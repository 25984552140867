import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link'


const projects = [
  {
    title: 'ASCC Robot Show',
    video: 'https://www.youtube.com/embed/q2pjcOBW4s4',
    desc: 'The video introduces a research project of using companion robots for elderly care.This is a collaborative project between the Laboratory for Advanced Sensing, Computation and Control (ASCC Lab) led by Dr. Weihua Sheng and the Center for Family Resilience led by Dr. Alex Bishop. The video shows the capabilities of a companion robot in providing companionship through verbal conversation, entertainment, cognitive assessment, fall detection, and video call for assistance.',
  },
  {
    title: 'ASCC Companion Robot',
    video: 'https://www.youtube.com/embed/W4dq4NoQ3Iw',
    desc: 'This is a demo video of the companion robot that is developed by the ASCC Lab. The robot has capabilities to assist a human at home. It can work as an assistant to answer human’s questions through voice chatting. It can take photos, record voice and video messages and then send them through emails or post on social networks. It is able to play game with humans. Moreover, It can detect fall and make the video call to the caregiver in case of emergency.',
  },
  {
    title: 'Distracted Driving Detection',
    video: 'https://www.youtube.com/embed/0xc-nRj1pEg',
    desc: 'This work proposes a driver distraction detection system which identifies various types of distractions through a camera observing the driver. An assisted driving testbed is developed for the purposes of creating realistic driving experiences and validating the distraction detection algorithms. We collected our own dataset which consists of images of the drivers in both normal and distracted driving postures. Four deep Convolutional Neural Networks (CNNs) are implemented and evaluated on an embedded GPU platform. We developed a conversational warning system that alerts the driver in real-time when he/she does not focus on the driving task.'
  }
]

const pastProjects = [
  {
    title: 'Wearable computing',
    desc: 'In this area, we are studying how to use wearable sensors and computers to recognize human behaviors, track human locations in indoor environments, monitor human health conditions, etc. The broad impact of this research includes helping elderly people in their daily lives, facilitating human robot interaction, and aiding first responders. Equipment and facilities',
    projects: [['Human activity recognition using wearable motion sensors', 'p1'], ['Humanoid robot control using Emotiv brain computer interface', 'p2']],
    funding: [['National Science Foundation'], ['OSU CTANS (Center for Telecommunication and Network Security)']],
  },
  {
    title: 'Human robot interaction',
    desc: 'In this area, we are developing intelligent machine learning algorithms to enable natural human robot interaction. Current research focus is on humanoid robot imitation learning, human robot collaboration, and remote presence through humanoid robot, etc.',
    projects: [['Humanoid robot imitation learning for collaborative tasks', 'p3'], ['Controlling a Humanoid Robot using a Brain Computer Interface', 'p4']],
    funding: [['National Science Foundation']],
  },
  {
    title: 'Distributed sensing and control',
    desc: 'In this area, we are developing distributed algorithms for a collection of agents, which can be mobile robots or sensors, to realize coordinated motion control and intelligent situational awareness. This research can benefit battlefield surveillance, security monitoring, etc.',
    projects: [['Flocking control of multiple mobile sensors in cluttered and noisy environments', 'p5'], ['	Distributed camera calibration for a multimedia sensor network', 'p6'], ['Multi-agent Mobile Robot Platform based on iRobot', 'p7']],
    funding: [['Department of Defense DURIP'], ['Defense Experimental Program to Stimulate Competitive Research (DEPSCoR']],
  },
  {
    title: 'Transportation Safety',
    desc: 'In this area, we are working on developing intelligent vehicles and roadside systems to improve the safety of future transportation system. Current research topics include human driving behavior analysis, intelligent intersection, vehicle collision avoidance.',
    projects: [['Automated and accurate crack inspection for bridge deck maintenance', 'p8'], ['Autonomous remote control (RC) cars tracking based on virtual approach', 'p9']],
    funding: [['Department of Transportation (OTC program)']],
  }
]

export default function Projects() {
  const navigate = useNavigate()
    return(
        <Box component="main" sx={{ p: 3, mt: 1}}>
            <Typography variant='h4' align='center' sx={{mb: 3}}>Projects</Typography>
            {projects.map((project) => (
                <Box 
                  m='auto'
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: { md: '50%'} 
                  }}
                >
                  <Card sx={{ maxWidth: 800, mb: 4 }}>
                  <CardActionArea>
                    <CardMedia
                      component="iframe"
                      height="250"
                      src = {project.video}
                      alt="ASCC Robot Show"
                      width="600"
                    />
                    <CardContent>
                      <Typography sx={{fontWeight: '600'}} gutterBottom variant="h5" component="div">
                        {project.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {project.desc}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    {/* <Button size="small" color="primary">
                      Share
                    </Button> */}
                  </CardActions>
                </Card>
              </Box>
              ))}
              <Typography align='center' variant='h5' sx={{mb: 2}}>Past Projects</Typography> 
              {pastProjects.map((pastProject) => (
              <Stack direction="row" justifyContent="center">
                <Card elevation={10} sx={{ maxWidth: 800, mb: 2 }}>
                  <CardContent>
                    <Typography sx={{fontWeight: '600'}} variant="h6">
                      {pastProject.title}
                    </Typography>
                    <Typography>
                      {pastProject.desc}
                    </Typography>
                    <Typography sx={{fontWeight: '600'}} >Projects</Typography>
                    {pastProject.projects.map((subproject) => (
                      <Typography><Link sx={{cursor: 'pointer'}} onClick={() => navigate('/Projectspost?id='+ subproject[1] )}>{subproject[0]}</Link></Typography>
                    ))}
                    <Typography sx={{fontWeight: '600'}}>Funding Sources</Typography>
                    {pastProject.funding.map((fund) => (
                      <Typography>{fund}</Typography>
                    ))}
                  </CardContent>
                  {/* <CardActions>
                    {/* <Button size="small">Share</Button> */}
                    {/* <Button onClick={() => navigate('/News?id='+ pastProject.id )} size="small">Learn More</Button> */}
                  {/* </CardActions> */}
                </Card>
              </Stack>
              ))}
          </Box>
                /* <Box 
                  m='auto'
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: { md: '38%'} 
                  }}
                >
                  <Card sx={{ maxWidth: 600 }}>
                  <CardActionArea>
                    <CardMedia
                      component="iframe"
                      height="250"
                      src="https://www.youtube.com/embed/W4dq4NoQ3Iw"
                      alt="ASCC Companion Robot"
                      width="600"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        ASCC Companion Robot
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        This is a demo video of the companion robot that is developed by the ASCC Lab. The robot has capabilities to assist a human at home. 
                        It can work as an assistant to answer human’s questions through voice chatting. It can take photos, record voice and video messages 
                        and then send them through emails or post on social networks. It is able to play game with humans. Moreover, It can detect fall and 
                        make the video call to the caregiver in case of emergency.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    {/* <Button size="small" color="primary">
                      Share
                    </Button> */
              //     </CardActions>
              //   </Card>
              // </Box> */
    )
}