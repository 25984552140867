import Box from '@mui/material/Box';
// import { Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';


export default function NSF() {
    return(
        <Box component="main" sx={{ p: 3 }}>
            <Typography align='center' variant='h4'>National Science Foundation</Typography>
            <Typography align='center' variant='h5'>FW-HTF-P: Robotic Health Assistants: A New Human-Machine Partnership in Home Healthcare</Typography>
      </Box>
    )
}