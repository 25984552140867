import React, { Component } from "react";
import {
  Routes,
  Route,
} from "react-router-dom";

import Contact from "./Contact";
import Home from "./Home";
import history from './history';
import People from "./People";
import Projects from "./Projects";
import Resources from "./Resources";
import Opportunities from "./Opportunities";
import Nsf from "./Nsf";
import Journal from "./Journal";
import Conference from "./Conference";
import Team from "./Team";
import News from "./News";
import Newspost from "./Newspost";
import Projectspost from "./Projectspost";
import Profile from "./Profile";

export default class Rout extends Component {
    render() {
        return (
            <Routes history={history}>
                <Route path="/"element={<Home/>} />
                <Route path="/Home" element={<Home/>}/>
                <Route path="/People" element={<People/>} />
                <Route path="/Contact" element={<Contact/>} />
                <Route path="/Journal" element={<Journal/>} />
                <Route path="/Conference" element={<Conference/>} />
                <Route path="/Projects" element={<Projects/>} />
                <Route path="/Resources" element={<Resources/>} />
                <Route path="/Opportunities" element={<Opportunities/>} />
                <Route path="/NSF HTF" element={<Nsf/>} />
                <Route path="/Team" element={<Team/>} />
                <Route path="/News" element={<News/>} />
                <Route path="/Newspost" element={<Newspost/>} />
                <Route path="/Projectspost" element={<Projectspost/>} />
                <Route path="/Profile" element={<Profile/>} />
            </Routes>
        )
    }
}