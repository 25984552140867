import Box from '@mui/material/Box';
// import { Toolbar } from '@mui/material';
// import Typography from '@mui/material/Typography';


export default function Opportunities() {
    return(
        <Box component="main" sx={{ p: 3 }}>
          <div>Opportunities</div>
      </Box>
    )
}