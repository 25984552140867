import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import logo from './logo-banner.png';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SvgIcon from '@mui/material/SvgIcon';

const drawerWidth = 240;
const navItems = [['Home'], ['People'],[['Publications'], ['Journal'], ['Conference']], ['Projects'], ['Resources'], ['News'] ,['Contact']];
const navItemsMobile = ['Home', 'People', 'Journal', 'Conference', 'Projects', 'Resources', 'News' ,'Contact'];
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.75),
    // backgroundColor: 'white',
    '&:hover': {
        backgroundColor: 'white',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
            width: '20ch',
        },
        },
    },
}));
  
export default function ResponsiveAppBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [ searchQuery , setSearchQuery ] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
    const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ my: 2 }}>
          ASCC Lab
        </Typography>
        <Divider />
        <List>
          {navItemsMobile.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(item)}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
    const container = window !== undefined ? () => window().document.body : undefined;
    const navigate= useNavigate();
    const searchInput = (e) => {
        setSearchQuery(e)
    }
  return (
    <Box>
        <Box
        sx={{
            width: "100%",
            height: {md: 80, xs: 65},
            backgroundColor: 'black',
            flexGrow: 1,
            position: 'static'
        }}
        >
            <Grid container spacing={1.5}>
                <Grid item xs={0} md={1} sm={0}>
                    
                </Grid>
                <Grid item xs={2} md={1} sm={2}>
                    {/* <img src={logo} alt="OSU Logo"></img> */}
                    <Box
                        component="img"
                        sx={{
                            content: {
                                xs: `url(${logo})`, //img src from xs up to md
                                md: `url(${logo})`,  //img src from md and up
                            },
                            height: {xs: 50, md: 120, sm: 70},
                            width: {xs: 50, md: 100, sm: 60},
                            zIndex: 'tooltip',
                            position: 'absolute',
                            // filter: 'grayscale(100%)'
                        }}
                        alt="OSU-Logo"
                    />
                    
                </Grid>
                <Grid item xs={9} md={8} sm={7}>
                    <a class="homeurl" href='/'> <Typography 
                        sx={{
                        mr: {md: 2, xs: 0},
                        mt: {md: 2, xs: 1},
                        ml: {md: 4},
                        fontFamily: `'-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"'`,
                        fontWeight: 500,
                        fontSize: {md: 30, xs: 15, sm: 15},
                        color: 'white',
                        textDecoration: 'none',
                        }}
                    > 
                        ASCC | LAB FOR ADVANCED SENSING, COMPUTATION &amp; CONTROL
                    </Typography></a>
                </Grid>
                {/* <Grid item xs={0} md={3} sm={0}></Grid> */}
                <Grid item xs={0} md={2} sm={2}>
                    {/* <form action='https://search.okstate.edu/?='{...searchQuery}> */}
                    <form target='_blank' action='https://search.okstate.edu/?'{...searchQuery}>
                    <Search
                        sx={{
                            mr: 2,
                            mt: 2,
                            display: { xs: 'none', md: 'flex', sm: 'flex' },
                            height: {sm: 30, md: 40},
                            width: {sm: 130, md: 200}
                        }}>
                        <SearchIconWrapper>
                        <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                        name = 'q'
                        onChange={e => searchInput(e.target.value)}
                        />
                    </Search>
                    </form>
                </Grid>
            </Grid>
        </Box>
        <Box sx={{display: 'flex'}}>
            <AppBar component="nav" sx={{position: 'relative', backgroundColor: '#F37224', height: {md: 60, xs: 10}}}>
                <Toolbar>
                    <IconButton
                        color="default"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                    <MenuIcon />
                    </IconButton>
                    {/* <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        ASCC Lab
                    </Typography> */}
                    <Box sx={{ display: { xs: 'none', sm: 'block'}, ml: {md: 50} }}>
                        {navItems.map((item) => (
                        item.length <= 1 ? <Button onClick={() => navigate(item[0])} key={item} sx={{ color: '#fff' , paddingLeft: {md: 4}, zIndex: 'drawer'}}>
                            <Typography>{item}</Typography>
                        </Button> : 
                            <>
                           <Button
                            id="fade-button"
                            aria-controls={open ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            sx={{ color: '#fff' , paddingLeft: {md: 4}, zIndex: 'drawer'}}
                        >
                            <Typography>{item[0]}</Typography> <SvgIcon component={ArrowDropDownIcon}></SvgIcon>
                        </Button>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                            'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                            sx={{
                                left: 35
                            }}
                        >
                            <MenuItem onClick={() => setAnchorEl(null) || navigate('Journal')}>{item[1]}</MenuItem>
                            <MenuItem onClick={() => setAnchorEl(null) || navigate('Conference')}>{item[2]}</MenuItem>
                        </Menu>
                            </>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            {/* onClick={() => {handleNav(item)}} */}
            <Box component="nav">
                <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                >
                {drawer}
                </Drawer>
            </Box>
        </Box>
        <Box>
        {/* <form target='_blank' action='https://search.okstate.edu/?'{...searchQuery}>
                        <Search
                            sx={{
                                mr: 2,
                                mt: 2,
                                mb: 2,
                                display: { xs: 'flex', md: 'none', sm: 'none' },
                                height: {xs: 30},
                                width: {xs: 200},
                                ml: 15
                            }}>
                            <SearchIconWrapper>
                            <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            name = 'q'
                            onChange={e => searchInput(e.target.value)}
                            />
                        </Search>
            </form> */}
        </Box>
    </Box>
  );
}