import Box from '@mui/material/Box';
// import { Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';



function importAll(r) {
    let images = {};
     r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}
const images = importAll(require.context('./images/Resources', false, /\.(png|jpe?g|svg)$/));

const resources = [
    {
        name: 'Motion Capture System',
        desc: 'Vicon Motion Capture System: A 12-camera motion capture system for high-accuracy motion tracking.',
        image: images['image002_0002.jpg']
    },
    {
        name: 'ASCC Smart Home',
        desc: 'ASCC Smart Home:  A smart home environment which consists of a simulated apartment,  a set of distributed wireless motion sensors on the ceiling, video cameras, human wearable motion/physiological sensors, an intelligent robot, and a 12-camera Optitrack motion capture system for ground truth.',
        image: images['smarthome.png']
    },
    {
        name: 'ASCC Assisted Driving Testbed',
        desc: 'ASCC Assisted Driving Testbed: A simulated driving testbed with a three-monitor driving simulator cockpit, a user interface, two embedded computers, a camera for monitoring the external environment, and two cameras for monitoring the human driver.',
        image: images['IMG_7746_1.jpg']
    },
    {
        name: 'ASCC ITS Testbed',
        desc: 'ASCC ITS Testbed: A small scale testbed for Intelligent Transportation Systems (ITS) research, which consists of multiple automated RC cars, an omnidirectional video camera, an Optitrack motion capture system for location/orientation feedback, and a racing wheel for simulated manual driving.',
        image: images['image006_0004.jpg']
    },
    {
        name: 'ASCC Humanoids',
        desc: 'ASCC Humanoids: Two NAO humanoid robots.',
        image: images['image007.jpg']
    },
    {
        name: 'ASCC Portable Skill Learning Kit',
        desc: 'ASCC Portable Skill Learning Kit:  A portable human skill demonstration platform consisting of a Kinect depth sensor, a rotating Lazy Susan for research in robot skill learning.',
        image: images['image010_0004.jpg']
    },
    {
        name: 'ASCC Wearable Wireless ECG Kit',
        desc: 'ASCC Wearable Wireless ECG Kit: A custom designed 10-lead wireless ECG sensor kit for collecting ECG data from human body. ',
        image: images['image012_0002.jpg']
    },
    {
        name: 'ASCC Mobile Sensor Network',
        desc: 'ASCC Mobile Sensor Network: A collection of seven mobile robotic sensor nodes. Each of them is equipped with an iRobot Create robot base, a Beagleboard minicomputer and a webcam.',
        image: images['image014_0002.jpg']
    },
    {
        name: 'ASCC Mobile Vision Sensor Network',
        desc: 'ASCC Mobile Vision Sensor Network: A collection of mobile wireless video sensors. Each of them consists of an iRobot Create robot, a minicomputer, a Hokuyo laser range finder and an omnidirectional video camera.',
        image: images['image016_0002.jpg']
    },
    {
        name: 'ASCC Flocker',
        desc: 'ASCC Flocker: A collection of seven mobile sensors made of Rovio home surveillance robots for research in multi-robot formation control.',
        image: images['image018_0002.jpg']
    },
    {
        name: 'A) ASCC Personal Robot. B) ASCC Explorer. C) ASCC Telepresence Robot',
        desc: '(a)ASCC Personal Robot: A retrofitted Pioneer 3DX Robot with a RGB-D sensor, a microphone array, a Hokuyo laser range finder, a touch screen, powered by two minicomputers for research in personal robots in future homes.(b).ASCC Explorer: A Pioneer 3DX robot equipped with a RGB-D sensor on a pan-tilt unit, a Hokuyo laser range finder and a minicomputer. (c) ASCC Telepresence Robot:  A mobile robot with a RGB-D sensor, a tablet and a minicomputer for research in telepresence.',
        image: images['robot.jpg']
    },
    {
        name: 'ASCC ROCIM',
        desc: 'ASCC ROCIM: A Robot Crack Inspection and Mapping (ROCIM) system consisting of a Pioneer 3DX robot, a SICK laser range finder and a video camera for detecting and locating cracks on bridges decks.',
        image: images['image026.jpg']
    },
    {
        name: '3D Scanner',
        desc: '3D Scanner:  A high-definition 3D scanner from NextEngine for research in 3D modeling.',
        image: images['image028.jpg']
    },
    {
        name: 'PhanToM Omni Haptic Devices',
        desc: 'PhanToM Omni Haptic Devices: Two PhanToM Omni haptic devices for research in hatptics and human-robot interaction',
        image: images['image030.jpg']
    },
    {
        name: 'Brain Computer Interface',
        desc: 'Brain Computer Interface: An Emotiv brain computer interface (BCI) for research in human robot interaction.',
        image: images['image032.jpg']
    },
    {
        name: 'Motion Sensor',
        desc: 'Motion Sensor: A VN-100 attitude heading reference system for measuring motion and orientation.',
        // image: images['image033.jpg']
    },
    {
        name: 'Omni-vision sensor',
        desc: 'Omni-vision sensor: A set of Q24 fish-eye vision sensor from Mobotix.',
        // image: images['image035.jpg']
    }
]

export default function Resources() {
    return(
        <Box component="main" sx={{ p: 3}}>
            <Typography variant='h4' align='center'>Resources</Typography>
            {resources.map((resource) => (
            <Box
                sx={{
                    // m: {md: 3},
                    // ml: {md: 10},
                    width: { md: '40%'} 
                }}
                m='auto'
                  justifyContent="center"
                  alignItems="center"

            >
            {/* <Grid container columns={{md: 12}}> */}
            {/* <Grid item xs={12} md={8} sm={12}> */}
            <Card sx={{mb: 4, boxShadow: '3', border: '2px solid #F37224'}}>
                  <CardActionArea>
                  <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {resource.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {resource.desc}
                      </Typography>
                    </CardContent>
                    {resource.image && <CardMedia
                      component="img"
                      image={resource.image}
                    //   alt="ASCC Robot Show"
                      sx={{
                        objectFit: { md: 'scale-down', xs: 'scale-down', sm: 'scale-down'},
                        // objectFit: 'fi'
                        height: 500
                      }}
                    />}
                    
                  </CardActionArea>
                  <CardActions>
                    {/* <Button size="small" color="primary">
                      Share
                    </Button> */}
                  </CardActions>
                </Card>
                {/* </Grid>
            </Grid> */}
            </Box>
            ))}
        </Box>
    )
}
