import React from 'react';
import Box from '@mui/material/Box';
// import { Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List  from '@mui/material/List';
import ListItemButton  from '@mui/material/ListItemButton';
import ListItemText  from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import map from './map.jpg'
import Link from '@mui/material/Link';
// import './animations.scss'

const campus = [
    ['OSU - Stillwater', 'https://go.okstate.edu/'], 
    // ['Tulsa', 'https://tulsa.okstate.edu/'], 
    // ['Center for Health Sciences', 'https://medicine.okstate.edu/'], 
    // ['Oklahoma City', 'https://osuokc.edu/'], 
    // ['Institute of Technology', 'https://osuit.edu/'], 
    // ['Division of Agriculture', 'https://agdivision.okstate.edu/?Forwarded=www.dasnr.okstate.edu/'], 
    // ['Veterinary Medicine', 'https://vetmed.okstate.edu/']
]

const unilinks = [
    ['ECE', 'https://ceat.okstate.edu/ece/'],
    // ['Diversity', 'https://diversity.okstate.edu/'],
    // ['Research', 'https://research.okstate.edu/'],
    // ['Extension & Engagement', 'https://outreach.okstate.edu/'],
    // ['Alumni & Friends', 'https://go.okstate.edu/family/'],
    // ['OSU Athletics', 'https://okstate.com/'],
    // ['Americas Healthiest Campus', 'https://wellness.okstate.edu/'],
    // ['News & Information', 'https://news.okstate.edu/']
]

const resources = [
    ['ASCC Lab', '/'],
    // ['EEO Statement', 'https://hr.okstate.edu/equal-opportunity/'],
    // ['Accessibility', 'https://accessibility.okstate.edu/'],
    // ['Trademarks', 'https://brand.okstate.edu/services/trademarks/index.html'],
    // ['Terms of Service', 'https://go.okstate.edu/tos/'],
    // ['Webmaster', 'mailto:webmaster@okstate.edu']
]


export default function Footer() {
    return(
        <Box component="main" sx={{ 
            p: 0, 
            backgroundColor: 'black',
            height: {md: '200px'},
            }}
        >
             {/* <div id="stars"></div> */}
            {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {Array.from(Array(12)).map((_, index) => (
                    <Grid item xs={2} sm={4} md={3} key={index}>
                    <Typography sx={{color: 'white'}}>xs=2</Typography>
                    </Grid>
                ))}
            </Grid> */}
            <Box sx={{
            }}>
            <Grid container columns={{md: 12, sm: 3, xs:3}} >
                <Grid container item md={3} sm={12}>
                    <List sx={{color: "white"}}>
                        <Typography sx={{ml: 4, fontWeight: 700, color: '#F37224', mt: 1}}>OUR CAMPUS</Typography>
                        {campus.map((item) => (
                            <ListItem sx={{height: {md: "30px", xs: "15px"}}}>
                                <ListItemButton component="a" href={item[1]} target="_blank">
                                    <Link color='white' underline='none'><ListItemText
                                        primary={item[0]}
                                    /></Link>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid container item md={3} sm={12}>
                    <List sx={{color: "white"}}>
                        <Typography sx={{ml: 4, fontWeight: 700, color: '#F37224', mt: 1}}>OUR DEPARTMENT</Typography>
                        {unilinks.map((item) => (
                            <ListItem sx={{height: {md: "30px", xs: "15px"}}}>
                                <ListItemButton component="a" href={item[1]} target="_blank">
                                    <Link color='white' underline='none'><ListItemText
                                        primary={item[0]}
                                    /></Link>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid container item md={2} sm={12}>
                    <List sx={{color: "white"}}>
                        <Typography sx={{ml: 4, fontWeight: 700, color: '#F37224', mt: 1}}>OUR LAB</Typography>
                        {resources.map((item) => (
                            <ListItem sx={{height: {md: "30px", xs: "15px"}}}>
                                <ListItemButton component="a" href={item[1]} target="_blank">
                                    <Link color='white' underline='none'><ListItemText
                                        primary={item[0]}
                                    /></Link>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid container item md={3}>
                    <List sx={{color: "white"}}>
                        <Typography sx={{ml: 4, fontWeight: 700, color: '#F37224', mt: 1}}>CAMPUS MAP</Typography>
                            <ListItem>
                                <ListItemButton component="a" href="https://map.okstate.edu/?id=1842#!ct/65027,65025,65026?s/" target="_blank">
                                    {/* <img src = {map} alt="OSU Logo"/> */}
                                    <Box
                                        component="img"
                                        sx={{
                                            content: {
                                                xs: `url(${map})`, //img src from xs up to md
                                                md: `url(${map})`,  //img src from md and up
                                            },
                                            height: {xs: 50, md: 50},
                                            width: {xs: 50, md: 50}
                                        }}
                                        alt="OSU-Logo"
                                    />
                                </ListItemButton>
                            </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Box sx={{color: 'white', justifyContent: 'center'}}>
                <Typography sx={{fontSize : {sm: 12, xs: 10, md: 'default'}}} display="flex" justifyContent='center'>© 2022 - ASCC LAB | Oklahoma State University  |  All Rights Reserved</Typography>
                {/* <Typography sx={{fontSize : {sm: 12, xs: 10, md: 'default'}}} display="flex" justifyContent='center'>Developed by&nbsp; <Link target="_blank" color='#fff' underline='none' href='https://linkedin.com/in/mohdm2611'> Mohammed</Link></Typography> */}
            </Box>
            </Box>
      </Box>
    )
}