import { useSearchParams } from "react-router-dom"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from "react";
// import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
// import Link from '@mui/material/Link'


function importAll(r) {
    let images = {};
     r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
   }
  const images = importAll(require.context('./images/Article', false, /\.(png|jpe?g|svg)$/));

const newsItems =[
    {
      type: 'Award',
      date: 'Friday, September 6 2019',
      desc: 'ASCC Awarded NSF Grant for Home Service Robots',
      id: 1,
      image: [],
      body: 'On Sept 6th,  Dr. Sheng was awarded a National Science Foundation project titled RI: Small: Enabling Sound-based Human Activity Monitoring for Home Service Robots. This three-year project focuses on developing the theories and algorithms for human behavior understanding through sounds in home environment. The project will start Oct. 1st, 2019.'
    },
    {
      type: 'Award',
      date: 'Friday, July 26 2019',
      desc: 'ASCC Awarded NSF Grant for Robotic Health Assistants',
      id: 2,
      image: [],
      body: 'On July 26th, Dr. Sheng was awarded a National Science Foundation project titled: FW-HTF-P: Robotic Health Assistants: A New Human-Machine Partnership in Home Healthcare. This one-year project focuses on the planning activities to develop a full proposal which aims at creating robotic health assistants for elderly care and studying the associated human-robot partnership.  This project is in collaboration with Dr. Alex Bishop from Human Sciences at OSU and Dr. Barbara Carlson from OU Health Science Center.'
    },
    {
      date: 'Friday, May 31 2019',
      desc: '2019 STEM Robotics Summer Camp Update',
      id: 3,
      body: 'From May 28 through the 30, Dr. Sheng and his team with the help of Dr. Fan and his team put on a three day STEM robotics camp for eighth through eleventh graders. During this camp, students learned the basic of building a robot, received an introduction to Python programming, and performed in a competition for their parents on the last day. In addition to this, they were also educated on OSU and CEAT facilities. This included a tour of the ENDEAVOR Lab building the ATRC, and a presentation by the CEAT recruitment officer Adam Stevens highlighting different aspects of the College of Engineering, Architecture, and Technology.',
      image: [images['ha_kids.jpg'], images['IMG_1060.jpg'], images['IMG_1286.jpg'], images['IMG_1342.jpg'], images['IMG_1438.jpg']]
    },
    {
      desc: 'The Weblos | Kids from local Cubscouts visited ASCC Lab on Oct. 16th, 2015.',
      date: 'Sunday, October 25 2015',
      id: 4,
      image: [images['2015-10-16 19.04.07.jpg'], images['2015-10-16 19.07.43.jpg'], images['2015-10-16 19.10.26.jpg'], images['2015-10-16 19.18.41.jpg'], images['2015-10-16 19.25.22.jpg']],
    },
    {
      desc: 'Trung Nguyen won Top 10 Finalist in 5th Annual App Competition.',
      date: 'Friday, April 28 2017',
      id: 5,
      image: [images['TrungApp.jpg']],
      body: 'App Center received a total of 87 app ideas, a record number, from various departments across campus. The selection process was very competitive and was based on three factors: 1. Relevancy to the contest theme, 2. Implementability of the app idea, and 3. Potential social and economic impact. Trung Nguyen, a Master student from School of Electrical and Computer Engineering at OSU and a research assistant at ASCC, won "Top 10 Finalist" with $500 prize with "The 3rd Eye" Idea. https://appcenter.okstate.edu/content/top-10-ideas-phase-i-announced-–-5th-annual-app-competition'
    },
    {
      desc: 'A reporter from The Oklahoman visited the lab',
      date: 'Sunday, November 26 2017',
      id: 6,
      image: [images['2017Dailyok.jpg']],
      body: 'On November 16, 2017, a reporter from the newspaper "The Oklahoman" came to the lab for an interview regarding our project on the ASCC Companion Robot. Here is the link to the article http://newsok.com/oklahoma-researchers-look-to-robots-to-care-for-aging-boomers/article/5573465'
    },
    {
      desc: 'Our Cloud-based Smart Home Environment (CoSHE) on Singularityhub',
      id: 7,
      date: 'Wednesday, January 31 2018',
      image: [],
      body: `Regarding to our project of delivering home healthcare through a Cloud-based Smart Home Environment (CoSHE), which was published in Future Generation Computer Systems journal (https://www.sciencedirect.com/science/article/pii/S0167739X17302194),  Dr. Sheng had an interview by Peter Rejcek, a writer from Singularity Hub. And his article entitled "Smart Homes Won’t Just Automate Your Life—They’ll Track Your Health Too" has been published recently.\n\n "Sheng’s team is developing a cloud-based smart home platform for healthcare monitoring that includes environmental sensors, wearables, and a robot assistant. A recent paper published in Future Generation Computer Systems outlined how such a system would operate.\n\n A smart garment with textile ECG electrodes and a respiration belt is one of several wearables, along with a microphone, used by the patient in the smart home mock-up. Environmental sensors include a network of infrared sensors and Grid-EYE thermopile array sensors in the ceiling of the apartment. The latter can be used for thermal tracking. A robot assistant serves as both a companion and multi-functional sensor." - Peter Rejcek.\n\n The full article can be reached here, https://singularityhub.com/2018/01/30/smart-homes-wont-just-automate-your-life-theyll-track-your-health-too/#sm.0000aew7ohh0aelc11fj43nx0eosm`,
    },
    {
      desc: 'Minh Pham and Yehenew Mengistu won the first prize in Rural Healthcare Innovation Weekend, Nov 13th-15th, 2015 in Tulsa, Oklahoma',
      id: 8,
      date: 'Friday, November 20 2015',
      image: [images['23111717752_0ab35c9f40_o.jpg'], images['23066743526_85d4333b22_o.jpg']],
      body: `Minh Pham, Yehenew Mengistu and Mostakim Tanjil, a master student from Design Housing and Merchandising department, formed a team and participated the Rural Healthcare Innovation Weekend hosted by the OSU Center for Health Systems Innovation, November 13-15, 2015  in Tulsa, Oklahoma. The team won the first prize for developing a prototype for the project "Cloud-based healthcare personal platform" with $3000 cash prize. The details of the event can be found in the following link http://chsi.okstate.edu/rural-healthcare-innovation-weekend/\n\nA brief description of their project\nPatients with cardiopulmonary disease need to visit hospitals once in every couple of weeks and sometimes physicians need to monitor those patients 24/7. It is hard for patients who live in rural areas. Furthermore, physicians usually have a lot of patients who they need to monitor. Our platform will ensure a central monitoring system for all patients under any specific physician. Our cloud-based health care platform will collect ECG, respiration, blood oxygen saturation, heartrate and activity (standing, sitting, lying and moving) continuously through a smart medical garment which patients will always wear. Then signals will be uploaded wirelessly to a cloud where the signals will be analyzed automatically to classify high-risk and low-risk patients. If a patient is classified as high-risk, the system will send a notification to the physician. Physician will go to the web portal and check real-time vital signs under that patient’s name/identification number. So physicians will not need to check every single patient’s status. Furthermore, patients can also visit the web portal and check their vital signs in real-time.\n\nSome pictures taken during the event\n`
    },
    {
      desc: 'Local high school students visit ASCC Lab',
      date: 'Monday, October 6 2014',
      id: 9,
      image: [],
      body: 'On Oct. 3rd, Friday, a group of 12 students from Eastern Oklahoma County toured the ASCC Lab. They were participating in the pre-engineering program in CEAT.'
    },
    {
      desc: 'K-12 students visited ASCC lab',
      date: 'Sunday, November 26 2017',
      id: 10,
      image: [images['2017k12.jpg'], images['2017k12_1.jpg'], images['2017k12_3.jpg']],
      body: 'On November 17, 2017, two groups of local high school student in the K-12 Shadow program visited ASCC Lab. During the visit, they gained hands-on experience with the home service robot and the assisted driving testbed.'
    },
    {
      desc: 'IEEE-CYBER 2016 Best Poster Award',
      date: 'Monday, July 11 2016',
      id: 11,
      image: [images['Duy_award.jpg']],
      body: 'Congratulation to Duy Tran and Eyosiyas Tadesse for IEEE-CYBER 2016 Best Poster Award.'
    },
    {
      desc: 'Gordon Cooper STEM Day',
      date: 'Wednesday, October 24 2018',
      id: 12,
      image: [images['stemday2.png'], images['stemday1.png'], images['stemday.png']],
      body: "Daniel Albrecht went to Gordon Cooper Technology Center's STEM Day in Shawnee , OK and demoed the new Kobuki robots to 5th, 6th, and 7th graders from that area. There, he showed them what students in the Electrical and Computer Engineering department learn and some of the exciting opportunities OSU has to offer."
    },
    {
      desc: 'ENDEAVOR Grand Opening',
      date: 'Saturday, September 22 2018',
      id: 13,
      image: [images['endeavor1.png'], images['endeavor2.png'], images['endeavor3.png']],
      body: 'On Saturday September 22, 2018, Dr. Sheng and his team helped with the grand opening of the new ENDEAVOR building. This brand new undergraduate labratory was inspired by research facilities from around the country and will house state-of-the-art equipment for undergraduate students to use. For the grand opening, Dr. Sheng and his team demoed the brand new Kobuki robots and put on a life size game of Pac-Man for visitors to enjoy.'
    },
    {
      desc: 'Dr. Sheng won the Second Place in the Competition of the 2018 President’s Cup for Creative Interdisciplinarity',
      date: 'Tuesday, December 18 2018',
      id: 14,
      image: [images['2018PresidentCup1.jpg'], images['2018PresidentCup2.jpg']],
      body: 'Dr. Weihua Sheng (Professor,  Team Leader)  and Yanmin Gong (Assistant Professor), along with their collaborators (Dr. Alex Bishop from the Department of Human Development and Family Science, Dr. Celinda Reese-Melancon from the Department of Psychology), won the Second Prize in the competition of the 2018 President’s Cup for Creative Interdisciplinarity.\n\n Titled Well-being Assessment Using a Social Robot for Elder Care, their project focuses on developing and testing a smart companion robot that conducts geriatric well-being assessments designed for old care recipients. This research is motivated by the urgent need of bridging the gap between the growing demand for healthcare among old adults who age alone and the shortage of qualified healthcare professionals across the nation, particularly in states like Oklahoma. This work is conducted by a multidisciplinary team of researchers with rich expertise in robotics and Al, gerontology, psychology as well as security and privacy. The project has resulted in a prototype of a smart companion robot capable of conducting well-being assessment through natural language conversations. The tests with seniors from the local community have produced promising results, which have been published in multiple conference papers. This project also allowed the PIs to reach out to the local senior residents by providing educational seminars on elder care technologies.\n\nFrom Left: Yanmin Gong, Weihua Sheng, Celinda Reese-Melanco, Alex Bishop.'
    },
    {
      desc: 'Director of Aging Services, State of Oklahoma, visited ASCC Lab',
      date: 'Monday, December 5 2016',
      id: 15,
      image: [images['Nov302016_5.jpg'], images['Nov302016_4.jpg'], images['Nov302016_3.jpg'], images['Nov302016_2.jpg'], images['Nov302016_1.jpg']],
      body: 'Lance Robertson, the Director of Aging Services, State of Oklahoma, visited ASCC Lab on Nov. 30th, 2016. Dr. Alex Bishop, Associate Professor in the Human Development and Family Sciences Department, accompanied him on this visit.'
    },
    {
      desc: `Congratulations on Ye Gu's Ph.D graduation`,
      date: 'Tuesday, May 5 2015',
      id: 16,
      image: [],
      body: "Congratulations on Ye Gu's Ph.D graduation!\n\nThe Graduate College Commencement will be held on Friday, May 8, 2015 7:00 p.m at Gallagher Iba Arena."
    },
    {
      desc: 'Bookworms Companion Robot Demo',
      date: 'Tuesday, September 25 2018',
      id: 17,
      image: [images['bookworms.png'], images['bookworms1.png']],
      body: `On Tueday September 24, 2018, Dr. Sheng and his students Ha Do and Daniel Albrecht presented some of their research on companion robots to the "Bookworms" club at Stillwater Public Library. The Bookworms club consists of elderly aged people with condiditions such as Alzheimer's and Dimentia, but have a desire to learn. The primary focus of Dr. Sheng's research into companion robots is to find new, innovative ways to care for elderly aged people, while still making them feel comfortable.`
    },
    {
      desc: `Barath Lakshmanan and his fellow Intel Interns won 2nd place of the PayPal Hack'on Competition`,
      date: 'Friday, November 20 2015',
      id: 18,
      image: [],
      body: `PayPal organized a two day application development contest 'Opportunity Hack' on 10th & 11th of October, 2015 (33 hours of coding). There were about 217 people registered for the event. Each team consisted of 3 to 5 members, out of which 23 teams made their final submission. Each team developed an application for the non-profit organization which is assigned to them.\n\n Barath Lakshmanan, who is a Master student from School of Electrical and Computer Engineering at OSU and currently an intern at Intel, worked with his fellow interns at Intel to develop an application (website and mobile app) as a one-stop solution for pet care. This application was deployed on the Cloud and with features such as adaptive search, location based service etc.,\n\nBarath’s team were awarded the second prize with $2000 cash prize and a guaranteed interview from PayPal.\n\nThe details of the app can be found in the following link\nhttp://devpost.com/software/nagi_app\n`
    },
    {
      desc: 'Barath has won the Robert & Jean Schuetz Graduate Fellowship in CEA',
      date: 'Tuesday, April 14 2015',
      id: 19,
      image: [],
      body: 'Congratulations Barath! He has been awarded the Robert & Jean Schuetz Graduate Fellowship in CEAT in the amount of $3000!'
    },
    {
      desc: `ASCC lab's students attended CASE 2016 Conference`,
      date: 'Wednesday, August 31 2016',
      id: 20,
      image: [images['case2016_1.jpg'], images['case2016_2.jpg'], images['case2016_3.jpg'], images['case2016_4.jpg'], images['case2016_5.jpg']],
      body: '12th Conference on Automation Science and Engineering was held in Fort Worth, Texas from 21st to 24th August , 2016. At the conference, Ha Do, Francisco Fernandes Jr and Minh Pham presented three papers titled "Context-Aware Sound Event Recognition for Home Service Robots", "Detection of Privacy-Sensitive Situations for Social Robots in Smart Homes", and "Cloud-Based Smart Home Environment (CoSHE) for Home Healthcare" respectively. Belows are several pictures of Dr. Sheng along with his students.'
    },
    {
      desc: 'ASCC Lab’s Research Mentioned in The Journal Record',
      date: 'Monday, March 11 2019',
      id: 21,
      image: [],
      body: `In the article, "Oklahoma is becoming a Growing Center for Robotics Research", posted by The Journal Record, Dr. Wiehua Sheng was interveiwed over his team's work with in-home, elderly-care robotics.\n\nCoppock, Mike. “Oklahoma Is Becoming a Growing Center for Robotics Research.” Greater Oklahoma City Economic Development, The Journal Record, 4 Mar. 2019,\nhttp://www.greateroklahomacity.com/news/2019/03/04/the-journal-record/oklahoma-is-becoming-a-growing-center-for-robotics-research/`
    },
    {
      desc: 'ASCC Lab participated in the 2018 OSU National Lab Day',
      date: 'Tuesday, May 15 2018',
      id: 22,
      image: [images['NLD2018 (1).jpg'], images['NLD2018 (2).jpg'], images['NLD2018 (3).jpg'], images['NLD2018 (4).jpg'], images['NLD2018 (5).jpg'], images['NLD2018 (7).jpg'] ],
      body: 'ASCC Lab participated in the 2018 OSU National Lab Day on Tuesday May 15th. Belows are some pictures of a group of students from Dewey High School in Oklahoma.'
    },
    {
      desc: 'ASCC Lab participated in the 2016 OSU National Lab Day',
      date: 'Friday, May 13 2016',
      id: 23,
      image: [images['NLD2016_1.jpg'], images['NLD2016_2.jpg'], images['NLD2016_3.jpg']],
      body: 'ASCC Lab participated in the 2016 OSU National Lab Day on Tuesday May 10th. Belows are some pictures of a group of students from Dewey High School in Oklahoma.'
    },
    {
      desc: 'ASCC Lab participated in the 2015 OSU National Lab Day',
      date: 'Tuesday, May 12 2015',
      id: 24,
      image: [images['NLD2015_3.jpg'], images['NLD2015_2.jpg'], images['NLD2015_1.jpg']],
      body: 'ASCC Lab participated in the 2015 OSU National Lab Day on Tuesday May 12th. Belows are some pictures of a group of students from Morrison High School in Oklahoma.'
    },
    {
      desc: 'ASCC Demos Companion Robot to Honors Students',
      date: 'Wednesday, February 20 2019',
      id: 25,
      image: [images['asccdemos.png'], images['asccdemos1.png']],
      body: 'Dr. Sheng and his team demoed the ASCC Companion robot to students in the Active Aging for L.I.F.E. honors seminar taught by Dr. Emily Roberts from the College of Human Sciences. On a local field trip, the students learned more about the importance of teamwork in different areas of study.'
    },
    {
      desc: 'ASCC Assists in Junior Day',
      date: 'Saturday, April 13 2019',
      id: 26,
      image: [images['juniorday.png'], images['juniorday1.png'], images['juniorday2.png'],images['juniorday3.png'] ],
      body: 'Over the weekend, Dr. Sheng and his team demonstrated their work for the CEAT Junior Day expo. Junior Day is an event put on by the College of Engineering, Architecture, and Technology focusing on high school juniors to show them the opportunities that Oklahoma State has to offer. Together in ENDEAVOR Lab 170, the team displayed the Smart home project, the Companion robot project, and some of the course material for ECEN 4213 Embedded Computer System Design. After this, team member Daniel Albrecht demonstrated to the Oklahoma State Heritage society how the funding gained from their donations has helped to improve the opportunities given to students using the ENDEAVOR facilities. '
    },
    {
      desc: '2019 Summer STEM Robotics Camp',
      date: 'Tuesday, May 7 2019',
      id: 27,
      image: [images['2019 RA Flyer FINAL_001.png'], images['2019 RA Flyer FINAL_002.png'] ],
      body: `From May 28 to May 30, Dr. Sheng and his team and Dr. Fan and his team will be hosting a Summer STEM Robotics camp for 8th through 12th graders. The purpose of the camp will be to inform the students about the creative process used to design robots.\n\n\nTo find out more information about the robotics camp, or to apply for the robotics camp, please see the flyer below.`
    },{
      desc: 'Zhidong won the first place in the CEAT 3MT competition',
      date: 'Monday, Oct 09  2023',
      id: 28,
      image: [images['zhidong_3mt.png'], images['3mt1.png'] ],
      body: ` On October 9th, 2023, Zhidong won the first place out of 32 participants in CEAT's 3MT (3 Minute Thesis) competition.\n\n\nThe 3MT is a research communication competition that challenges research degree students to present a compelling oration on their thesis/dissertation topic and its significance in just three minutes using just one static slide. \n\n\n In the 3MT competition, Zhidong presented his research titled “Conversation-based Medication Management System for Older Adults using A Companion Robot”`
    },
]


export default function Newspost (props){
    const [searchParams, setSearchParams] = useSearchParams(0);
    const id = searchParams.get('id')
    var news = []
    for(var i=0; i<newsItems.length;i++){
        if(newsItems[i]['id'] == id){
            news = newsItems[i]
        }
    }
    return (
        <Box sx={{minHeight: 'calc(100vh - 350px)'}}>
            <Stack direction="row" justifyContent="center">
                <Card elevation={10} sx={{ width: 1100, mb: 2, p: 3 }}>
                  <CardContent>
                    <Typography sx={{fontWeight: '400'}} variant="h4" align="center">
                      {news.desc}
                    </Typography>
                    <Typography sx={{mt: 1}} color="text.secondary">
                      {news.date}
                    </Typography>
                    <Typography sx={{mt: 2, whiteSpace: 'pre-wrap'}}variant="body1">
                      {news.body}
                    </Typography>
                  </CardContent>
                  {news.image.length == 1 ?
                    // <ImageList sx={{ width: '100%'}} cols={1} rowHeight={400}>
                      <ImageListItem>
                        <img
                          src={`${news.image}?w=400&h=400&fit=crop&auto=format`}
                        //   srcSet={`${news.image}?w=400&h=400&fit=crop&auto=format&dpr=2 2x`}
                          alt={news.desc}
                          loading="lazy"
                          height="100%"
                        />
                      </ImageListItem>
                    // </ImageList>
                    : news.image.length == 0 ? <>&nbsp;</> 
                    :
                    // <ImageList gap={400} sx={{ width: '100%', height: 600 }} cols={1} rowHeight={400}>
                    news.image.map((img, index) => (
                      <ImageListItem key={index} sx={{mb: 3}}>
                        <img
                          src={`${img}?w=400&h=400&fit=crop&auto=format`}
                          srcSet={`${img}?w=400&h=400&fit=crop&auto=format&dpr=2 2x`}
                          alt={news.desc}
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                    {/* </ImageList> */}
                </Card>
            </Stack>
            {/* <Box>
                <Typography variant="h5">{news.desc}</Typography>
                <Typography variant="body2" color="text.secondary">{news.date}</Typography>
                <Typography variant="body1">{news.body}</Typography>
                {news.image.length < 1 ?
                <ImageList sx={{ width: '100%', height: 600 }} cols={2} rowHeight={400}>
                  <ImageListItem>
                    <img
                      src={`${news.image}?w=400&h=400&fit=crop&auto=format`}
                    //   srcSet={`${news.image}?w=400&h=400&fit=crop&auto=format&dpr=2 2x`}
                      alt={news.desc}
                      loading="lazy"
                    />
                  </ImageListItem>
                </ImageList>
                :
                <ImageList sx={{ width: '100%', height: 600 }} cols={2} rowHeight={400}>
                {news.image.map((img, index) => (
                  <ImageListItem key={index}>
                    <img
                      src={`${img}?w=400&h=400&fit=crop&auto=format`}
                      srcSet={`${img}?w=400&h=400&fit=crop&auto=format&dpr=2 2x`}
                      alt={news.desc}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            }
            </Box> */}
        </Box>
    )
}