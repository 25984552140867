import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List  from '@mui/material/List';
import ListItemButton  from '@mui/material/ListItemButton';
import ListItemText  from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';


const bibFile = [
    `@article{chen2002cad,
        title={CAD-based automated robot trajectory planning for spray painting of free-form surfaces},
        author={Chen, Heping and Sheng, Weihua and Xi, Ning and Song, Mumin and Chen, Yifan},
        journal={Industrial Robot: An International Journal},
        volume={29},
        number={5},
        pages={426--433},
        year={2002},
        publisher={MCB UP Ltd}
      }`,
      `@article{sheng2001cad,
        title={CAD-guided robot motion planning},
        author={Sheng, Weihua and Xi, Ning and Song, Mumin and Chen, Yifan},
        journal={Industrial Robot: An International Journal},
        volume={28},
        number={2},
        pages={143--152},
        year={2001},
        publisher={MCB UP Ltd}
      }`,
      `@article{sheng2003cad,
        title={CAD-guided sensor planning for dimensional inspection in automotive manufacturing},
        author={Sheng, Weihua and Xi, Ning and Song, Mumin and Chen, Yifan},
        journal={IEEE/ASME transactions on mechatronics},
        volume={8},
        number={3},
        pages={372--380},
        year={2003},
        publisher={IEEE}
      }`,
      `@inproceedings{tan2003coordination,
        title={Coordination of human and mobile manipulator formation in a perceptive reference fame},
        author={Tan, Jindong and Xi, Ning and Goradia, Amit and Sheng, Weihua},
        booktitle={Robotics and Automation, 2003. Proceedings. ICRA'03. IEEE International Conference on},
        volume={1},
        pages={374--379},
        year={2003},
        organization={IEEE}
      }`,
      `@article{chen2005general,
        title={General framework of optimal tool trajectory planning for free-form surfaces in surface manufacturing},
        author={Chen, Heping and Xi, Ning and Sheng, Weihua and Chen, Yifan},
        journal={Journal of manufacturing science and engineering},
        volume={127},
        number={1},
        pages={49--59},
        year={2005},
        publisher={American Society of Mechanical Engineers}
      }`,
      `@article{sheng2005robot,
        title={Robot path planning for dimensional measurement in automotive manufacturing},
        author={Sheng, Weihua and Xi, Ning and Song, Mumin and Chen, Yifan},
        journal={Journal of manufacturing science and engineering},
        volume={127},
        number={2},
        pages={420--428},
        year={2005},
        publisher={American Society of Mechanical Engineers}
      }`,
      `@article{sheng2005tool,
        title={Tool path planning for compound surfaces in spray forming processes},
        author={Sheng, Weihua and Chen, Heping and Xi, Ning and Chen, Yifan},
        journal={IEEE transactions on automation science and engineering},
        volume={2},
        number={3},
        pages={240--249},
        year={2005},
        publisher={IEEE}
      }`,
      `@article{sheng2006distributed,
        title={Distributed multi-robot coordination in area exploration},
        author={Sheng, Weihua and Yang, Qingyan and Tan, Jindong and Xi, Ning},
        journal={Robotics and Autonomous Systems},
        volume={54},
        number={12},
        pages={945--955},
        year={2006},
        publisher={Elsevier}
      }`,
      `@article{wu2006mobile,
        title={Mobile self-localization using multi-dimensional scaling in robotic sensor networks},
        author={Wu, Changhua and Sheng, Weihua and Zhang, Ying},
        journal={The International Journal of Intelligent Control and Systems},
        volume={11},
        number={3},
        pages={163--175},
        year={2006},
        publisher={Citeseer}
      }`,
      `@article{sheng2008navigating,
        title={Navigating a miniature crawler robot for engineered structure inspection},
        author={Sheng, Weihua and Chen, Hongjun and Xi, Ning},
        journal={IEEE Transactions on Automation Science and Engineering},
        volume={5},
        number={2},
        pages={368--373},
        year={2008},
        publisher={IEEE}
      }`,
      `@article{tewolde2008robot,
        title={Robot path integration in manufacturing processes: Genetic algorithm versus ant colony optimization},
        author={Tewolde, Girma S and Sheng, Weihua},
        journal={IEEE Transactions on systems, man, and cybernetics-Part A: systems and humans},
        volume={38},
        number={2},
        pages={278--287},
        year={2008},
        publisher={IEEE}
      }`,
      `@article{thobbi2010achieving,
        title={Achieving remote presence using a humanoid robot controlled by a non-invasive BCI device},
        author={Thobbi, Anand and Kadam, Rohit and Sheng, Weihua},
        journal={International Journal on Artificial Intelligence and Machine Learning},
        volume={10},
        pages={41--45},
        year={2010}
      }`,
       `@article{zhu2011motion,
        title={Motion-and location-based online human daily activity recognition},
        author={Zhu, Chun and Sheng, Weihua},
        journal={Pervasive and Mobile Computing},
        volume={7},
        number={2},
        pages={256--269},
        year={2011},
        publisher={Elsevier}
      }`,
      `@article{zhu2011wearable,
        title={Wearable sensor-based hand gesture and daily activity recognition for robot-assisted living},
        author={Zhu, Chun and Sheng, Weihua},
        journal={IEEE Transactions on Systems, Man, and Cybernetics-Part A: Systems and Humans},
        volume={41},
        number={3},
        pages={569--573},
        year={2011},
        publisher={IEEE}
      }`,
      `@article{wu2010rigidity,
        title={Rigidity guided localisation for mobile robotic sensor networks},
        author={Wu, Changhua and Zhang, Ying and Sheng, Weihua and Kanchi, Saroja},
        journal={International Journal of Ad Hoc and Ubiquitous Computing},
        volume={6},
        number={2},
        pages={114--128},
        year={2010},
        publisher={Inderscience Publishers}
      }`,
      `@article{wu2011distributed,
        title={Distributed multi-actuator control for workload balancing in wireless sensor and actuator networks},
        author={Wu, Changhua and Tewolde, Girma S and Sheng, Weihua and Xu, Bin and Wang, Yu},
        journal={IEEE Transactions on Automatic Control},
        volume={56},
        number={10},
        pages={2462--2467},
        year={2011},
        publisher={IEEE}
      }`,
      `@article{sheng2010wearable,
        title={A wearable computing approach for hand gesture and daily activity recognition in human-robot interaction},
        author={Sheng, W-H and Zhu, Chun},
        journal={Shandong Daxue Xuebao(GongxueBan)},
        volume={40},
        number={3},
        pages={37--50},
        year={2010},
        publisher={Shandong University}
      }`,
      `@article{zhu2012realtime,
        title={Realtime recognition of complex human daily activities using human motion and location data},
        author={Zhu, Chun and Sheng, Weihua},
        journal={IEEE Transactions on Biomedical Engineering},
        volume={59},
        number={9},
        pages={2422--2430},
        year={2012},
        publisher={IEEE}
      }`,
      `@article{la2012development,
        title={Development of a small-scale research platform for intelligent transportation systems},
        author={La, Hung Manh and Lim, Ronny Salim and Du, Jianhao and Zhang, Sijian and Yan, Gangfeng and Sheng, Weihua},
        journal={IEEE Transactions on Intelligent Transportation Systems},
        volume={13},
        number={4},
        pages={1753--1762},
        year={2012},
        publisher={IEEE}
      }`,
      `@article{sheng2012localisation,
        title={Localisation of multiple mobile subjects using multidimensional scaling and sensor fusion},
        author={Sheng, Weihua and Garimella, Ravi K},
        journal={International Journal of Ad Hoc and Ubiquitous Computing},
        volume={11},
        number={4},
        pages={214--224},
        year={2012},
        publisher={Inderscience Publishers}
      }`,
      `@article{la2012dynamic,
        title={Dynamic target tracking and observing in a mobile sensor network},
        author={La, Hung Manh and Sheng, Weihua},
        journal={Robotics and Autonomous Systems},
        volume={60},
        number={7},
        pages={996--1009},
        year={2012},
        publisher={Elsevier}
      }`,
      `@article{la2013distributed,
        title={Distributed sensor fusion for scalar field mapping using mobile sensor networks},
        author={La, Hung Manh and Sheng, Weihua},
        journal={IEEE transactions on cybernetics},
        volume={43},
        number={2},
        pages={766--778},
        year={2013},
        publisher={IEEE}
      }`,
      `@article{la2013multi,
        title={Multi-agent motion control in cluttered and noisy environments},
        author={La, Hung Manh and Sheng, Weihua},
        journal={Journal of Communications},
        volume={8},
        number={1},
        year={2013}
      }`,
      `@article{liu2013exponential,
        title={Exponential H∞ synchronization and state estimation for chaotic systems via a unified model},
        author={Liu, Meiqin and Zhang, Senlin and Fan, Zhen and Zheng, Shiyou and Sheng, Weihua},
        journal={IEEE transactions on neural networks and learning systems},
        volume={24},
        number={7},
        pages={1114--1126},
        year={2013},
        publisher={IEEE}
      }`,
      `@article{lim2014robotic,
        title={A robotic crack inspection and mapping system for bridge deck maintenance},
        author={Lim, Ronny Salim and La, Hung Manh and Sheng, Weihua},
        journal={IEEE Transactions on Automation Science and Engineering},
        volume={11},
        number={2},
        pages={367--378},
        year={2014},
        publisher={IEEE}
      }`,
      `@article{liu2014output,
        title={Output Tracking Control of Discrete-Time Nonlinear Systems via Standard Neural Network Models},
        author={Liu, Meiqin and Zhang, Senlin and Chen, Haiyang and Sheng, Weihua},
        journal={IEEE transactions on neural networks and learning systems},
        volume={25},
        number={10},
        pages={1928--1935},
        year={2014},
        publisher={IEEE}
      }`,
      `@article{la2015multirobot,
        title={Multirobot cooperative learning for predator avoidance},
        author={La, Hung Manh and Lim, Ronny and Sheng, Weihua},
        journal={IEEE Transactions on Control Systems Technology},
        volume={23},
        number={1},
        pages={52--63},
        year={2015},
        publisher={IEEE}
      }`,
      `@article{sheng2015integrated,
        title={An integrated framework for human--robot collaborative manipulation},
        author={Sheng, Weihua and Thobbi, Anand and Gu, Ye},
        journal={IEEE transactions on cybernetics},
        volume={45},
        number={10},
        pages={2030--2041},
        year={2015},
        publisher={IEEE}
      }`,
      `@article{liu2015h,
        title={H∞ synchronization of two different discrete-time chaotic systems via a unified model},
        author={Liu, Meiqin and Chen, Haiyang and Zhang, Senlin and Sheng, Weihua},
        journal={International Journal of Control, Automation and Systems},
        volume={13},
        number={1},
        pages={212--221},
        year={2015},
        publisher={Springer}
      }`,
      `@article{la2015cooperative,
        title={Cooperative and active sensing in mobile sensor networks for scalar field mapping},
        author={La, Hung M and Sheng, Weihua and Chen, Jiming},
        journal={IEEE Transactions on Systems, Man, and Cybernetics: Systems},
        volume={45},
        number={1},
        pages={1--12},
        year={2015},
        publisher={IEEE}
      }`,
      `@article{sheng2015robot,
        title={Robot semantic mapping through human activity recognition: A wearable sensing and computing approach},
        author={Sheng, Weihua and Du, Jianhao and Cheng, Qi and Li, Gang and Zhu, Chun and Liu, Meiqin and Xu, Guoqing},
        journal={Robotics and Autonomous Systems},
        volume={68},
        pages={47--58},
        year={2015},
        publisher={Elsevier}
      }`,
      `@article{zhang2015observer,
        title={Observer-based l2--l∞ control for discrete-time nonhomogeneous Markov jump Lur׳ e systems with sensor saturations},
        author={Zhang, Yujie and Ou, Yongsheng and Zhou, Yimin and Wu, Xinyu and Sheng, Weihua},
        journal={Neurocomputing},
        volume={162},
        pages={141--149},
        year={2015},
        publisher={Elsevier}
      }`,
      `@article{du2016design,
        title={Design and Evaluation of a Teleoperated Robotic 3-D Mapping System using an RGB-D Sensor},
        author={Du, Jianhao and Mouser, Craig and Sheng, Weihua},
        journal={IEEE Transactions on Systems, Man, and Cybernetics: Systems},
        volume={46},
        number={5},
        pages={718--724},
        year={2016},
        publisher={IEEE}
      }`,
      `@article{zhu2015wearable,
        title={Wearable sensor-based behavioral anomaly detection in smart assisted living systems},
        author={Zhu, Chun and Sheng, Weihua and Liu, Meiqin},
        journal={IEEE Transactions on Automation Science and Engineering},
        volume={12},
        number={4},
        pages={1225--1234},
        year={2015},
        publisher={IEEE}
      }`,
      `@article{do2016human,
        title={Human-assisted sound event recognition for home service robots},
        author={Do, Ha Manh and Sheng, Weihua and Liu, Meiqin},
        journal={Robotics and Biomimetics},
        volume={3},
        number={1},
        pages={1--12},
        year={2016},
        publisher={Springer}
      }`,
      `@ARTICLE{7287820,  
        author={Sheng, Weihua and Matsuoka, Yoky and Ou, Yongsheng and Liu, Meiqin and Mastrogiovanni, Fulvio},  
        journal={IEEE Transactions on Automation Science and Engineering},   
        title={Guest Editorial Special Section on Home Automation},   
        year={2015},  
        volume={12},  
        number={4},  
        pages={1155-1156},  
        doi={10.1109/TASE.2015.2473215}}`,
        `@ARTICLE{7194826,
          author={J. {Du} and C. {Mouser} and W. {Sheng}},
          journal={IEEE Transactions on Systems, Man, and Cybernetics: Systems},
          title={Design and Evaluation of a Teleoperated Robotic 3-D Mapping System using an RGB-D Sensor},
          year={2016},
          volume={46},
          number={5},
          pages={718-724},
          doi={10.1109/TSMC.2015.2461186},
          ISSN={2168-2232},
          month={May},}`,
          `@article{doi:10.1080/08839514.2017.1378205,
          author = {Guanci Yang and Weihua Sheng and Shaobo Li and Yang Wang and Fei Xu},
          title = {Game-theoretic Evolutionary Algorithm Based on Behavioral Expectation and its Performance Analysis},
          journal = {Applied Artificial Intelligence},
          volume = {31},
          number = {5-6},
          pages = {493-517},
          year  = {2017},
          publisher = {Taylor & Francis},
          doi = {10.1080/08839514.2017.1378205},
          }`,
          `@article{GU20181,
          title = "Automated assembly skill acquisition and implementation through human demonstration",
          journal = "Robotics and Autonomous Systems",
          volume = "99",
          pages = "1 - 16",
          year = "2018",
          issn = "0921-8890",
          doi = "https://doi.org/10.1016/j.robot.2017.10.002",
          url = "http://www.sciencedirect.com/science/article/pii/S0921889016303888",
          author = "Ye Gu and Weihua Sheng and Christopher Crick and Yongsheng Ou",
          }`,
          `@article{article,
          author = {Pham, Minh and Mengistu, Yehenew and Do, Ha and Sheng, Weihua},
          year = {2017},
          month = {11},
          pages = {},
          title = {Delivering home healthcare through a Cloud-based Smart Home Environment (CoSHE)},
          volume = {81},
          journal = {Future Generation Computer Systems},
          doi = {10.1016/j.future.2017.10.040}
          }`,          
          `@article{DO201874,
          title = "RiSH: A robot-integrated smart home for elderly care",
          journal = "Robotics and Autonomous Systems",
          volume = "101",
          pages = "74 - 92",
          year = "2018",
          issn = "0921-8890",
          doi = "https://doi.org/10.1016/j.robot.2017.12.008",
          url = "http://www.sciencedirect.com/science/article/pii/S0921889017300477",
          author = "Ha Manh Do and Minh Pham and Weihua Sheng and Dan Yang and Meiqin Liu",
          }`,          
          `@article{Yang_2018, 
          author={Yang, Guanci and Yang, Jing and Sheng, Weihua and Junior, Francisco and Li, Shaobo}, 
          journal={Sensors},
          title={Convolutional Neural Network-Based Embarrassing Situation Detection under Camera for Social Robot in Smart Homes}, 
          year={2018},
          volume={18}, 
          number={5},
          pages={1530}},
          ISSN={1424-8220}, 
          DOI={10.3390/s18051530}, 
          url={http://dx.doi.org/10.3390/s18051530}, 
          publisher={MDPI AG}, 
          month={May},}`,
          `@ARTICLE{8532040,
          author={D. {Tran} and H. {Manh Do} and W. {Sheng} and H. {Bai} and G. {Chowdhary}},
          journal={IET Intelligent Transport Systems},
          title={Real-time detection of distracted driving based on deep learning},
          year={2018},
          volume={12},
          number={10},
          pages={1210-1219},
          doi={10.1049/iet-its.2018.5172},
          ISSN={1751-9578},
          month={},}`,
          `@article{article,
          author = {Du, Jianhao and Sheng, Weihua and Liu, Meiqin},
          year = {2018},
          month = {07},
          pages = {1-1},
          title = {A Human-Robot Collaborative System for Robust 3D Mapping},
          volume = {PP},
          journal = {IEEE/ASME Transactions on Mechatronics},
          doi = {10.1109/TMECH.2018.2854544}
          }`,
          `@article{article,
          author = {Wang, Qi and Fan, Zhen and Sheng, Wei-hua and Zhang, Sen-lin and Liu, Mei-qin},
          year = {2019},
          month = {08},
          pages = {1036-1048},
          title = {Finding misplaced items using a mobile robot in a smart home environment},
          volume = {20},
          journal = {Frontiers of Information Technology & Electronic Engineering},
          doi = {10.1631/FITEE.1800275}
          }`,
          `"@ARTICLE{8510895,
          author={M. {Pham} and D. {Yang} and W. {Sheng}},
          journal={IEEE Transactions on Automation Science and Engineering},
          title={A Sensor Fusion Approach to Indoor Human Localization Based on Environmental and Wearable Sensors},
          year={2019},
          volume={16},
          number={1},
          pages={339-350},
          doi={10.1109/TASE.2018.2874487},
          ISSN={1558-3783},
          month={Jan},}"`,          
          `@ARTICLE{8556397,
          author={D. {Tran} and J. {Du} and W. {Sheng} and D. {Osipychev} and Y. {Sun} and H. {Bai}},
          journal={IEEE Transactions on Intelligent Transportation Systems},
          title={A Human-Vehicle Collaborative Driving Framework for Driver Assistance},
          year={2019},
          volume={20},
          number={9},
          pages={3470-3485},
          doi={10.1109/TITS.2018.2878027},
          ISSN={1558-0016},
          month={Sep.},}`
]


const journal = [
    ['[J47] Duy Tran, Jianhao Du,  Weihua Sheng, Denis Osypichev, Yuge Sun, He Bai. A Human-Vehicle Collaborative Driving Framework for Driver Assistance, IEEE Transactions on Intelligent Transportation Systems, Volume: 20, Issue: 9 , Page: 3470-3485, Sept. 2019.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8510895'],
    ['[J46] Minh Pham, Dan Yang, Weihua Sheng, A sensor fusion approach to indoor human localization based on environmental and wearable sensors, Vol. 16. No. 1, Page 339-350. IEEE Transactions on Automation Science and Engineering. Jan. 2019.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8510895'],
    ['[J45] Qi Wang, Zhen Fan, Senlin Zhang, Meiqin Liu and Weihua Sheng, Finding Misplaced Items Using a Mobile Robot in a Smart Home Environment, by Frontiers of Information Technology and Electronic Engineering. 2018.', '[bib]', '[link]', 'https://www.researchgate.net/publication/335582813_Finding_misplaced_items_using_a_mobile_robot_in_a_smart_home_environment'],
    ['[J44] Jianhao Du, Weihua Sheng, Meiqin Liu. A Human-Robot Collaborative System for Robust 3D Mapping. IEEE Transactions on Mechatronics. Vol. 23, No. 5, Pages 2358-2368, 2018.', '[bib]', '[link]', 'https://www.researchgate.net/publication/326281520_A_Human-Robot_Collaborative_System_for_Robust_3D_Mapping'],
    ['[J43] Duy Tran, Ha Manh Do, Weihua Sheng, He Bai and Girish Chowdhary, Real-time Detection of Distracted Driving based on Deep Learning, Volume 12, Issue 10.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8532040'],
    ['[J42] Guanci Yang, Jing Yang, Weihua Sheng, Francisco Erivaldo Fernandes Junior, Shaobo Li, Convolutional Neural Network-based Embarrassing Situation Detection under Camera in Smart Homes, Sensors,12;18(5), 2018.', '[bib]', '[link]', 'https://www.mdpi.com/1424-8220/18/5/1530'],
    ['[J41] Ha Do, Jianhao Du, Minh Pham, Weihua Sheng, Dan Yang, Meiqin Liu, RiSH: A Robot-integrated Smart Home for Elderly Care.   Robotics and Autonomous Systems, Volume 101, Pages 74–92. March 2018.', '[bib]', '[link]', 'https://doi.org/10.1016/j.robot.2017.12.008'],
    ['[J40] Minh Pham, Yehenew Mengistu, Ha Do, Weihua Sheng, Delivering Home Healthcare through a Cloud-based Smart Home Environment (CoSHE).  The Elsevier Journal of Future Generation Computer Systems. Volume 81, Pages 129-140, April 2018.', '[bib]', '[link]', 'https://www.researchgate.net/publication/320915339_Delivering_home_healthcare_through_a_Cloud-based_Smart_Home_Environment_CoSHE'],
    ['[J39] Ye Gu, Weihua Sheng, Chris Crick, Yongsheng Ou, Automated Assembly Skill Acquisition and Implementation through Learning by Demonstration. Robotics and Autonomous Systems. Volume 99, Pages 1-16. January 2018.', '[bib]', '[link]', 'https://doi.org/10.1016/j.robot.2017.10.002'],
    ['[J38] Guanci Yang, Weihua Sheng, Shaobo Li, Yang Wang, Fei Xu, Game theoretic Evolutionary Algorithm Based on Behavioral Expectation and its Performance Analysis, Applied Artificial Intelligence, 31:5-6, 493-517, 2017.', '[bib]', '[link]', 'https://doi.org/10.1080/08839514.2017.1378205'],
    ['[J37] Jianhao Du, Craig Mouser, Weihua Sheng, Design and Evaluation of a Teleoperated Robotic 3D Mapping System using a RGB-D Sensor.  IEEE Transactions on System Man and Cybernetics: Systems, Volume: 46,  Issue 5. Page 718-724, 2016.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7194826'],
    ['[J36] Weihua Sheng, Meiqin Liu, Yoki Matsuoka, Yongsheng Ou, Fovio Mastrogiovanni, Guest Editorial, Special Section on Home Automation, IEEE Transactions on Automation Science and Engineering (T-ASE), Volume 12 Issue 4. Page 1155-1156, Oct. 2015.','[bib]','[link]', 'http://ieeexplore.ieee.org/document/7287820/?arnumber=7287820'],
    ['[J35] Ha Manh Do, Weihua Sheng, and Meiqin Liu. Human-assisted sound event recognition for home service robots. Thematic series on Real-time Computing and Robotics, Springer Open Journal, Robotics and Biomimetics, 3(1):1–12, 2016.', '[bib]', '[link]', 'http://link.springer.com/article/10.1186/s40638-016-0042-2'],
    ['[J34] C. Zhu, W. Sheng, and Meiqin Liu, Wearable Sensor-based Behavioral Anomaly Detection in Smart Assisted Living Systems, IEEE Transactions on Automation Science and Engineering, Volume:12 , Issue: 4, 2015. Pages 1225 – 1234.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/document/7270350/?arnumber=7270350'],
    ['[J33] Jianhao Du, Craig Mouser, Weihua Sheng, Design and Evaluation of a Teleoperated Robotic 3D Mapping System using a RGB-D Sensor. IEEE Transactions on System Man and Cybernetics: Systems, Volume:46 , Issue 5. Page 718-724, 2016.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/document/7194826/?arnumber=7194826'],
    ['[J32] Yujie Zhang, Yongsheng Ou, Yimin Zhou, Xinyu Wu, Weihua Sheng, Observer-Based l2-l∞ Control for Discrete-time Nonhomogeneous Markov Jump Lure Systems with Sensor Saturations, Nerucomputing, Vol 162, Page 141-149. 2015.', '[bib]', '[link]', 'http://www.sciencedirect.com/science/article/pii/S0925231215003574'],
    ['[J31] Weihua Sheng, Jianhao Du, Qi Cheng, Chun Zhu, Meiqin Liu, Guoqing Xu, Robot Semantic Mapping through Human Activity Recognition: A Wearable Sensing and Computing Approach. Robotics and Autonomous Systems. Volume 68, Pages 47–58, June 2015.', '[bib]', '[link]', 'http://www.sciencedirect.com/science/article/pii/S0921889015000202'],
    ['[J30] H. La, W. Sheng, Jiming Chen, Cooperative and Active Sensing in Mobile Sensor Networks for Scalar Field Mapping, IEEE Transactions on System, Man and Cybernetics: Systems. Vol. 45, No. 1. Page 1-12, 2015.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/document/6815791/?arnumber=6815791'],
    ['[J29] Meiqin Liu, Haiyang Chen, Senlin Zhang, Weihua Sheng, and Zhen Fan, "H∞ synchronization of two different discrete-time chaotic systems via a unified model," International Journal of Control, Automation, and Systems. vol. 13, no.1, pp.212-221, Feb. 2015.', '[bib]', '[link]', 'http://link.springer.com/article/10.1007/s12555-013-0207-5'],
    ['[J28] W. Sheng, A. Thobbi, Y. Gu, An Integrated Framework for Human-Robot Collaborative Manipulation, IEEE Transactions on Cybernetics. Vol. 45, Issue 10. Page 2030-2041, 2015.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/document/6942235/?arnumber=6942235'],
    ['[J27] H. La, R. Lim and W. Sheng, Multi-robot cooperative learning for predator avoidance, IEEE Transactions on Control Systems Technology, Vol. 23, No. 1, Page 52-63, 2015.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/document/6783781/?arnumber=6783781'],
    ['[J26] Meiqin Liu, Senlin Zhang, Haiyang Chen, and Weihua Sheng, "H∞ output tracking control of discrete-time nonlinear systems via standard neural network models," IEEE Transactions on Neural Networks and Learning Systems, Vol. 25, No. 10, Page 1928-1935. October, 2014', '[bib]', '[link]', 'http://ieeexplore.ieee.org/document/6709673/?arnumber=6709673'],
    ['[J25] Ronny Lim, Hung La and Weihua Sheng, A Robotic Crack Inspection and Mapping System for Bridge Deck Maintenance. IEEE Transactions on Automation Science and Engineering. VOL. 11, NO. 2, Page 367-378. APRIL 2014.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/document/6705706/?arnumber=6705706'],
    ['[J24] M. Liu, S. Zhang, Z. Fan, S. Zheng, W. Sheng, Exponential H∞ synchronization and state estimation for chaotic systems via a unified model, IEEE Transactions on Neural Networks and Learning Systems. vol.24, no.7, Pages 1114-1126, July 2013.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/document/6491485/?arnumber=6491485'],
    ['[J23] H. La and W. Sheng, Multi-agent motion control in cluttered and noisy environments, Journal of Communication, July, 2012.', '[bib]', '[link]', 'http://www.jocm.us/index.php?m=content&c=index&a=show&catid=56&id=169'],
    ['[J22] H. La and W. Sheng, Distributed Sensor Fusion for Scalar Field Mapping using Mobile Sensor Networks, IEEE Transactions on Systems, Man, and Cybernetics - Part B, Oct. 2012.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/stamp/stamp.jsp?arnumber=06327372'],
    ['[J21] H. La and W. Sheng, Dynamic Targets Tracking and Observing in a Mobile Sensor Network, Robotics and Autonomous Systems. Volume 60, Issue 7, Pages 996–1009, July, 2012.', '[bib]', '[link]', 'http://www.sciencedirect.com/science/article/pii/S0921889012000565'],
    ['[J20] W. Sheng and R. Garimella, Localization of Multiple Mobile Subjects using Multidimensional Scaling and Sensor Fusion. International Journal of Ad hoc and Ubiquitous Computing. Vol. 11 No. 4, Page 214-224. 2012.', '[bib]', '[link]', 'http://www.inderscienceonline.com/doi/abs/10.1504/IJAHUC.2012.050431'],
    ['[J19] Hung Manh La, Ronny Salim Lim, Jianhao Du, Sijian Zhang, Gangfeng Yan and Weihua Sheng, Development of a Small-Scale Research Platform for Intelligent Transportation Systems, IEEE Transactions on Intelligent Transportation Systems. Issue: 99, Page(s): 1 - 10 2012.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=6248708&tag=1'],
    ['[J18] C. Zhu, and W. Sheng, Realtime Recognition of Complex Human Daily Activities Using Human Motion and Location Data, IEEE Transactions on Biomedical Engineering. Vol. 59, No. 9, Sept. Pages: 2422-2430. 2012.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=6168228'],
    ['[J17] C. Zhu and W. Sheng, A Wearable Computing Approach for Hand Gesture and Daily Activity Recognition in Human-robot Interaction, Journal of Shangdong University, China. Vol 40. No. 3, Page 37-50. 2010.', '[bib]', '[link]', 'http://en.cnki.com.cn/Article_en/CJFDTOTAL-SDGY201003009.htm'],
    ['[J16] C.Wu, G. Tewolde, W. Sheng, Y. Wang, Distributed Multi-Actuators Control for Workload Balancing in Wireless Sensor and Actuator Networks, IEEE Transactions on Automatic Control. Vol. 56, No. 10, Page 2462-2467, 2011.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=5979135'],
    ['[J15] C. Wu, Y. Zhang, W. Sheng and S. Kanchi, Rigidity Guided Localization for Mobile Robotic Networks, International Journal of Ad hoc and Ubiquitous Computing. Volume 6 , Issue 2 Pages: 114-128 , 2010.', '[bib]', '[link]', 'http://www.inderscienceonline.com/doi/abs/10.1504/IJAHUC.2010.034324'],
    ['[J14] C. Zhu and W. Sheng, Wearable Sensors-based Hand Gesture and Daily Activity Recognition for Robot-assisted Living. IEEE Transactions on System, Man and Cybernetics, Part A, Volume 41, Issue:3, page 569-573, May. 2011.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=5682421'],
    ['[J13] C. Zhu and W. Sheng, Motion and Location based Online Human Daily Activity Recognition, Journal of Pervasive and Mobile Computing, page: 1574-1192, 2010.', '[bib]', '[link]', 'http://www.sciencedirect.com/science/article/pii/S1574119210001215'],
    ['[J12] A. Thobbi, R. Kadam, W. Sheng, Achieving Remote Presence using a Humanoid Robot Controlled by Non-Invasive BCI Device, ICGST International Journal on Automation, Robotics and Autonomous Systems, Volume 10, Issue I, page 41-45, October, 2010.', '[bib]', '[link]', 'http://anandthobbi.yolasite.com/resources/ICGST.pdf'],
    ['[J11] G. Tewolde, W. Sheng, Robot Path Integration in Manufacturing Processes: Genetic Algorithm vs. Ant Colony Optimization, IEEE Transactions on System, Man and Cybernetics, Part: A, Vol. 38, No. 2, page 278 – 287, 2008.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=4443568'],
    ['[J10] W. Sheng, H. Chen, and N. Xi. Navigating a micro crawler robot for engineered structure inspection. IEEE Transactions on Automation Science and Engineering, 5(2):368–373, 2008.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=4441728'],
    ['[J9] C. Wu, W. Sheng, Y. Zhang, Mobile Self-Localization using Multi-Dimensional Scaling in Robotic Sensor Networks, International Journal of Intelligent Control and System, pp.163-175. Vol. 11, No. 3, Sept. 2006.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=4209717'],
    ['[J8] W. Sheng, Q. Yang, J. Tan and N. Xi, Distributed Multi-robot Coordination in Area Exploration, Robotics and Autonomous Systems (Journal). Page: 945-955, Issue 54. 2006.', '[bib]', '[link]', 'http://www.sciencedirect.com/science/article/pii/S092188900600114X'],
    ['[J7] W. Sheng, H. Chen, N. Xi and Y. Chen, Tool Path Planning for Compound Surfaces in Spray Forming Processes. IEEE Transactions on Automation Science and Engineering. pp.240-249, VOL. 2, NO. 3, 2004.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=1458717'],
    ['[J6] W. Sheng, N. Xi, M. Song, Y. Chen, Robot Path Planning for Dimensional Measurement in Automotive Manufacturing. Journal of Manufacturing Science and Engineering. pp. 420-428, Volume 127, Issue 2, 2005.', '[bib]','[link]', 'http://manufacturingscience.asmedigitalcollection.asme.org/article.aspx?articleid=1449783'],
    ['[J5] H. Chen, N. Xi, W. Sheng, Y. Chen, General Framework of Optimal Tool Trajectory Planning for Free-form Surfaces in Surface Manufacturing, Journal of Manufacturing Science and Engineering, pp. 49-59. Vol. 127, Feb, 2005.', '[bib]', '[link]', 'http://manufacturingscience.asmedigitalcollection.asme.org/article.aspx?articleid=1449447'],
    ['[J4] Jindong Tan, Ning Xi, Amit Goradia and Weihua Sheng , Coordination of Human and Formations of Mobile Manipulators in a Perceptive Reference Frame, pp. 201 - 216 , Vol. 2, No.3/4, International Journal on Vehicle Autonomous Systems, 2004.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/document/1241624/?arnumber=1241624'],
    ['[J3] W. Sheng, N. Xi, M. Song, Y. Chen, CAD-Guided Sensor Planning for Dimensional Inspection in Automotive Manufacturing, IEEE/ASME Transactions on Mechatronics, pp.372-380, Vol. 8, No. 3 Sept. 2003.', '[bib]', '[link]', 'http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=1232297'],
    ['[J2] W. Sheng, N. Xi, M. Song, Y. Chen, CAD-Guided Robot Motion Planning. International Journal of Industrial Robot, pp.143-151, Vol. 28, No. 2, 2001.', '[bib]', '[link]', 'http://www.emeraldinsight.com/journals.htm?articleid=875196&show=abstract'],
    ['[J1] Heping Chen, Ning Xi, Weihua Sheng, Muming Song, Yifan Chen, CAD-Based Automated Robot Trajectory Planning for Spray Painting of Free-Form Surfaces, International Journal of Industrial Robot, pp. 426-433, Volume 29 , Number 5 . 2002.', '[bib]', '[link]', 'http://www.emeraldinsight.com/journals.htm?articleid=1454232&show=abstract']]


export default function Journal() {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
    setOpen(false);
    };

    const handleTooltipOpen = () => {
    setOpen(true);
    };
    return(
        <Box component="main" sx={{ p: 3 }}>
            <Box sx={{mb: 1}}>
                <Typography variant='h4' align='center'>Journal</Typography>
                <Typography variant='h6' align='center'>Journal Publications</Typography>
            </Box>
            <Box>
                {journal.map((item, index) => (
                    // <ListItem sx={{mb: 1}}>
                    //     <ListItemText key={index}
                    //             primary={item[0]+item[1]}
                    //         />
                    // </ListItem>
                    <><Typography>{item[0]}
                        <Link href={'#'+String(Math.abs(index-47))}>{item[1]}</Link>&nbsp;
                        <Link target="_blank" href={item[3]}>{item[2]}</Link>
                    </Typography><br/></>
                ))}
            </Box>
            <Typography align='center' variant='h5'>Bibliography</Typography>
            { bibFile.map((bib, index) => (
            <Stack direction="row" justifyContent="center">
                <Card id={index+1} elevation={10} sx={{ width: 600, maxWidth: 600, mb: 2 }}>
                <CardContent>
                    <Typography>J{index+1}</Typography>
                    <Typography key={index+1} variant="body2" color="text.secondary">
                    {bib}
                    </Typography>
                </CardContent>
                <CardActions>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                        PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Add" 
                      arrow>
                        <Button onClick={() => {navigator.clipboard.writeText(bib); handleTooltipOpen(this)}} size="small">copy</Button>
                    </Tooltip>
                </ClickAwayListener>
                </CardActions>
                </Card>
            </Stack>
            ))}
      </Box>
    )
}