import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper'
import CardMedia from '@mui/material/CardMedia'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom';

function importAll(r) {
  let images = {};
   r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images
 }
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

const faculty = [
  {
  name: 'Dr. Weihua Sheng',
  phone: '(405)744-7590',
  profession: 'Professor',
  // resint: 'Social Robotics, Smart Health, Embedded Computing, Intelligent Transportation Systems',
  email: 'weihua.sheng AT okstate.edu',
  image: images['sheng1.jpg']
  }
]

const phd = [
{
  name: 'Zhidong Su',
  resint: 'Mobile robotics, natural language processing, computer vision,and machine learning',
  phone: '(405)780-2992',
  email: 'zhidong.su AT okstate.edu',
  image: images['zhidong-headshot.jpg'],
},
{
  name: 'Jiaxing Lu',
  resint: 'Artificial Intelligence, Machine Learning, and Pattern Recognition',
  phone: '(201)539-0389',
  email: 'jiaxing.lu AT okstate.edu',
  image: images['JiaxingLu.jpg'],
},
{
  name: 'Fei Liang',
  resint: 'Wearable Devices, Smart Home, Machine Learning and Digital Twin',
  phone: '(405)714-4760',
  email: 'fei.liang AT okstate.edu',
  image: images['fei.jpeg']
},
{
  name: 'Zhaohua Zhang',
  resint: 'Autonomous Driving, Reinforcement Learning and Embedded System',
  phone: '(315)484-6798',
  email: 'zhaohua.zhang AT okstate.edu',
  image: images['zhaohua.jpeg']
},
{
  name: 'Zhanjie Chen',
  resint: 'Visual SLAM, 3D object detection and Robot navigation',
  phone: '(706)255-9156',
  email: 'zhanjie.chen AT okstate.edu',
  image: images['zhanjiechen.jpg']
}
]

// const ms = [
// {
//   name: 'Mohammed Mukarram',
//   resint: 'Machine Learning, Big Data, IoT',
//   phone: '(405)926-0247',
//   email: 'mohammed.mukarram AT okstate.edu',
//   image: images['mohammed.png']
// }
// ]


const ra = [
{
  name: 'Joel Quarnstrom',
  resint: 'Robotics and control system, robot dynamics simulation, 3D printing',
  // phone: '(405)926-0247',
  email: 'joel.quarnstrom AT okstate.edu',
  image: images['joel.jpg']
}
]

const bs = [
{
  name: 'Brandon Ong',
  resint: 'Embedded Systems Development, VLSI, and Computer Vision',
  phone: '(405)905-9535',
  // profession: 'London, UK',
  image: images['BrandonOng.jpg']
},
{
  name: 'Nick Loeffelholz',
  resint: 'Smart Home, Robotic Assistance technology, Alternative Power, and Electroacoustics',
  phone: '(405)435-0557',
  email: 'nick.loeffelholz AT okstate.edu',
  image: images['NickLoeffelholz.jpg']
},
{
  name: 'Daniel Albrecht',
  resint: 'Machine Learning, Computer Vision, and Mobile Robotics',
  phone: '(918)290-1854',
  email: 'daniel.albrecht AT okstate.edu',
  image: images['danielalbrecht2.jpg']
}
]

const alumphd = [
  {
    name: 'Hung La',
    profession: 'PhD (2011), Assistant Professor, University of Nevada, Reno, NV, USA.',
    image: images['hung.jpg']
  },
  {
    name: 'Chun Zhu, PhD (2011)',
    resint: 'Applied Scientist, Microsoft Corporation, CA.',
    image: images['chun.jpg']
  },
  {
    name: 'Ye Gu',
    email: 'ye.gu AT okstate.edu',
    image: images['guye.jpg']
  },
  {
    name: 'Jianhao Du',
    email: 'jianhao.du AT okstate.edu',
    image: images['jianhao.jpg']
  },
  {
    name: 'Ha Do',
    resint: 'Mobile robotics, computer vision, and embedded systems development',
    phone: '(405) 744-4799',
    email: 'ha.do AT okstate.edu',
    image: images['HaDo.jpg']
  },
  {
    name: 'Minh Pham',
    resint: 'Digital health, smart homes, and wearable computing',
    phone: '(405) 744-4799',
    email: 'minh.pham AT okstate.edu',
    image: images['MinhPham.jpg']
  },
  {
    name: 'Duy Tran',
    resint: 'Intelligent Transportation Systems',
    phone: '(405) 744-4799',
    email: 'duydt AT okstate.edu',
    image: images['duy.jpg']
  }
]


const alumms = [
  {
    name: 'Ricardo Hernandez',
    resint: 'Robotics, Machine Learning, Embedded System Development',
    phone: '(405)430-6792',
    email: 'ricardh AT okstate.edu',
    image: images['RicardoHernandez2.jpg']
  },
  {
    name: 'Yehenew Mengistu',
    resint: 'Machine learning, computer vision, and embedded system development',
    phone: '(405) 744-4799',
    email: 'yehenew.mengistu AT okstate.edu',
    image: images['Yehenew.jpg']
  },
  {
    name: 'Kiran Muniraju',
    resint: 'Artificial Intelligence',
    phone: '(405) 744-4799',
    email: 'kiran.muniraju AT okstate.edu',
    image: images['kiran.jpg']
  },
  {
    name: 'Trung Nguyen',
    resint: 'Machine learning and computer vision',
    phone: '(405) 744-4799',
    email: 'trungdn AT okstate.edu',
    image: images['trungnguyen.jpg']
  },
  {
    name: 'Barath Lakshmanan',
    profession: 'Master (2016), Intel',
    image: images['Barath.jpg']
  },
  {
    name: 'Dharmendra Kallur',
    profession: 'Master (2014), RBC Medical Innovations',
    image: images['Dharma.jpg']
  },
  {
    name: 'Denis Osipychev',
    phone: '(405) 744-4799',
    email: 'denis.osipychev AT okstate.edu',
    image: images['Denis.jpg']
  },
  {
    name: 'Praveen C Batapati V',
    profession: 'Master (2013), Engineer, ABB Inc. OK',
    image: images['Praveen.jpg']
  },
  {
    name: 'Eyosiyas Tadesse',
    profession: 'Master (2013), Research Scientist',
    image: images['Eyosiyas.jpg']
  },
  {
    name: 'Faizan Shaik',
    profession: 'Master (2013), Toyota, Ann Arbor, MI. USA',
    image: images['faizan.jpg']
  },
  {
    name: 'Usman Zafar',
    profession: 'Master (2013), Linkedin',
    image: images['Usman.jpg']
  },
  {
    name: 'Craig Mouser',
    profession: 'Master (2012), Amazon',
    image: images['CraigMouser.jpg']
  },
  {
    name: 'Nizar Khemri',
    profession: 'Master (2012), Tripoli University, Libya',
    image: images['Nizar_Khemri.jpg']
  },
  {
    name: 'Haritha Srinivasan',
    profession: 'Master (2012), Wilson Mohr',
    image: images['Haritha.jpg']
  },
  {
    name: 'Sauvik Das Gupta',
    profession: 'Master (2012)',
    image: images['Sauvik.jpg']
  },
  {
    name: 'Anand Thobbi',
    profession: 'Master (2011), International Electronic Machines Corporation',
    image: images['anand.jpg']
  },
  {
    name: 'Gang Li',
    profession: 'Master (2011), Turn',
    image: images['ligang.jpg']
  },
  {
    name: 'Ronny Lim',
    profession: 'Master (2011)',
    resint: 'Integrated Microwave Technologies',
    image: images['ronny.jpg']
  },
  {
    name: 'Rohit Kadam',
    profession: 'Master (2010), CG Power Solutions',
    image: images['rohit.jpg']
  },
  {
    name: 'Ravi Kiran Garimella',
    profession: 'Master (2007), Fedex Services',
    image: images['ravi.jpg']
  },
  {
    name: 'Vimal Mehta',
    profession: 'Master (2008),Garmin',
    image: images['vimal.jpg']
  },
  {
    name: 'Chongben Tao',
    profession: 'Visiting Student (2013)',
    image: images['Chongben.jpg']
  },
  {
    name: 'Sijian Zhang',
    profession: 'Visiting Student (2009)',
    image: images['sijian.jpg']
  },
  // {
  // name: 'Mohammed Mukarram',
  // resint: 'Machine Learning, Big Data, IoT',
  // phone: '(405)926-0247',
  // email: 'mohammed.mukarram AT okstate.edu',
  // image: images['mohammed.png']
// }
]

const scholars = [
  {
    name: 'Xianfeng Yuan',
    resint: 'Mobile robotics, Machine learning and Intelligent fault diagnosis & prognosis',
    phone: '(405) 744-4799',
    email: 'xianfeng.yuan AT okstate.edu',
    image: images['Xianfeng.jpg']
  },
  {
    name: 'Jing Liu',
    resint: 'Resource management for sensor networks, Smart homes',
    phone: '(405) 762-6675',
    email: 'jing.liu10 AT okstate.edu',
    image: images['liujing.jpg']
  },
  {
    name: 'Zhijun Zhang',
    resint: 'Machine Learning and Pattern Recognition, Artificial Intelligence, Computational Intelligence, Recommendation System and Network Engineering',
    phone: '(405) 744-4799',
    email: 'zzjsdcn AT 163.com',
    image: images['ZhijunZhang.jpg']
  },
  {
    name: 'Baoshan You',
    resint: 'Mobile robotics ,Computer Vision and Embedded Systems Development',
    phone: '(405) 744-4799',
    email: 'Baoshan.you AT okstate.edu',
    image: images['baoshanyou.jpg']
  },
  {
    name: 'Baojuan Liang',
    phone: '(405) 744-4799',
    email: 'Baojuan.Liang AT okstate.edu',
    resint: 'Traffic Information and Control, Innovative energy supply technologies, wireless sensor network',
    image: images['BaojuanLiang.jpg']
  },
  {
    name: 'Guanci Yang',
    phone: '(405) 744-4799',
    resint: 'Computational Intelligence, Wearable Computing and Embedded Systems Development',
    email: 'guanci.yang AT okstate.edu',
    image: images['YangGuanci.jpg']
  },
  {	
    name: 'Dr. Dan Yang',
    profession: 'Lecturer',
    resint: 'Speech signal processing, Physiological signal detection',
    phone: '(405) 332-3336',
    email: 'yangdan AT neu.edu.cn',
    image: images['Yang.jpg']
  },
  {    	
    name: 'Dr. Ruili Zeng',
    profession : 'Associate Professor',
    resint: 'Intelligent traffic monitoring technology, Intelligent instrumentation and measurement',
    phone: '(405) 744-4799',
    email: 'ruili.zeng AT okstate.edu',
    image: images['zeng.png']
  },
  {
    name: 'B.E. Lanh Nguyen',
    professor: 'Visiting Scholar',
    resint: 'Automatic Control Systems',
    phone: '(405) 744-4799',
    email: 'lanhnguyen AT tnut.edu.vn',
    image: images['lanh.jpg']
  },
  {    	
    name: 'B.E. Tuan Tran',
    profession: 'Visiting Scholar',
    resint: 'Automatic Control Systems',
    phone: '(405) 744-4799',
    email: 'trantuan88tn AT gmail.com',
    image: images['Tuan.jpg']
  },
  {
    name: 'Prof. Zhaolin Gu',
    profession: 'Associate Professor',
    resint: 'Intelligent instrumentation and measurement',
    phone: '(405) 744-4799',
    email: 'zhaolin.gu AT okstate.edu',
    image: images['zhaolin.jpg']
  },
  {
    	
    name: 'Prof. Li Liu',
    profession: 'Associate Professor',
    resint: 'Intelligent instrumentation and measurement',
    phone: '(405) 744-4799',
    email: 'li.liu AT okstate.edu',
    image: images['liuli.jpg']
  },
  {
    	
    name: 'Theresa Pollinger',
    profession: 'Visiting Student',
    phone: '(405) 744-4799',
    email: 'theresa.pollinger AT gmx.net',
    image: images['Theresa.jpg']
  },
  {
    name: 'Sun Yuge',
    resint: 'Signal processing, Pattern recognition and BCI systems',
    phone: '(405) 332-3251',
    email: 'yuge.sun AT okstate.edu',
    image: images['Sunyuge.jpg']
  }
];

// const emeriti = [
// {
//   name: 'Hai Nguyen',
//   resint: 'v0.x creator',
//   email: 'Dallas, Texas, US',
// },
// {
//   name: 'Nathan Marks',
//   resint: 'v1.x co-creator',
//   email: 'Toronto, ON',
// },
// {
//   name: 'Kevin Ross',
//   resint: 'Core focus',
//   email: 'Franklin, Tennessee, US',
// },
// {
//   name: 'Sebastian Sebald',
//   resint: 'Core focus',
//   email: 'Freiburg, Germany',
// },
// {
//   name: 'Ken Gregory',
//   resint: 'Core focus',
//   email: 'New Jersey, US',
// },
// {
//   name: 'Tom Crockett',
//   resint: 'Core focus',
//   email: 'Los Angeles, California, US',
// },
// {
//   name: 'Maik Marschner',
//   resint: 'Core focus',
//   email: 'Hannover, Germany',
// },
// ];

function Group(props) {
    const { title, members} = props;
    const navigate = useNavigate();
    return(
        <Box component="main" sx={{ p: 1, justifyContent: 'center', alignContent: 'center'}}>
            <Typography gutterBottom component="h2" variant="h5" color='#F37224'>{title}</Typography>
            <Grid container spacing={2} columns={{md: 12}}>
            {members.map((member) => (
            <Grid key={member.name} item xs={12} md={4} sm={2}>
            {/* <Paper variant="outlined" sx={{backgroundColor: '#F37224'}}> */}
            <Paper variant="outlined" sx={{backgroundColor: '#fff'}}>
                    <Grid container wrap="nowrap" >
                        <Grid item>
                            <CardMedia
                            sx={{
                              width: 120,
                              height: 120,
                              margin: 2,
                              borderRadius: '50%',
                              flexShrink: 0,
                              backgroundColor: 'default',
                              backgroundPosition: 'top',
                              backgroundRepeat: 'no-repeat',
                              // backgroundSize: '120px 130px'
                            }}
                            image={member.image}
                            title={member.name}
                            />
                        </Grid>
                    <Grid item>
                      <Box sx={{
                         mr: 2, color: 'white'
                      }}>
                        <Typography component="h3" variant="h6">
                          <Link onClick={() => navigate('/Profile?name='+ member.name )} sx={{cursor: 'pointer',color: '#F37224', textDecoration: 'none', ":hover": 'none', ":visited": 'none', ":active": 'none', ":link": 'none'}}>{member.name}</Link>
                        </Typography>
                        {member.profession && <Typography variant="body2" sx={{color: 'black'}}>
                          {member.profession}
                        </Typography>}
                        {member.resint && <Typography variant="body2" color="textSecondary" sx={{color: 'black'}}>
                          Research Interests: {member.resint}
                        </Typography>}
                        {member.phone && <Typography variant="body2" color="textSecondary" sx={{color: 'black'}}>
                          Phone: {member.phone}
                        </Typography>}
                        {member.email && <Typography variant="body2" color="textSecondary" sx={{color: 'black'}}>
                          Email: {member.email}
                        </Typography>}
                      </Box>
                    </Grid>
                    </Grid>
                </Paper>
                </Grid>
                ))}
            </Grid>
        </Box>
    )
}


export default function People(props) {
  return (
    <Box sx={{mt: 1, p:1, minHeight: 'calc(100vh - 350px)'}}>
        <Typography variant='h4' align='center'>People</Typography>
        <Group
            title="Faculty"
            members={faculty}
            {...props}
        />
        <Group
            title="PhD Students"
            members={phd}
            {...props}
        />
        {/* <Group
            title="M.S Students"
            members={ms}
            {...props}
        /> */}
        <Group
            title="Research Assistant"
            members={ra}
            {...props}
        />
        <Group
            title="Alumni PhD"
            members={alumphd}
            {...props}
        />
        <Group
            title="Alumni MS"
            members={alumms}
            {...props}
        />
        <Group
            title="Alumni BS"
            members={bs}
            {...props}
        />
        <Group
            title="Previous Visiting Scholars"
            members={scholars}
            {...props}
        />
    </Box>
  );
}