import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List  from '@mui/material/List';
import ListItemButton  from '@mui/material/ListItemButton';
import ListItemText  from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';


const bibFile = [
    `@article{chen2002cad,
        title={CAD-based automated robot trajectory planning for spray painting of free-form surfaces},
        author={Chen, Heping and Sheng, Weihua and Xi, Ning and Song, Mumin and Chen, Yifan},
        journal={Industrial Robot: An International Journal},
        volume={29},
        number={5},
        pages={426--433},
        year={2002},
        publisher={MCB UP Ltd}
      `,
      `@article{sheng2001cad,
        title={CAD-guided robot motion planning},
        author={Sheng, Weihua and Xi, Ning and Song, Mumin and Chen, Yifan},
        journal={Industrial Robot: An International Journal},
        volume={28},
        number={2},
        pages={143--152},
        year={2001},
        publisher={MCB UP Ltd}
      `,
      `@INPROCEEDINGS{5152756,
        author={Zhu, Chun and Sheng, Weihua},
        booktitle={2009 IEEE International Conference on Robotics and Automation}, 
        title={Human daily activity recognition in robot-assisted living using multi-sensor fusion}, 
        year={2009},
        volume={},
        number={},
        pages={2154-2159},
        doi={10.1109/ROBOT.2009.5152756}}
      `,
      `@INPROCEEDINGS{5152747,
        author={La, Hung M. and Sheng, Weihua},
        booktitle={2009 IEEE International Conference on Robotics and Automation}, 
        title={Flocking control of a mobile sensor network to track and observe a moving target}, 
        year={2009},
        volume={},
        number={},
        pages={3129-3134},
        doi={10.1109/ROBOT.2009.5152747}}
      `,
      `@INPROCEEDINGS{5354657,
        author={Zhu, Chun and Sheng, Weihua},
        booktitle={2009 IEEE/RSJ International Conference on Intelligent Robots and Systems}, 
        title={Online hand gesture recognition using neural network based segmentation}, 
        year={2009},
        volume={},
        number={},
        pages={2415-2420},
        doi={10.1109/IROS.2009.5354657}}
      `,
      `@INPROCEEDINGS{5354224,
        author={Mehta, Vimal and Sheng, Weihua and Chen, Tianzhou and shi, Quan},
        booktitle={2009 IEEE/RSJ International Conference on Intelligent Robots and Systems}, 
        title={Development and calibration of a low cost wireless camera sensor network}, 
        year={2009},
        volume={},
        number={},
        pages={110-115},
        doi={10.1109/IROS.2009.5354224}}
      `,
      `@INPROCEEDINGS{5354657,
        author={Zhu, Chun and Sheng, Weihua},
        booktitle={2009 IEEE/RSJ International Conference on Intelligent Robots and Systems}, 
        title={Online hand gesture recognition using neural network based segmentation}, 
        year={2009},
        volume={},
        number={},
        pages={2415-2420},
        doi={10.1109/IROS.2009.5354657}}
      `,
      `@INPROCEEDINGS{5354450,
        author={La, Hung Manh and Sheng, Weihua},
        booktitle={2009 IEEE/RSJ International Conference on Intelligent Robots and Systems}, 
        title={Adaptive flocking control for dynamic target tracking in mobile sensor networks}, 
        year={2009},
        volume={},
        number={},
        pages={4843-4848},
        doi={10.1109/IROS.2009.5354450}}
      `,
      `@INPROCEEDINGS{5354245,
        author={Zhang, Sijian and Yan, Gangfeng and Sheng, Weihua},
        booktitle={2009 IEEE/RSJ International Conference on Intelligent Robots and Systems}, 
        title={Viewpoint planning for automated 3D digitization using a low-cost mobile platform}, 
        year={2009},
        volume={},
        number={},
        pages={4369-4374},
        doi={10.1109/IROS.2009.5354245}}
      `,
      `@INPROCEEDINGS{5509668,
        author={La, Hung Manh and Sheng, Weihua},
        booktitle={2010 IEEE International Conference on Robotics and Automation}, 
        title={Flocking control of multiple agents in noisy environments}, 
        year={2010},
        volume={},
        number={},
        pages={4964-4969},
        doi={10.1109/ROBOT.2010.5509668}}
      `,
      `@INPROCEEDINGS{5555072,
        author={Zhu, Chun and Sheng, Weihua},
        booktitle={2010 8th World Congress on Intelligent Control and Automation}, 
        title={Recognizing human daily activity using a single inertial sensor}, 
        year={2010},
        volume={},
        number={},
        pages={282-287},
        doi={10.1109/WCICA.2010.5555072}}
      `,
      `@INPROCEEDINGS{5554855,
        author={Zhang, Sijian and Gangfeng Yan and Weihua Sheng},
        booktitle={2010 8th World Congress on Intelligent Control and Automation}, 
        title={An efficient planning method for mobile robot based 3D digitization}, 
        year={2010},
        volume={},
        number={},
        pages={1348-1353},
        doi={10.1109/WCICA.2010.5554855}}
      `,
       `@INPROCEEDINGS{5695916,
        author={Zhang, Sijian and Li, Gang and Yan, Gangfeng and Sheng, Weihua},
        booktitle={2010 IEEE/ASME International Conference on Advanced Intelligent Mechatronics}, 
        title={Development and evaluation of a compact motion sensor node for wearable computing}, 
        year={2010},
        volume={},
        number={},
        pages={465-470},
        doi={10.1109/AIM.2010.5695916}}
      `,
      `@INPROCEEDINGS{5512451,
        author={Zhu, Chun and Sheng, Weihua},
        booktitle={The 2010 IEEE International Conference on Information and Automation}, 
        title={Realtime human daily activity recognition through fusion of motion and location data}, 
        year={2010},
        volume={},
        number={},
        pages={846-851},
        doi={10.1109/ICINFA.2010.5512451}}
      `,
      `@INPROCEEDINGS{5512333,
        author={Thobbi, Anand and Sheng, Weihua},
        booktitle={The 2010 IEEE International Conference on Information and Automation}, 
        title={Imitation learning of hand gestures and its evaluation for humanoid robots}, 
        year={2010},
        volume={},
        number={},
        pages={60-65},
        doi={10.1109/ICINFA.2010.5512333}}
      `,
      `@INPROCEEDINGS{5649678,
        author={Kadam, Rohit and Sijian Zhang and Qizhi Wang and Weihua Sheng},
        booktitle={2010 IEEE/RSJ International Conference on Intelligent Robots and Systems}, 
        title={Multidimensional scaling based location calibration for Wireless Multimedia Sensor Networks}, 
        year={2010},
        volume={},
        number={},
        pages={5654-5659},
        doi={10.1109/IROS.2010.5649678}}
      `,
      `@INPROCEEDINGS{5686324,
        author={Thobbi, Anand and Weihua Sheng},
        booktitle={2010 10th IEEE-RAS International Conference on Humanoid Robots}, 
        title={Imitation learning of arm gestures in presence of missing data for humanoid robots}, 
        year={2010},
        volume={},
        number={},
        pages={92-97},
        doi={10.1109/ICHR.2010.5686324}}
      `,
      `@article{article,
        author = {Thobbi, Anand and Kadam, Rohit and Sheng, Weihua},
        year = {2010},
        month = {01},
        pages = {41-45},
        title = {Achieving remote presence using a humanoid robot controlled by a non-invasive BCI device},
        volume = {10},
        journal = {Int J Artif Intell Mach Learn}
        }
      `,
      ``,
      `@INPROCEEDINGS{5948979,
        author={Ye Gu and Thobbi, Anand and Sheng, Weihua},
        booktitle={2011 IEEE International Conference on Information and Automation}, 
        title={Human-robot collaborative manipulation through imitation and reinforcement learning}, 
        year={2011},
        volume={},
        number={},
        pages={151-156},
        doi={10.1109/ICINFA.2011.5948979}}
      `,
      `@INPROCEEDINGS{5979570,
        author={Heping Chen and Weihua Sheng},
        booktitle={2011 IEEE International Conference on Robotics and Automation}, 
        title={Transformative industrial robot programming in surface manufacturing}, 
        year={2011},
        volume={},
        number={},
        pages={6059-6064},
        doi={10.1109/ICRA.2011.5979570}}
      `,
      `@INPROCEEDINGS{5980131,
        author={Ronny Salim Lim and La, Hung Manh and Zeyong Shan and Weihua Sheng},
        booktitle={2011 IEEE International Conference on Robotics and Automation}, 
        title={Developing a crack inspection robot for bridge maintenance}, 
        year={2011},
        volume={},
        number={},
        pages={6288-6293},
        doi={10.1109/ICRA.2011.5980131}}
      `,
      `@INPROCEEDINGS{5975893,
        author={La, Hung Manh and Lim, Ronny Salim and Sheng, Weihua and Chen, Heping},
        booktitle={2011 6th IEEE Conference on Industrial Electronics and Applications}, 
        title={Decentralized flocking control with a minority of informed agents}, 
        year={2011},
        volume={},
        number={},
        pages={1851-1856},
        doi={10.1109/ICIEA.2011.5975893}}
      `,
      `@inproceedings{10.1117/12.895519,
      author = {Hung Manh La and Weihua Sheng},
      title = {{Cooperative sensing in mobile sensor networks based on distributed consensus}},
      volume = {8137},
      booktitle = {Signal and Data Processing of Small Targets 2011},
      editor = {Oliver E. Drummond},
      organization = {International Society for Optics and Photonics},
      publisher = {SPIE},
      pages = {81370Y},
      keywords = {Cooperative sensing, Sensor fusion, Flocking control, Mobile sensor networks},
      year = {2011},
      doi = {10.1117/12.895519},
      URL = {https://doi.org/10.1117/12.895519}
      }
      `,
      `@inproceedings{10.1117/12.895520,
      author = {Gang Li and Jianhao Du and Chun Zhu and Weihua Sheng},
      title = {{A cost-effective and open mobile sensor platform for networked surveillance}},
      volume = {8137},
      booktitle = {Signal and Data Processing of Small Targets 2011},
      editor = {Oliver E. Drummond},
      organization = {International Society for Optics and Photonics},
      publisher = {SPIE},
      pages = {813713},
      keywords = {Networked Surveillance, Collaborative Target Localization, Robot Operating System},
      year = {2011},
      doi = {10.1117/12.895520},
      URL = {https://doi.org/10.1117/12.895520}
      }
      `,
      ``,
      `@INPROCEEDINGS{6181480,
        author={La, Hung Manh and Lim, Ronny Salim and Du, Jianhao and Sheng, Weihua and Li, Gang and Zhang, Sijian and Chen, Heping},
        booktitle={2011 IEEE International Conference on Robotics and Biomimetics}, 
        title={A small-scale research platform for intelligent transportation systems}, 
        year={2011},
        volume={},
        number={},
        pages={1373-1378},
        doi={10.1109/ROBIO.2011.6181480}}
      `,
      `@INPROCEEDINGS{6094995,
        author={Zhu, Chun and Sheng, Weihua},
        booktitle={2011 IEEE/RSJ International Conference on Intelligent Robots and Systems}, 
        title={Realtime recognition of complex daily activities using dynamic Bayesian network}, 
        year={2011},
        volume={},
        number={},
        pages={3395-3400},
        doi={10.1109/IROS.2011.6094995}}
      `,
      `@INPROCEEDINGS{6094904,
        author={Thobbi, Anand and Gu, Ye and Sheng, Weihua},
        booktitle={2011 IEEE/RSJ International Conference on Intelligent Robots and Systems}, 
        title={Using human motion estimation for human-robot cooperative manipulation}, 
        year={2011},
        volume={},
        number={},
        pages={2873-2878},
        doi={10.1109/IROS.2011.6094904}}
      `,
      `@INPROCEEDINGS{5978632,
        author={Tewolde, Girma S. and Sheng, Weihua},
        booktitle={2011 IEEE INTERNATIONAL CONFERENCE ON ELECTRO/INFORMATION TECHNOLOGY}, 
        title={Energy aware adaptive clustering in wireless sensor networks}, 
        year={2011},
        volume={},
        number={},
        pages={1-6},
        doi={10.1109/EIT.2011.5978632}}
      `,
      `@inproceedings{inproceedings,
        author = {Cheng, Hongtai and Liu, Yong},
        year = {2013},
        month = {05},
        pages = {36-41},
        title = {Object handling using Autonomous Industrial Mobile Manipulator},
        isbn = {978-1-4799-0610-9},
        journal = {2013 IEEE International Conference on Cyber Technology in Automation, Control and Intelligent Systems, IEEE-CYBER 2013},
        doi = {10.1109/CYBER.2013.6705416}
        }
      `,
      `@INPROCEEDINGS{6225305,
        author={Li, Gang and Zhu, Chun and Jianhao Du and Qi Cheng and Sheng, Weihua and Heping Chen},
        booktitle={2012 IEEE International Conference on Robotics and Automation}, 
        title={Robot semantic mapping through wearable sensor-based human activity recognition}, 
        year={2012},
        volume={},
        number={},
        pages={5228-5233},
        doi={10.1109/ICRA.2012.6225305}}
      `,
      `@INPROCEEDINGS{6357947,
        author={Srinivasan, Haritha and Gupta, Sauvik and Sheng, Weihua and Heping Chen},
        booktitle={Proceedings of the 10th World Congress on Intelligent Control and Automation}, 
        title={Estimation of hand force from surface Electromyography signals using Artificial Neural Network}, 
        year={2012},
        volume={},
        number={},
        pages={584-589},
        doi={10.1109/WCICA.2012.6357947}}
      `,
      `@INPROCEEDINGS{6491014,
        author={Du, Jianhao and Ou, Yongsheng and Sheng, Weihua},
        booktitle={2012 IEEE International Conference on Robotics and Biomimetics (ROBIO)}, 
        title={Improving 3D indoor mapping with motion data}, 
        year={2012},
        volume={},
        number={},
        pages={489-494},
        doi={10.1109/ROBIO.2012.6491014}}
      `,
      `@INPROCEEDINGS{6491161,
        author={Gu, Ye and Do, Ha and Ou, Yongsheng and Sheng, Weihua},
        booktitle={2012 IEEE International Conference on Robotics and Biomimetics (ROBIO)}, 
        title={Human gesture recognition through a Kinect sensor}, 
        year={2012},
        volume={},
        number={},
        pages={1379-1384},
        doi={10.1109/ROBIO.2012.6491161}}
      `,
      ``,
      `@INPROCEEDINGS{6521923,
        author={Du, Jianhao and Sheng, Weihua},
        booktitle={2013 IEEE Workshop on Robot Vision (WORV)}, 
        title={Active view planing for human observation through a RGB-D camera}, 
        year={2013},
        volume={},
        number={},
        pages={114-119},
        doi={10.1109/WORV.2013.6521923}}
      `,
      `@INPROCEEDINGS{6561237,
        author={Zheng, Shiyou and Tang, Xiaofang and Liu, Meiqin and Zhang, Senlin and Sheng, Weihua},
        booktitle={2013 25th Chinese Control and Decision Conference (CCDC)}, 
        title={State estimation with missing measurements using IMM}, 
        year={2013},
        volume={},
        number={},
        pages={1868-1873},
        doi={10.1109/CCDC.2013.6561237}}
      `,
      `@INPROCEEDINGS{6246801,
        author={Cheng, Hongtai and Chen, Heping and Liu, Yong and Sheng, Weihua},
        booktitle={2012 IEEE International Conference on Information and Automation}, 
        title={Human-like indoor navigation for Autonomous Industrial Mobile Manipulator}, 
        year={2012},
        volume={},
        number={},
        pages={162-167},
        doi={10.1109/ICInfA.2012.6246801}}
      `,
      `@INPROCEEDINGS{6640737,
        author={Zhang, Senlin and Zhang, Qiang and Liu, Meiqin and Fan, Zhen and Sheng, Weihua},
        booktitle={Proceedings of the 32nd Chinese Control Conference}, 
        title={A multi-hop reverse localization scheme for underwater wireless sensor networks}, 
        year={2013},
        volume={},
        number={},
        pages={7383-7388},
        doi={}}
      `,
      `@INPROCEEDINGS{6639552,
        author={Liu, Meiqin and Zhang, Senlin and Fan, Zhen and Sheng, Weihua},
        booktitle={Proceedings of the 32nd Chinese Control Conference}, 
        title={Optimal H∞ output feedback control for a class of nonlinear systems}, 
        year={2013},
        volume={},
        number={},
        pages={884-889},
        doi={}}
      `,
      `@INPROCEEDINGS{6705469,
        author={La, Hung Manh and Lim, Ronny Salim and Sheng, Weihua and Chen, Jiming},
        booktitle={2013 IEEE International Conference on Cyber Technology in Automation, Control and Intelligent Systems}, 
        title={Cooperative flocking and learning in multi-robot systems for predator avoidance}, 
        year={2013},
        volume={},
        number={},
        pages={337-342},
        doi={10.1109/CYBER.2013.6705469}}
      `,
      `@INPROCEEDINGS{6705424,
        author={Do, Ha M. and Mouser, Craig J. and Gu, Ye and Sheng, Weihua and Honarvar, Sam and Chen, Tingting},
        booktitle={2013 IEEE International Conference on Cyber Technology in Automation, Control and Intelligent Systems}, 
        title={An open platform telepresence robot with natural human interface}, 
        year={2013},
        volume={},
        number={},
        pages={81-86},
        doi={10.1109/CYBER.2013.6705424}
      `,
      `@ARTICLE{6815791,
        author={La, Hung M. and Sheng, Weihua and Chen, Jiming},
        journal={IEEE Transactions on Systems, Man, and Cybernetics: Systems}, 
        title={Cooperative and Active Sensing in Mobile Sensor Networks for Scalar Field Mapping}, 
        year={2015},
        volume={45},
        number={1},
        pages={1-12},
        doi={10.1109/TSMC.2014.2318282}}
      `,
      `@INPROCEEDINGS{6696984,
        author={Sheng, Weihua and Ou, Yongsheng and Tran, Duy and Tadesse, Eyosiyas and Liu, Meiqin and Yan, Gangfeng},
        booktitle={2013 IEEE/RSJ International Conference on Intelligent Robots and Systems}, 
        title={An integrated manual and autonomous driving framework based on driver drowsiness detection}, 
        year={2013},
        volume={},
        number={},
        pages={4376-4381},
        doi={10.1109/IROS.2013.6696984}}
      `,
      `@INPROCEEDINGS{6739538,
        author={Gu, Ye and Sheng, Weihua and Ou, Yongsheng and Liu, Meiqin and Zhang, Senlin},
        booktitle={2013 IEEE International Conference on Robotics and Biomimetics (ROBIO)}, 
        title={Human action recognition with contextual constraints using a RGB-D sensor}, 
        year={2013},
        volume={},
        number={},
        pages={674-679},
        doi={10.1109/ROBIO.2013.6739538}}
      `,
      `@INPROCEEDINGS{6907000,
        author={Allamaraju, Rakshit and Kingravi, Hassan and Axelrod, Allan and Chowdhary, Girish and Grande, Robert and How, Jonathan P. and Crick, Christopher and Sheng, Weihua},
        booktitle={2014 IEEE International Conference on Robotics and Automation (ICRA)}, 
        title={Human aware UAS path planning in urban environments using nonstationary MDPs}, 
        year={2014},
        volume={},
        number={},
        pages={1161-1167},
        doi={10.1109/ICRA.2014.6907000}}
      `,
      `@INPROCEEDINGS{6907163,
        author={Do, Ha Manh and Mouser, Craig and Liu, Meiqin and Sheng, Weihua},
        booktitle={2014 IEEE International Conference on Robotics and Automation (ICRA)}, 
        title={Human-robot collaboration in a Mobile Visual Sensor Network}, 
        year={2014},
        volume={},
        number={},
        pages={2203-2208},
        doi={10.1109/ICRA.2014.6907163}}
      `,
      `@INPROCEEDINGS{6907440,
        author={Tadesse, Eyosiyas and Sheng, Weihua and Liu, Meiqin},
        booktitle={2014 IEEE International Conference on Robotics and Automation (ICRA)}, 
        title={Driver drowsiness detection through HMM based dynamic modeling}, 
        year={2014},
        volume={},
        number={},
        pages={4003-4008},
        doi={10.1109/ICRA.2014.6907440}}
      `,
      `@INPROCEEDINGS{6907790,
        author={Gu, Ye and Sheng, Weihua and Ou, Yongsheng},
        booktitle={2014 IEEE International Conference on Robotics and Automation (ICRA)}, 
        title={Automated assembly skill acquisition through human demonstration}, 
        year={2014},
        volume={},
        number={},
        pages={6313-6318},
        doi={10.1109/ICRA.2014.6907790}}
      `,
      `@INPROCEEDINGS{6858601,
        author={Jiang, Tongyang and Liu, Meiqin and Zhang, Senlin and Sheng, Weihua},
        booktitle={2014 American Control Conference}, 
        title={Gating technique for the Gaussian mixture multi-Bernoulli filter}, 
        year={2014},
        volume={},
        number={},
        pages={1096-1101},
        doi={10.1109/ACC.2014.6858601}}
      `,
      `@INPROCEEDINGS{7053655,
        author={Batapati, Praveen and Tran, Duy and Sheng, Weihua and Meiqin Liu and Ruili Zeng},
        booktitle={Proceeding of the 11th World Congress on Intelligent Control and Automation}, 
        title={Video analysis for traffic anomaly detection using support vector machines}, 
        year={2014},
        volume={},
        number={},
        pages={5500-5505},
        doi={10.1109/WCICA.2014.7053655}}
      `,
      ``,
      `@INPROCEEDINGS{7288161,
        author={Li, Yanfei and Liu, Meiqin and Sheng, Weihua},
        booktitle={2015 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Indoor human tracking and state estimation by fusing environmental sensors and wearable sensors}, 
        year={2015},
        volume={},
        number={},
        pages={1468-1473},
        doi={10.1109/CYBER.2015.7288161}}
      `,
      `@INPROCEEDINGS{7288160,
        author={Zeng, Ruili and Sheng, Weihua and Yang, Dan},
        booktitle={2015 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Collision probability computation based on vehicle to vehicle communication}, 
        year={2015},
        volume={},
        number={},
        pages={1462-1467},
        doi={10.1109/CYBER.2015.7288160}}
      `,
      `@INPROCEEDINGS{7288078,
        author={Osipychev, Denis and Tran, Duy and Sheng, Weihua and Chowdhary, Girish and Zeng, Ruili},
        booktitle={2015 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Proactive MDP-based collision avoidance algorithm for autonomous cars}, 
        year={2015},
        volume={},
        number={},
        pages={983-988},
        doi={10.1109/CYBER.2015.7288078}}
      `,
      `@INPROCEEDINGS{7288004,
        author={Yang, Dan and Sheng, Weihua and Zeng, Ruili},
        booktitle={2015 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Indoor human localization using PIR sensors and accessibility map}, 
        year={2015},
        volume={},
        number={},
        pages={577-581},
        doi={10.1109/CYBER.2015.7288004}}
      `,
      `@INPROCEEDINGS{7287920,
        author={Tran, Duy and Sheng, Weihua and Liu, Li and Liu, Meiqin},
        booktitle={2015 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={A Hidden Markov Model based driver intention prediction system}, 
        year={2015},
        volume={},
        number={},
        pages={115-120},
        doi={10.1109/CYBER.2015.7287920}}
      `,
      `@INPROCEEDINGS{7354255,
        author={Ha Manh Do and Weihua Sheng and Meiqin Liu},
        booktitle={2015 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS)}, 
        title={An open platform of auditory perception for home service robots}, 
        year={2015},
        volume={},
        number={},
        pages={6161-6166},
        doi={10.1109/IROS.2015.7354255}}
      `,
      `@INPROCEEDINGS{7353476,
        author={Gu, Ye and Sheng, Weihua and Meiqin Liu and Yongsheng Ou},
        booktitle={2015 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS)}, 
        title={Fine manipulative action recognition through sensor fusion}, 
        year={2015},
        volume={},
        number={},
        pages={886-891},
        doi={10.1109/IROS.2015.7353476}}
      `,
      `@INPROCEEDINGS{7260590,
        author={Jianhao, Du and Meiqin, Liu and Weihua, Sheng},
        booktitle={2015 34th Chinese Control Conference (CCC)}, 
        title={Efficient exploration for real-time robot indoor 3D mapping}, 
        year={2015},
        volume={},
        number={},
        pages={6078-6083},
        doi={10.1109/ChiCC.2015.7260590}}
      `,
      `@INPROCEEDINGS{7419088,
        author={Pham, Minh and Yang, Dan and Sheng, Weihua and Liu, Meiqin},
        booktitle={2015 IEEE International Conference on Robotics and Biomimetics (ROBIO)}, 
        title={Human localization and tracking using distributed motion sensors and an inertial measurement unit}, 
        year={2015},
        volume={},
        number={},
        pages={2127-2132},
        doi={10.1109/ROBIO.2015.7419088}}
      `,
      `@INPROCEEDINGS{7418809,
        author={Tran, Duy and Tadesse, Eyosiyas and Batapati, Praveen and Sheng, Weihua and Liu, Li},
        booktitle={2015 IEEE International Conference on Robotics and Biomimetics (ROBIO)}, 
        title={A cloud based testbed for research and education in intelligent transportation system}, 
        year={2015},
        volume={},
        number={},
        pages={452-457},
        doi={10.1109/ROBIO.2015.7418809}}
      `,
      `@INPROCEEDINGS{7574817,
        author={Tran, Duy and Tadesse, Eyosiyas and Sheng, Weihua and Sun, Yuge and Liu, Meiqin and Zhang, Senlin},
        booktitle={2016 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={A driver assistance framework based on driver drowsiness detection}, 
        year={2016},
        volume={},
        number={},
        pages={173-178},
        doi={10.1109/CYBER.2016.7574817}}
      `,
      `@INPROCEEDINGS{7574820,
        author={Lakshmanan, Barath and Sheng, Weihua and Liu, Meiqin and Zhang, Senlin},
        booktitle={2016 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Locating license tags using edges}, 
        year={2016},
        volume={},
        number={},
        pages={188-193},
        doi={10.1109/CYBER.2016.7574820}}
      `,
      `@INPROCEEDINGS{7743444,
        author={Minh Pham and Mengistu, Yehenew and Ha Manh Do and Weihua Sheng},
        booktitle={2016 IEEE International Conference on Automation Science and Engineering (CASE)}, 
        title={Cloud-Based Smart Home Environment (CoSHE) for home healthcare}, 
        year={2016},
        volume={},
        number={},
        pages={483-488},
        doi={10.1109/COASE.2016.7743444}}
      `,
      `@INPROCEEDINGS{7743476,
        author={Do, Ha Manh and Sheng, Weihua and Liu, Meiqin and Senlin Zhang},
        booktitle={2016 IEEE International Conference on Automation Science and Engineering (CASE)}, 
        title={Context-aware sound event recognition for home service robots}, 
        year={2016},
        volume={},
        number={},
        pages={739-744},
        doi={10.1109/COASE.2016.7743476}}
      `,
      `@INPROCEEDINGS{7743474,
        author={Fernandes, Francisco Erivaldo and Guanci Yang and Do, Ha Manh and Sheng, Weihua},
        booktitle={2016 IEEE International Conference on Automation Science and Engineering (CASE)}, 
        title={Detection of privacy-sensitive situations for social robots in smart homes}, 
        year={2016},
        volume={},
        number={},
        pages={727-732},
        doi={10.1109/COASE.2016.7743474}}
      `,
      // `@INPROCEEDINGS{7759680,
      //   author={Du, Jianhao and Sheng, Weihua and Liu, Meiqin},
      //   booktitle={2016 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS)}, 
      //   title={Human-guided robot 3D mapping using virtual reality technology}, 
      //   year={2016},
      //   volume={},
      //   number={},
      //   pages={4624-4629},
      //   doi={10.1109/IROS.2016.7759680}}
      //   `,
      // `@INPROCEEDINGS{7759295,
      //   author={Mengistu, Yehenew and Pham, Minh and Manh Do, Ha and Sheng, Weihua},
      //   booktitle={2016 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS)}, 
      //   title={AutoHydrate: A wearable hydration monitoring system}, 
      //   year={2016},
      //   volume={},
      //   number={},
      //   pages={1857-1862},
      //   doi={10.1109/IROS.2016.7759295}}
      // `,
      `@INPROCEEDINGS{6640737,
        author={Zhang, Senlin and Zhang, Qiang and Liu, Meiqin and Fan, Zhen and Sheng, Weihua},
        booktitle={Proceedings of the 32nd Chinese Control Conference}, 
        title={A multi-hop reverse localization scheme for underwater wireless sensor networks}, 
        year={2013},
        volume={},
        number={},
        pages={7383-7388},
        doi={}}
      `,
      `@INPROCEEDINGS{6639552,
        author={Liu, Meiqin and Zhang, Senlin and Fan, Zhen and Sheng, Weihua},
        booktitle={Proceedings of the 32nd Chinese Control Conference}, 
        title={Optimal H∞ output feedback control for a class of nonlinear systems}, 
        year={2013},
        volume={},
        number={},
        pages={884-889},
        doi={}}
      `,
      `@INPROCEEDINGS{6705469,
        author={La, Hung Manh and Lim, Ronny Salim and Sheng, Weihua and Chen, Jiming},
        booktitle={2013 IEEE International Conference on Cyber Technology in Automation, Control and Intelligent Systems}, 
        title={Cooperative flocking and learning in multi-robot systems for predator avoidance}, 
        year={2013},
        volume={},
        number={},
        pages={337-342},
        doi={10.1109/CYBER.2013.6705469}}
      `,
      `@INPROCEEDINGS{6705424,
        author={Do, Ha M. and Mouser, Craig J. and Gu, Ye and Sheng, Weihua and Honarvar, Sam and Chen, Tingting},
        booktitle={2013 IEEE International Conference on Cyber Technology in Automation, Control and Intelligent Systems}, 
        title={An open platform telepresence robot with natural human interface}, 
        year={2013},
        volume={},
        number={},
        pages={81-86},
        doi={10.1109/CYBER.2013.6705424}}
      `,
      `@ARTICLE{6815791,
        author={La, Hung M. and Sheng, Weihua and Chen, Jiming},
        journal={IEEE Transactions on Systems, Man, and Cybernetics: Systems}, 
        title={Cooperative and Active Sensing in Mobile Sensor Networks for Scalar Field Mapping}, 
        year={2015},
        volume={45},
        number={1},
        pages={1-12},
        doi={10.1109/TSMC.2014.2318282}}
      `,
      `@INPROCEEDINGS{6696984,
        author={Sheng, Weihua and Ou, Yongsheng and Tran, Duy and Tadesse, Eyosiyas and Liu, Meiqin and Yan, Gangfeng},
        booktitle={2013 IEEE/RSJ International Conference on Intelligent Robots and Systems}, 
        title={An integrated manual and autonomous driving framework based on driver drowsiness detection}, 
        year={2013},
        volume={},
        number={},
        pages={4376-4381},
        doi={10.1109/IROS.2013.6696984}}
      `,
      `@INPROCEEDINGS{6739538,
        author={Gu, Ye and Sheng, Weihua and Ou, Yongsheng and Liu, Meiqin and Zhang, Senlin},
        booktitle={2013 IEEE International Conference on Robotics and Biomimetics (ROBIO)}, 
        title={Human action recognition with contextual constraints using a RGB-D sensor}, 
        year={2013},
        volume={},
        number={},
        pages={674-679},
        doi={10.1109/ROBIO.2013.6739538}}
      `,
      `@INPROCEEDINGS{6907000,
        author={Allamaraju, Rakshit and Kingravi, Hassan and Axelrod, Allan and Chowdhary, Girish and Grande, Robert and How, Jonathan P. and Crick, Christopher and Sheng, Weihua},
        booktitle={2014 IEEE International Conference on Robotics and Automation (ICRA)}, 
        title={Human aware UAS path planning in urban environments using nonstationary MDPs}, 
        year={2014},
        volume={},
        number={},
        pages={1161-1167},
        doi={10.1109/ICRA.2014.6907000}}
      `,
      `@INPROCEEDINGS{6907163,
        author={Do, Ha Manh and Mouser, Craig and Liu, Meiqin and Sheng, Weihua},
        booktitle={2014 IEEE International Conference on Robotics and Automation (ICRA)}, 
        title={Human-robot collaboration in a Mobile Visual Sensor Network}, 
        year={2014},
        volume={},
        number={},
        pages={2203-2208},
        doi={10.1109/ICRA.2014.6907163}}
      `,









      `@INPROCEEDINGS{6907440,
        author={Tadesse, Eyosiyas and Sheng, Weihua and Liu, Meiqin},
        booktitle={2014 IEEE International Conference on Robotics and Automation (ICRA)}, 
        title={Driver drowsiness detection through HMM based dynamic modeling}, 
        year={2014},
        volume={},
        number={},
        pages={4003-4008},
        doi={10.1109/ICRA.2014.6907440}}
      `,

      `@INPROCEEDINGS{6907790,
        author={Gu, Ye and Sheng, Weihua and Ou, Yongsheng},
        booktitle={2014 IEEE International Conference on Robotics and Automation (ICRA)}, 
        title={Automated assembly skill acquisition through human demonstration}, 
        year={2014},
        volume={},
        number={},
        pages={6313-6318},
        doi={10.1109/ICRA.2014.6907790}}
      `,
      `@INPROCEEDINGS{6858601,
        author={Jiang, Tongyang and Liu, Meiqin and Zhang, Senlin and Sheng, Weihua},
        booktitle={2014 American Control Conference}, 
        title={Gating technique for the Gaussian mixture multi-Bernoulli filter}, 
        year={2014},
        volume={},
        number={},
        pages={1096-1101},
        doi={10.1109/ACC.2014.6858601}}
      `,
      `@INPROCEEDINGS{7053655,
        author={Batapati, Praveen and Tran, Duy and Sheng, Weihua and Meiqin Liu and Ruili Zeng},
        booktitle={Proceeding of the 11th World Congress on Intelligent Control and Automation}, 
        title={Video analysis for traffic anomaly detection using support vector machines}, 
        year={2014},
        volume={},
        number={},
        pages={5500-5505},
        doi={10.1109/WCICA.2014.7053655}}
      `,
      `@InProceedings{10.1007/978-3-319-14249-4_74,
      author="Du, Jianhao
      and Sheng, Weihua
      and Cheng, Qi
      and Liu, Meiqin",
      editor="Bebis, George
      and Boyle, Richard
      and Parvin, Bahram
      and Koracin, Darko
      and McMahan, Ryan
      and Jerald, Jason
      and Zhang, Hui
      and Drucker, Steven M.
      and Kambhamettu, Chandra
      and El Choubassi, Maha
      and Deng, Zhigang
      and Carlson, Mark",
      title="Proactive 3D Robot Mapping in Environments with Sparse Features",
      booktitle="Advances in Visual Computing",
      year="2014",
      publisher="Springer International Publishing",
      address="Cham",
      pages="773--782",
      abstract="3D map building can aid robots to accomplish high-level tasks. Using an inexpensive RGB-D camera, a 3D map can be built by estimating the camera pose using visual features. However, the mapping will easily fail if there lack a sufficient number of features. In this paper, a proactive 3D mapping framework is proposed using a mobile robot platform equipped with an RGB-D camera and a projector. Both the camera and the projector are mounted on pan-tilt units controlled by servo motors. With the motion of the camera pan-tilt unit and the movement of the robot, a binary hypothesis testing problem is modeled to evaluate the estimation accuracy of the camera pose. A pattern is generated by the projector to increase the number of features when the pose estimation has large errors based on the real-time evaluation. The experimental results show that the proposed approach improves the mapping performance in an indoor environment with sparse features.",
      isbn="978-3-319-14249-4"
      }
      `,
      `@INPROCEEDINGS{7288161,
        author={Li, Yanfei and Liu, Meiqin and Sheng, Weihua},
        booktitle={2015 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Indoor human tracking and state estimation by fusing environmental sensors and wearable sensors}, 
        year={2015},
        volume={},
        number={},
        pages={1468-1473},
        doi={10.1109/CYBER.2015.7288161}}
      `,
      `@INPROCEEDINGS{7288160,
        author={Zeng, Ruili and Sheng, Weihua and Yang, Dan},
        booktitle={2015 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Collision probability computation based on vehicle to vehicle communication}, 
        year={2015},
        volume={},
        number={},
        pages={1462-1467},
        doi={10.1109/CYBER.2015.7288160}}
      `,
      `@INPROCEEDINGS{7288078,
        author={Osipychev, Denis and Tran, Duy and Sheng, Weihua and Chowdhary, Girish and Zeng, Ruili},
        booktitle={2015 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Proactive MDP-based collision avoidance algorithm for autonomous cars}, 
        year={2015},
        volume={},
        number={},
        pages={983-988},
        doi={10.1109/CYBER.2015.7288078}}
      `,
      `@INPROCEEDINGS{7288004,
        author={Yang, Dan and Sheng, Weihua and Zeng, Ruili},
        booktitle={2015 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Indoor human localization using PIR sensors and accessibility map}, 
        year={2015},
        volume={},
        number={},
        pages={577-581},
        doi={10.1109/CYBER.2015.7288004}}
      `,
      `@INPROCEEDINGS{7287920,
        author={Tran, Duy and Sheng, Weihua and Liu, Li and Liu, Meiqin},
        booktitle={2015 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={A Hidden Markov Model based driver intention prediction system}, 
        year={2015},
        volume={},
        number={},
        pages={115-120},
        doi={10.1109/CYBER.2015.7287920}}
      `,
      `@INPROCEEDINGS{7354255,
        author={Ha Manh Do and Weihua Sheng and Meiqin Liu},
        booktitle={2015 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS)}, 
        title={An open platform of auditory perception for home service robots}, 
        year={2015},
        volume={},
        number={},
        pages={6161-6166},
        doi={10.1109/IROS.2015.7354255}}
      `,
      `@INPROCEEDINGS{7353476,
        author={Gu, Ye and Sheng, Weihua and Meiqin Liu and Yongsheng Ou},
        booktitle={2015 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS)}, 
        title={Fine manipulative action recognition through sensor fusion}, 
        year={2015},
        volume={},
        number={},
        pages={886-891},
        doi={10.1109/IROS.2015.7353476}}
      `,
      `@INPROCEEDINGS{7260590,
        author={Jianhao, Du and Meiqin, Liu and Weihua, Sheng},
        booktitle={2015 34th Chinese Control Conference (CCC)}, 
        title={Efficient exploration for real-time robot indoor 3D mapping}, 
        year={2015},
        volume={},
        number={},
        pages={6078-6083},
        doi={10.1109/ChiCC.2015.7260590}}
      `,
      `@INPROCEEDINGS{7419088,
        author={Pham, Minh and Yang, Dan and Sheng, Weihua and Liu, Meiqin},
        booktitle={2015 IEEE International Conference on Robotics and Biomimetics (ROBIO)}, 
        title={Human localization and tracking using distributed motion sensors and an inertial measurement unit}, 
        year={2015},
        volume={},
        number={},
        pages={2127-2132},
        doi={10.1109/ROBIO.2015.7419088}}
      `,
      `@INPROCEEDINGS{7418809,
        author={Tran, Duy and Tadesse, Eyosiyas and Batapati, Praveen and Sheng, Weihua and Liu, Li},
        booktitle={2015 IEEE International Conference on Robotics and Biomimetics (ROBIO)}, 
        title={A cloud based testbed for research and education in intelligent transportation system}, 
        year={2015},
        volume={},
        number={},
        pages={452-457},
        doi={10.1109/ROBIO.2015.7418809}}
      `,
      `@INPROCEEDINGS{7574817,
        author={Tran, Duy and Tadesse, Eyosiyas and Sheng, Weihua and Sun, Yuge and Liu, Meiqin and Zhang, Senlin},
        booktitle={2016 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={A driver assistance framework based on driver drowsiness detection}, 
        year={2016},
        volume={},
        number={},
        pages={173-178},
        doi={10.1109/CYBER.2016.7574817}}
      `,
      `@INPROCEEDINGS{7574820,
        author={Lakshmanan, Barath and Sheng, Weihua and Liu, Meiqin and Zhang, Senlin},
        booktitle={2016 IEEE International Conference on Cyber Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Locating license tags using edges}, 
        year={2016},
        volume={},
        number={},
        pages={188-193},
        doi={10.1109/CYBER.2016.7574820}}
      `,
      `@INPROCEEDINGS{7743444,
        author={Minh Pham and Mengistu, Yehenew and Ha Manh Do and Weihua Sheng},
        booktitle={2016 IEEE International Conference on Automation Science and Engineering (CASE)}, 
        title={Cloud-Based Smart Home Environment (CoSHE) for home healthcare}, 
        year={2016},
        volume={},
        number={},
        pages={483-488},
        doi={10.1109/COASE.2016.7743444}}
      `,
      `@INPROCEEDINGS{7759680,
        author={Du, Jianhao and Sheng, Weihua and Liu, Meiqin},
        booktitle={2016 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS)}, 
        title={Human-guided robot 3D mapping using virtual reality technology}, 
        year={2016},
        volume={},
        number={},
        pages={4624-4629},
        doi={10.1109/IROS.2016.7759680}}
      `,
      `@INPROCEEDINGS{7759295,
        author={Mengistu, Yehenew and Pham, Minh and Manh Do, Ha and Sheng, Weihua},
        booktitle={2016 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS)}, 
        title={AutoHydrate: A wearable hydration monitoring system}, 
        year={2016},
        volume={},
        number={},
        pages={1857-1862},
        doi={10.1109/IROS.2016.7759295}}
      `,






      `@INPROCEEDINGS{7743476,
        author={Do, Ha Manh and Sheng, Weihua and Liu, Meiqin and Senlin Zhang},
        booktitle={2016 IEEE International Conference on Automation Science and Engineering (CASE)}, 
        title={Context-aware sound event recognition for home service robots}, 
        year={2016},
        volume={},
        number={},
        pages={739-744},
        doi={10.1109/COASE.2016.7743476}}
      `,
      `@INPROCEEDINGS{7743474,
        author={Fernandes, Francisco Erivaldo and Guanci Yang and Do, Ha Manh and Sheng, Weihua},
        booktitle={2016 IEEE International Conference on Automation Science and Engineering (CASE)}, 
        title={Detection of privacy-sensitive situations for social robots in smart homes}, 
        year={2016},
        volume={},
        number={},
        pages={727-732},
        doi={10.1109/COASE.2016.7743474}}
      `,
      `@INPROCEEDINGS{7759680,
        author={Du, Jianhao and Sheng, Weihua and Liu, Meiqin},
        booktitle={2016 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS)}, 
        title={Human-guided robot 3D mapping using virtual reality technology}, 
        year={2016},
        volume={},
        number={},
        pages={4624-4629},
        doi={10.1109/IROS.2016.7759680}}
      `,
      `@INPROCEEDINGS{7759295,
        author={Mengistu, Yehenew and Pham, Minh and Manh Do, Ha and Sheng, Weihua},
        booktitle={2016 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS)}, 
        title={AutoHydrate: A wearable hydration monitoring system}, 
        year={2016},
        volume={},
        number={},
        pages={1857-1862},
        doi={10.1109/IROS.2016.7759295}}
      `,
      `@InProceedings{10.1007/978-981-10-5230-9_32,
      author="Tang, Ye
      and Liu, Meiqin
      and Sheng, Weihua
      and Zhang, Senlin",
      editor="Sun, Fuchun
      and Liu, Huaping
      and Hu, Dewen",
      title="Robot Path Planning for Human Search in Indoor Environments",
      booktitle="Cognitive Systems and Signal Processing",
      year="2017",
      publisher="Springer Singapore",
      address="Singapore",
      pages="310--323",
      abstract="Aiming at the problem of a mobile robot searching human in home environments, a gird model is built and a path planning method based on a modified genetic algorithm and an improved A* algorithm is proposed. First, the grid map is divided into several unit regions using Boustrophedon cellular decomposition. Then, a unit region planning method based on a genetic algorithm is applied to generate a region transition sequence, and an effective strategy to search every region is adjusted according to the robot's sensors. Meanwhile, the optimal path between two points is generated by an improved A* algorithm, so that the path is much shorter and the number of turns is greatly reduced. Finally, the simulation results verify that this method can provide an optimized path in known home environments effectively, based on that the robot can find human in the shortest possible time.",
      isbn="978-981-10-5230-9"
      }
      `,
      `@InProceedings{10.1007/978-981-10-5230-9_46,
      author="Sun, Xinyue
      and Liu, Meiqin
      and Sheng, Weihua
      and Zhang, Senlin
      and Fan, Zhen",
      editor="Sun, Fuchun
      and Liu, Huaping
      and Hu, Dewen",
      title="Indoor Multi Human Target Tracking Based on PIR Sensor Network",
      booktitle="Cognitive Systems and Signal Processing",
      year="2017",
      publisher="Springer Singapore",
      address="Singapore",
      pages="479--492",
      abstract="In order to solve the problem of human target tracking in smart-home Wireless Sensor Network (WSN) environment, and only based on limited measurement data of Binary PIR sensors, the sensor networks joint likelihood is derived, which proposes the indoor PIR sensor network Binary Auxiliary Particle Filter (Bin-APF) fusion estimate algorithm further more. Meanwhile, as for the problem of multiple human targets measurement classification and trajectory association, combined with PIR's binary measurement, an improved K-Nearest Neighbor algorithm is adopted. And according to parameters of current experimental environment, a simulation is carried out, which contributes to the algorithm proposed. Experiment and Simulation results indicate that the MTT-KNN-Bin-APF algorithm accord well with the expectation of in-home multiple human target localization and tracking in consideration of actual result and error precision. Moreover, the algorithm is in low dependency of sensor network's layout, which is suitable for various type of household arrangement. The method provides a solution to indoor human target tracking and is promising in the field of smart home.",
      isbn="978-981-10-5230-9"
      }
      `,
      `@INPROCEEDINGS{7989715,
        author={Tran, Duy and Tadesse, Eyosiyas and Osipychev, Denis and Du, Jianhao and Sheng, Weihua and Sun, Yuge and Chen, Heping},
        booktitle={2017 IEEE International Conference on Robotics and Automation (ICRA)}, 
        title={A collaborative control framework for driver assistance systems}, 
        year={2017},
        volume={},
        number={},
        pages={6038-6043},
        doi={10.1109/ICRA.2017.7989715}}
      `,
      `@INPROCEEDINGS{7963403,
        author={Osipychev, Denis and Duy Tran and Weihua Sheng and Chowdhary, Girish},
        booktitle={2017 American Control Conference (ACC)}, 
        title={Human intention-based collision avoidance for autonomous cars}, 
        year={2017},
        volume={},
        number={},
        pages={2974-2979},
        doi={10.23919/ACC.2017.7963403}}
      `,
      `@INPROCEEDINGS{8446419,
        author={Sun, Yuge and Sheng, Weihua and Khemri, Nizar Ali and Gu, Ye},
        booktitle={2017 IEEE 7th Annual International Conference on CYBER Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Evaluation of P300-based BCI Using a Non-invasive Commercial EEG Sensor}, 
        year={2017},
        volume={},
        number={},
        pages={115-120},
        doi={10.1109/CYBER.2017.8446419}}
      `,
      `@INPROCEEDINGS{8446254,
        author={Yuan, Xianfeng and Song, Mumin and Zhou, Fengyu and Wang, Mingchang and Yin, Lei and Sheng, Weihua},
        booktitle={2017 IEEE 7th Annual International Conference on CYBER Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Design of A Software Simulation Platform for Fault Diagnosis of Service Robot}, 
        year={2017},
        volume={},
        number={},
        pages={376-381},
        doi={10.1109/CYBER.2017.8446254}}
      `,
      `@INPROCEEDINGS{8446158,
        author={Wang, Qi and Zhang, Senlin and Liu, Meiqin and Sheng, Weihua},
        booktitle={2017 IEEE 7th Annual International Conference on CYBER Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Retrieval of Misplaced Items Using a Mobile Robot via Visual Object Recognition}, 
        year={2017},
        volume={},
        number={},
        pages={1188-1193},
        doi={10.1109/CYBER.2017.8446158}}
      `,
      `@INPROCEEDINGS{8446621,
        author={Nguyen, Trung and Lakshmanan, Barth and Lin, Chengjie and Sheng, Weihua and Gu, Ye and Liu, Meiqin and Zhang, Senlin},
        booktitle={2017 IEEE 7th Annual International Conference on CYBER Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={A Miniature Smart Home Testbed for Research and Education}, 
        year={2017},
        volume={},
        number={},
        pages={1637-1642},
        doi={10.1109/CYBER.2017.8446621}}
      `,
      `@INPROCEEDINGS{8446103,
        author={You, Baoshan and Sheng, Weihua and Ma, Hongwei and Gu, Ye and Qin, Yinglin},
        booktitle={2017 IEEE 7th Annual International Conference on CYBER Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Implementation of Sobel Edge Detection on FPGA based on OpenCL}, 
        year={2017},
        volume={},
        number={},
        pages={753-758},
        doi={10.1109/CYBER.2017.8446103}}
      `,
      `@INPROCEEDINGS{8446336,
        author={Sun, Yuge and Li, Lei and Ye, Ning and Zhao, Lihong and Lei, Hongwei and Yang, Jie and Sheng, Weihua},
        booktitle={2017 IEEE 7th Annual International Conference on CYBER Technology in Automation, Control, and Intelligent Systems (CYBER)}, 
        title={Research on Video-based Traffic Sign Recognition}, 
        year={2017},
        volume={},
        number={},
        pages={1500-1505},
        doi={10.1109/CYBER.2017.8446336}}
      `,
      `@INPROCEEDINGS{8305073,
        author={Yazdanpour, Mahdi and Fan, Guoliang and Sheng, Weihua},
        booktitle={2017 IEEE Visual Communications and Image Processing (VCIP)}, 
        title={Real-time volumetric reconstruction of Manhattan indoor scenes from depth sequences}, 
        year={2017},
        volume={},
        number={},
        pages={1-4},
        doi={10.1109/VCIP.2017.8305073}}
      `,
      `@INPROCEEDINGS{8305159,
        author={Guo, Lin and Fan, Guoliang and Sheng, Weihua},
        booktitle={2017 IEEE Visual Communications and Image Processing (VCIP)}, 
        title={Robust object detection by cuboid matching with local plane optimization in indoor RGB-D images}, 
        year={2017},
        volume={},
        number={},
        pages={1-4},
        doi={10.1109/VCIP.2017.8305159}}
      `,
      `@INPROCEEDINGS{8324417,
        author={Erivaldo Fernandes, Francisco and Do, Ha Manh and Muniraju, Kiran and Sheng, Weihua and Bishop, Alex J.},
        booktitle={2017 IEEE International Conference on Robotics and Biomimetics (ROBIO)}, 
        title={Cognitive orientation assessment for older adults using social robots}, 
        year={2017},
        volume={},
        number={},
        pages={196-201},
        doi={10.1109/ROBIO.2017.8324417}}
      `,
      `@INPROCEEDINGS{8630495,
        author={Li, Ming and Junior, Francisco Erivaldo Fernandes and Sheng, Weihua and Bai, He and Fan, Zhen and Liu, Meiqin},
        booktitle={2018 13th World Congress on Intelligent Control and Automation (WCICA)}, 
        title={Measurement Of Latency On Visual Feedback In an Immersive Telepresence Robotic System}, 
        year={2018},
        volume={},
        number={},
        pages={1757-1762},
        doi={10.1109/WCICA.2018.8630495}}
      `,
      `@inproceedings{inproceedings,
        author = {McCall, G. and Boevers, E. and Harrington, Erin and Bishop, Alex and Do, Ha and Sheng, Weihua},
        year = {2018},
        month = {04},
        pages = {123-123},
        title = {Emergent themes in the likes and dislikes of social robots expressed by older adults},
        volume = {17},
        journal = {Gerontechnology},
        doi = {10.4017/gt.2018.17.s.119.00}
        }
      `,
      `@inproceedings{inproceedings,
        author = {Boevers, E. and McCall, G. and Harrington, Erin and Do, Ha and Bishop, Alex and Sheng, Weihua},
        year = {2018},
        month = {04},
        pages = {120-120},
        title = {Exploring older adult concerns regarding acceptance and use of social companion robots},
        volume = {17},
        journal = {Gerontechnology},
        doi = {10.4017/gt.2018.17.s.116.00}
        }
      `,
      `@inproceedings{inproceedings,
        author = {Harrington, Erin and Do, Ha and McCall, G. and Boevers, H. and Bishop, Alex and Shen, W.},
        year = {2018},
        month = {04},
        pages = {121-121},
        title = {Older adult interaction with social robots: implications for socio-emotional well-being},
        volume = {17},
        journal = {Gerontechnology},
        doi = {10.4017/gt.2018.17.s.117.00}
        }
      `,
      `@INPROCEEDINGS{8630497,
        author={Yang, Jing and Sheng, Weihua and Yang, Guanci},
        booktitle={2018 13th World Congress on Intelligent Control and Automation (WCICA)}, 
        title={Dynamic Gesture Recognition Algorithm based on ROI and CNN for Social Robots}, 
        year={2018},
        volume={},
        number={},
        pages={389-394},
        doi={10.1109/WCICA.2018.8630497}}
      `,
      `@INPROCEEDINGS{8630370,
       author={Gu, Ye and Ye, Xiaofeng and Sheng, Weihua},
       booktitle={2018 13th World Congress on Intelligent Control and Automation (WCICA)}, 
       title={Depth MHI Based Deep Learning Model for Human Action Recognition}, 
       year={2018},
       volume={},
       number={},
       pages={395-400},
       doi={10.1109/WCICA.2018.8630370}}
      `,
      `@INPROCEEDINGS{8630380,
        author={Li, Yang and Sheng, Weihua and Yang, Guanci and Liang, Baojuan and Su, Zhidong and Chen, Zhanjie},
        booktitle={2018 13th World Congress on Intelligent Control and Automation (WCICA)}, 
        title={Home Assistant-Based Collaborative Framework of Multi-Sensor Fusion for Social Robot}, 
        year={2018},
        volume={},
        number={},
        pages={401-406},
        doi={10.1109/WCICA.2018.8630380}}
      `
]


const conference = [
    ['[C174] Yang Li, Guanci Yang, Zhidong Su, Zhanjie Chen, Weihua Sheng and Baojuan Liang, Home Assistant-based Collaborative Framework of Multi Sensor Fusion for Social Robot, The 13th World Congress on Intelligent Control and Automation (WCICA2018), Changsha, China, July, 2018.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8630380'],
    ['[C173] Ye Gu, Xiaofeng Ye and Weihua Sheng, Depth MHI Based Deep Learning Model for Human Action Recognition, The 13th World Congress on Intelligent Control and Automation (WCICA2018), Changsha, China, July, 2018.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8630370'],
    ['[C172] Jing Yang, Guanci Yang and Weihua Sheng, Dynamic Gesture Recognition Algorithm based on ROI and CNN for Social Robots, The 13th World Congress on Intelligent Control and Automation (WCICA2018), Changsha, China, July, 2018.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8630497'],
    ['[C171] Erin Harrington, Ha M. Do, G. McCall, E. Boevers, Alex J. Bishop, Weihua Sheng, “Older adult interaction with social robots: Implications for socio-emotional well-being,” 11th World Conference of Gerontechnology, May, 2018.', '[bib]', '[link]', 'https://www.researchgate.net/publication/324786358_Older_adult_interaction_with_social_robots_implications_for_socio-emotional_well-being'],
    ['[C170] E. Boevers, G. McCall, Erin Harrington, Ha M. Do, Alex J. Bishop, Weihua Sheng, “Exploring older adult concerns regarding acceptance and use of social companion robots,” 11th World Conference of Gerontechnology, May, 2018.', '[bib]', '[link]', 'https://www.researchgate.net/publication/324786264_Exploring_older_adult_concerns_regarding_acceptance_and_use_of_social_companion_robots'],
    ['[C169] G. McCall, E. Boevers, Erin Harrington, Alex J. Bishop, Ha M. Do, Weihua Sheng, “Emergent themes in the likes and dislikes of social robots expressed by older adults,” 11th World Conference of Gerontechnology, May, 2018.', '[bib]', '[link]', 'https://www.researchgate.net/publication/324785471_Emergent_themes_in_the_likes_and_dislikes_of_social_robots_expressed_by_older_adults'],
    ['[C168] Ming Li, Francisco E. Fernandes Jr., Weihua Sheng, He Bai, Zhen Fan, Meiqin Liu, Measurement of Latency on Visual Feedback in an Immersive Telepresence Robotic System. The 2018 World Congress on Intelligent Control and Automation, Changsha, China, July 2018.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8630495'],
    ['[C167] Francisco Erivaldo Fernandes Junior, Ha Manh Do, Kiran Muniraju, Weihua Sheng and Alex Bishop, Cognitive Assessment for Older Adults using Social Robots, The 2017 IEEE International Conference on Robotics and Biomimetics (ROBIO 2017) Macau SAR, China December 5-8, 2017', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8324417'],
    ['[C166] L. Guo, G. Fan, W. Sheng, “Robust Object Detection by Cuboid Matching with Local Plane Optimization in Indoor RGB-D Images”, 2017 IEEE International Conference on Visual Communication and Image Processing (VCIPL). Best Paper Candidate for VCIP2017.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8305159'],
    ['[C165] M. Yazdanpour, G. Fan, W. Sheng, “Real-Time Volumetric Reconstruction of Manhattan Indoor Scenes from Depth Sequences”, 2017 IEEE International Conference on Visual Communication and Image Processing (VCIPL).', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8305073'],
    ['[C164] Yuge Sun, Lei Li, Ning Ye, Lihong Zhao, Hongwei Lei, Jie Yang, Weihua Sheng, Research on Video-based Traffic Sign Recognition, IEEE-CYBER 2017, Honolulu, Hawaii, 2017', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8446336'],
    ['[C163] Baoshan You, Weihua Sheng, Hongwei Ma and Ye Gu, Implementation of Sobel Edge Detection on FPGA based on OpenCL. IEEE-CYBER 2017, Honolulu, Hawaii, 2017', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8446103'],
    ['[C162] Trung Nguyen, Barath Lakshmanan, Chengjie Lin, Weihua Sheng, Ye Gu, Meiqin Liu, Senlin Zhang, A Miniature Smart-Home Testbed for Research and Education. IEEE-CYBER 2017, Honolulu, Hawaii, 2017', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8446621'],
    ['[C161] Qi Wang, Meiqin Liu, Weihua Sheng and Senlin Zhang, Retrieval of Misplaced Items using a Mobile Robot via Visual Object Recognition. IEEE-CYBER 2017, Honolulu, Hawaii, 2017.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8446158'],
    ['[C160] Xiangfeng Yuan, Mumin Song, Fengyu Zhou, Mingchang Wang, Lei Yin and Weihua Sheng, Design of a Software Simulation Platform for Fault Diagnosis of Service Robot. IEEE-CYBER 2017, Honolulu, Hawaii, 2017.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8446254'], 
    ['[C159] Yuge Sun, Weihua Sheng, Nizar Ali Khemri, Ye Gu, Evaluation of P300-based BCI Using a Non-invasive Commercial EEG Sensor. IEEE-CYBER 2017, Honolulu, Hawaii, 2017', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/8446419'],
    ['[C158] Denis Osipychev, Duy Tran, Weihua Sheng, Girish Chowdhary, Human Intention-Based Collision Avoidance for Autonomous Cars, 2017 American Control Conference, Seattle, USA, 2017', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7963403'],
    ['[C157] Duy Tran, Eyosiyas Tadesse, Denis Osipychev, Jianhao Du, Weihua Sheng, Yuge Sun, A Collaborative Control Framework for Driver Assistance Systems, IEEE International Conference on Robotics and Automation, Singapore, May 2017.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7989715'],
    ['[C156] X. Sun, M. Liu, W. Sheng, S. Zhang, Z. Fan, Indoor multi human target tracking based on pir sensor network, 3rd International Conference on Cognitive Systems and Information Processing, ICCSIP 2016.', '[bib]', '[link]', 'https://link.springer.com/chapter/10.1007/978-981-10-5230-9_46'],
    ['[C155] Ye Tang, Meiqin Liu, Weihua Sheng, Senlin Zhang, Robot Path Planning for Human Search in Indoor Environments, the 2nd International Conference on Communication Software and Information Processing (ICCSIP) 2016, China.', '[bib]', '[link]', 'https://link.springer.com/chapter/10.1007%2F978-981-10-5230-9_32'],
    ['[C154] Yehenew Mengistu, Minh Pham, Ha Manh Do and Weihua Sheng, AutoHydrate: A Wearable Hydration Monitoring System, The IEEE/RSJ International Conference on Intelligent Robots and System (IROS2016) Daejeon Korea.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/abstract/document/7759295'],
    ['[C153] Jianhao Du, Weihua Sheng and Meiqin Liu, Human-guided Robot 3D Mapping using Virtual Reality Technology, The IEEE/RSJ International Conference on Intelligent Robots and System (IROS2016) Daejeon Korea.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7759680'],
    ['[C152] Francisco Erivaldo Fernandes Junior, Guanci Yang, Ha Manh Do, Weihua Sheng, Detection of Privacy-Sensitive Situations for Social Robots in Smart Homes, IEEE International Conference on Automation Science and Engineering, Fort Worth TX, 2016.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7743474'],
    ['[C151] Ha Manh Do, Weihua Sheng, Meiqin Liu, Senlin Zhang, Context-aware Sound Event Recognition for Home Service Robots, IEEE International Conference on Automation Science and Engineering, Fort Worth TX, 2016.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7743476'],
    ['[C150] Minh Pham, Yehenew Mengistu, Ha Manh Do, Weihua Sheng, Cloud-Based Smart Home Environment (CoSHE) for Home Healthcare, IEEE International Conference on Automation Science and Engineering, Fort Worth TX, 2016.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7743444'],
    ['[C149] Barath Lakshmanan, Weihua Sheng, Meiqin Liu and Senlin Zhang, Locating License Tags Using Edges, The 6th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems (IEEE-CYBER 2016). June 2016.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7574820'],
    ['[C148] Duy Tran, Eyosiyas Tadesse, Weihua Sheng, Yuge Sun, Meiqin Liu and Senlin Zhang, A Driver Assistance Framework based on Driver Drowsiness Detection. The 6th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems (IEEE-CYBER 2016). June, 2016.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7574817'],
    ['[C147] Duy Tran, Eyosiyas Tadesse, Praveen Batapati, Weihua Sheng and Li Liu, A Cloud based Testbed for Research and Education in Intelligent Transportation System (ITS), IEEE International Conference on Robotics and Biomimetics, Zhuhai China, Dec. 6-9, 2015', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7418809'],
    ['[C146] Minh Pham, Dan Yang, Weihua Sheng, Meiqin Liu, Human Localization and Tracking Using Distributed Motion Sensors and an Inertial Measurement Unit. IEEE International Conference on Robotics and Biomimetics, Zhuhai China, Dec. 6-9, 2015', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7419088',],
    ['[C145] Jianhao Du, Meiqin Liu and Weihua Sheng, Efficient Exploration for Real-time Robot Indoor 3D Mapping, 34th Chinese Control Conference, July 2015, Hangzhou China.',  '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7260590'],
    ['[C144] Ye Gu, Weihua Sheng, Yongsheng Ou, Meiqin Liu, Fine Manipulative Action Recognition through Sensor Fusion, 2015 IEEE/RSJ International Conference on Intelligent Robots and Systems, Hamburg, Germany, Sept, 2015.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7353476'],
    ['[C143] Ha Manh Do, Weihua Sheng, Meiqin Liu, An Open Platform of Auditory Perception for Home Service Robots, 2015 IEEE/RSJ International Conference on Intelligent Robots and Systems, Hamburg, Germany, Sept, 2015.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7354255'],
    ['[C142] Duy Tran, Weihua Sheng, Li Liu and Meiqin Liu, A Hidden Markov Model based Driver Intention Prediction System, 5th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems. June 8-12, 2015, Shenyang, China.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7287920'],
    ['[C141] Dan Yang, Weihua Sheng, Ruili Zeng, Indoor Human Localization Using PIR Sensors and Accessibility Map, 5th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems. June 8-12, 2015, Shenyang, China.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7288004'],
    ['[C140] Denis Osipychev, Duy Tran, Weihua Sheng, Girish Chowdhary and Ruili Zeng, Proactive MDP-based Collision Avoidance Algorithm for Autonomous Cars, 5th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems. June 8-12, 2015, Shenyang, China.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7288078'],
    ['[C139] Ruili Zeng, Weihua Sheng and Dan Yang, Collision Probability Computation Based on Vehicle to Vehicle Communication, 5th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems. June 8-12, 2015, Shenyang, China.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7288160'],
    ['[C138] Yanfei Li, Meiqin Liu and Weihua Sheng, Indoor Human Tracking and State Estimation by Fusing Environmental Sensors and Wearable Sensors, 5th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems. June 8-12, 2015, Shenyang, China.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7288161'],
    ['[C137] Jianhao Du, Weihua Sheng, Qi Cheng and Meiqin Liu, Active 3D Robot Mapping in Environments with Sparse Features, 10th International Symposium on Visual Computing (ISVC14), 2014.', '[bib]', '[link]', 'https://link.springer.com/chapter/10.1007/978-3-319-14249-4_74'],
    ['[C136] Praveen Batapati, Duy Tran, Weihua Sheng, Meiqin Liu, Ruili Zeng, Video Analysis for Traffic Anomaly Detection Using Support Vector Machines. World Congress on Intelligent Control and Automation. Shenyang, China. April, 2014.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7053655'],
    ['[C135] Tongyang Jiang, Meiqin Liu, senlin zhang, weihua sheng, Gating Technique for the Gaussian Mixture MeMBer Filter, American Control Conference. June 2014.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6858601'],
    ['[C134] Ye Gu, Weihua Sheng, Yongsheng Ou, Automated Assembly Skill Acquisition through Human Demonstration. The IEEE International Conference on Robotics and Automation (ICRA2014), May, 2014.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6907790'],
    ['[C133] Eyosiyas Tadesse, Weihua Sheng, Meiqin Liu, Driver Drowsiness Detection through HMM Based Dynamic Modeling, The 2014 IEEE International Conference on Robotics and Automation. May, 2014', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6907440'],
    ['[C132] Ha Do, Craig Mouser, Weihua Sheng, Meiqin Liu, Human-Robot Collaboration in a Mobile Visual Sensor Network. The 2014 IEEE International Conference on Robotics and Automation. May, 2014', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6907163'],
    ['[C131] Rakshit Allamaraju, Hassan Kingravi, Allan Axelrod, Girish Chowdhary, Robert Grande, Christopher Crick, Weihua Sheng. Human Aware UAS Path Planning in Urban Environments using Nonstationary MDPs, The IEEE International Conference on Robotics and Automation (ICRA2014), May 2014', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6907000'],
    ['[C130] Ye Gu and Weihua Sheng, Yongsheng Ou, Meiqin Liu and Senlin Zhang, Human Activity Recognition with Contextual Constraints using a RGB-D Sensor, 2013 IEEE Conference on Robotics and Biomimetics. Dec. 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6739538'],
    ['[C129] Weihua Sheng, Duy Tran, Eyosiyas Tadesse, Meiqin Liu, Gangfen Yan, An Integrated Manual and Autonomous Driving Framework based on Driver Drowsiness Detection, 2013 IEEE/RSJ International Conference on Robots and Intelligent Systems (IROS2013), Nov. 2013', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6696984'],
    ['[C128] Hung La, Weihua Sheng, Jiming Chen, Cooperative and Active Sensing in Mobile Sensor Networks for Scalar Field Mapping, the 2013 IEEE International Conference on Automation Science and Engineering, Feb. 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6815791'],
    ['[C127] Ha M. Do, Craig J. Mouser, Ye Gu, Sam Honarvar, Tingting Chen, Weihua Sheng, An Open Platform Telepresence Robot with Natural Human Interface, the 2013 Cyber conference, Jan. 2012.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6705424'],
    ['[C126] Hung La, Ronny Lim and Weihua Sheng, Jiming Chen, Cooperative Flocking and Learning in Multi-Robot Systems for Predator Avoidance, the 2013 Cyber conference, Jan. 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6705469'],
    ['[C125] Meiqin Liu, Weihua Sheng Senlin Zhang, and Zhen Fan, "Optimal H∞ output feedback control for a class of nonlinear systems,” the 32nd Chinese Control Conference, Xi’an, China. 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6639552'],
    ['[C124] ZHANG Senlin, ZHANG Qiang, LIU Meiqin, and SHENG Weihua, “A multi-hop reverse localization for underwater wireless sensor networks,” the 32nd Chinese Control Conference, Xi’an, China. 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6640737'],
    ['[C123] Yehenew Mengistu, Minh Pham, Ha Manh Do and Weihua Sheng, AutoHydrate: A Wearable Hydration Monitoring System, The IEEE/RSJ International Conference on Intelligent Robots and System (IROS2016) Daejeon Korea.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7759295'],
    ['[C122] Jianhao Du, Weihua Sheng and Meiqin Liu, Human-guided Robot 3D Mapping using Virtual Reality Technology, The IEEE/RSJ International Conference on Intelligent Robots and System (IROS2016) Daejeon Korea.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7759680'],
    ['[C121] Francisco Erivaldo Fernandes Junior, Guanci Yang, Ha Manh Do, Weihua Sheng, Detection of Privacy-Sensitive Situations for Social Robots in Smart Homes, IEEE International Conference on Automation Science and Engineering, Fort Worth TX, 2016.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7743474'],
    ['[C120] Ha Manh Do, Weihua Sheng, Meiqin Liu, Senlin Zhang, Context-aware Sound Event Recognition for Home Service Robots, IEEE International Conference on Automation Science and Engineering, Fort Worth TX, 2016.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7743476'],
    ['[C119] Minh Pham, Yehenew Mengistu, Ha Manh Do, Weihua Sheng, Cloud-Based Smart Home Environment (CoSHE) for Home Healthcare, IEEE International Conference on Automation Science and Engineering, Fort Worth TX, 2016.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7743444'],
    ['[C118] Barath Lakshmanan, Weihua Sheng, Meiqin Liu and Senlin Zhang, Locating License Tags Using Edges, The 6th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems (IEEE-CYBER 2016). June 2016.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7574820'],
    ['[C117] Duy Tran, Eyosiyas Tadesse, Weihua Sheng, Meiqin Liu and Senlin Zhang, A Driver Assistance Framework based on Driver Drowsiness Detection. The 6th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems (IEEE-CYBER 2016). June, 2016.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7574817'],
    ['[C116] Duy Tran, Eyosiyas Tadesse, Praveen Batapati, Weihua Sheng and Li Liu, A Cloud based Testbed for Research and Education in Intelligent Transportation System (ITS), IEEE International Conference on Robotics and Biomimetics, Zhuhai China, Dec. 6-9, 2015.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7418809'],
    ['[C115] Minh Pham, Dan Yang, Weihua Sheng, Meiqin Liu, Human Localization and Tracking Using Distributed Motion Sensors and an Inertial Measurement Unit. IEEE International Conference on Robotics and Biomimetics, Zhuhai China, Dec. 6-9, 2015.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7419088'],
    ['[C114] Jianhao Du, Meiqin Liu and Weihua Sheng, Efficient Exploration for Real-time Robot Indoor 3D Mapping, 34th Chinese Control Conference, July 2015, Hangzhou China.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7260590'],
    ['[C113] Ye Gu, Weihua Sheng, Yongsheng Ou, Meiqin Liu, Fine Manipulative Action Recognition through Sensor Fusion, 2015 IEEE/RSJ International Conference on Intelligent Robots and Systems, Hamburg, Germany, Sept, 2015.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7353476'],
    ['[C112] Ha Manh Do, Weihua Sheng, Meiqin Liu, An Open Platform of Auditory Perception for Home Service Robots, 2015 IEEE/RSJ International Conference on Intelligent Robots and Systems, Hamburg, Germany, Sept, 2015.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7354255'],
    ['[C111] Duy Tran, Weihua Sheng, Li Liu and Meiqin Liu, A Hidden Markov Model based Driver Intention Prediction System, 5th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems. June 8-12, 2015, Shenyang, China.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7287920'],
    ['[C110] Dan Yang, Weihua Sheng, Ruili Zeng, Indoor Human Localization Using PIR Sensors and Accessibility Map, 5th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems. June 8-12, 2015, Shenyang, China.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7288004'],
    ['[C109] Denis Osipychev, Duy Tran, Weihua Sheng, Girish Chowdhary and Ruili Zeng, Proactive MDP-based Collision Avoidance Algorithm for Autonomous Cars, 5th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems. June 8-12, 2015, Shenyang, China.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7288078'],
    ['[C108] Ruili Zeng, Weihua Sheng and Dan Yang, Collision Probability Computation Based on Vehicle to Vehicle Communication, 5th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems. June 8-12, 2015, Shenyang, China.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7288160'],
    ['[C107] Yanfei Li, Meiqin Liu and Weihua Sheng, Indoor Human Tracking and State Estimation by Fusing Environmental Sensors and Wearable Sensors, 5th Annual IEEE International Conference on CYBER Technology in Automation, Control, and Intelligent Systems. June 8-12, 2015, Shenyang, China.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7288161'],
    ['[C106] Jianhao Du, Weihua Sheng, Qi Cheng and Meiqin Liu, Active 3D Robot Mapping in Environments with Sparse Features, 10th International Symposium on Visual Computing (ISVC14), 2014.', '[bib]', '[link]', 'http://www.isvc.net/wp-content/uploads/2018/06/ISVC14_Final_Program.pdf'],
    ['[C105] Praveen Batapati, Duy Tran, Weihua Sheng, Meiqin Liu, Ruili Zeng, Video Analysis for Traffic Anomaly Detection Using Support Vector Machines. World Congress on Intelligent Control and Automation. Shenyang, China. April, 2014.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/7053655'],
    ['[C104] Tongyang Jiang, Meiqin Liu, senlin zhang, weihua sheng, Gating Technique for the Gaussian Mixture MeMBer Filter, American Control Conference. June 2014.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6858601?arnumber=6858601'],
    ['[C103] Ye. Gu, Weihua Sheng, Yongsheng Ou, Automated Assembly Skill Acquisition through Human Demonstration. The IEEE International Conference on Robotics and Automation (ICRA2014), May, 2014.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6907790'],
    ['[C102] Eyosiyas Tadesse, Weihua Sheng, Meiqin Liu, Driver Drowsiness Detection through HMM Based Dynamic Modeling, The 2014 IEEE International Conference on Robotics and Automation. May, 2014.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6907440'],
    ['[C101] Ha Do, Craig Mouser, Weihua Sheng, Meiqin Liu, Human-Robot Collaboration in a Mobile Visual Sensor Network. The 2014 IEEE International Conference on Robotics and Automation. May, 2014.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6907163'],
    ['[C100] Rakshit Allamaraju, Hassan Kingravi, Allan Axelrod, Girish Chowdhary, Robert Grande, Christopher Crick, Weihua Sheng. Human Aware UAS Path Planning in Urban Environments using Nonstationary MDPs, The IEEE International Conference on Robotics and Automation (ICRA2014), May 2014.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6907000'],
    ['[C99] Ye Gu and Weihua Sheng, Yongsheng Ou, Meiqin Liu and Senlin Zhang, Human Activity Recognition with Contextual Constraints using a RGB-D Sensor, 2013 IEEE Conference on Robotics and Biomimetics. Dec. 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6739538'],
    ['[C98] Weihua Sheng, Duy Tran, Eyosiyas Tadesse, Meiqin Liu, Gangfen Yan, An Integrated Manual and Autonomous Driving Framework based on Driver Drowsiness Detection, 2013 IEEE/RSJ International Conference on Robots and Intelligent Systems (IROS2013), Nov. 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6696984'],
    ['[C97]Hung La, Weihua Sheng, Jiming Chen, Cooperative and Active Sensing in Mobile Sensor Networks for Scalar Field Mapping, the 2013 IEEE International Conference on Automation Science and Engineering, Feb. 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6815791'],
    ['[C96] Ha M. Do, Craig J. Mouser, Ye Gu, Sam Honarvar, Tingting Chen, Weihua Sheng, An Open Platform Telepresence Robot with Natural Human Interface, the 2013 Cyber conference, Jan. 2012.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6705424'],
    ['[C95]Hung La, Ronny Lim and Weihua Sheng, Jiming Chen, Cooperative Flocking and Learning in Multi-Robot Systems for Predator Avoidance, the 2013 Cyber conference, Jan. 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6705469'],
    ['[C94] Meiqin Liu, Weihua Sheng Senlin Zhang, and Zhen Fan, "Optimal H∞ output feedback control for a class of nonlinear systems,” the 32nd Chinese Control Conference, Xi’an, China. 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6639552'],
    ['[C93] ZHANG Senlin, ZHANG Qiang, LIU Meiqin, and SHENG Weihua, “A multi-hop reverse localization for underwater wireless sensor networks,” the 32nd Chinese Control Conference, Xi’an, China. 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6640737'],
    ['[C92] Hongtai Chen, Heping Chen, Yong Liu and Weihua Sheng, Human-Like Indoor Navigation for Autonomous Industrial Mobile Manipulator. IEEE International Conference on Information and Automation, 2012.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6246801'],
    ['[C91] Shiyou Zheng, Xiaofang Tang, Meiqin Liu, Senlin Zhang, Weihua Sheng, “State estimation with missing measurements using IMM,” Proceedings of the 25th Chinese Control and Decision Conference (2013 CCDC), Guiyang, China, May 25-27, 2013, accepted.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6561237'],
    ['[C90] Jianhao Du and Weihua Sheng, Active View Planning for Human Observation through a RGB-D Camera. IEEE Workshop on Robot Vision (WoRV) 2013.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6521923'],
    ['[C89] Y. Gu, Q. Cheng and W. Sheng, Classifier Fusion for Gesture Recognition using a Kinect Sensor, the 3rd International Conference on Sensor Networks and Applications, 2012. (Best Paper Award).', '[bib]', '[link]', '#'], 
    ['[C88] Y. Gu, H. Do, Y. Ou and W. Sheng, Human Gesture Recognition through a Kinect Sensor, 2012 IEEE International Conference on Robotics and Biomimetics (ROBIO 2012).' ,'[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6491161'],
    ['[C87] J. Du, Y. OU and W. Sheng, Improving 3D Indoor Mapping with Motion Data, 2012 IEEE International Conference on Robotics and Biomimetics (ROBIO 2012).', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6491014'],
    ['[C86] H. Srinivasan, S. Das Gupta, W. Sheng, H. Chen, Estimation of Hand Force from Surface Electromyography Signals using Artificial Neural Network, 10th World Congress on Intelligent Control and Automation, July, 2012.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6357947'],
    ['[C85] G. Li, C. Zhu, J. Du, Q. Cheng, W. Sheng and H. Chen, Robot Semantic Mapping Through Wearable Sensor-based Human Activity Recognition. IEEE International Conference on Robotics and Automation, 2012.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6225305'],
    ['[C84] H. Cheng, H. Chen, Y. Liu and W. Sheng, Autonomous Industrial Mobile Manipulator in Industrial Applications. IEEE International Conference on Information and Automation, 2012. (Best Conference Paper Award).', '[bib]', '[link]', 'https://www.researchgate.net/publication/260736636_Object_handling_using_Autonomous_Industrial_Mobile_Manipulator'], 
    ['[C83] G. Tewolde, W. Sheng, Energy aware adaptive clustering in wireless sensor networks, IEEE International Conference on Electro/Information Technology. 2011.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5978632'],
    ['[C82] A. Thobbi, Y. Gu and W. Sheng, Using Human Motion Estimation for Human-Robot Cooperative Manipulation, IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS), 2011. (Best Cotesys Paper Award).', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6094904'],
    ['[C81] C. Zhu and W. Sheng, Realtime Recognition of Complex Daily Activities Using Dynamic Bayesian Network, IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS), 2011.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6094995'],
    ['[C80] Hung Manh La, Ronny Salim Lim, Jianhao Du, Weihua Sheng, Gang Li, Sijian Zhang and Heping Chen, A Small-Scale Research Platform for Intelligent Transportation Systems, IEEE International Conference on Robotics and Biomimetics (Robio), 2011.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/6181480'],
    ['[C79] G. Li, Q. Cheng and W. Sheng, Sequential Hybrid Map Learning through Human-Robot Interaction, the Proceedings of the ISCA 23rd International Conference on Computer Applications in Industry and Engineering (CAINE-2011), 2011.', '[bib]', '[link]', ''],
    ['[C78] G. Li, J. Du, C. Zhu, W. Sheng, A cost-effective and open mobile sensor platform for networked surveillance. Proceedings of SPIE: Signal and Data Processing of Small Targets 2011.', '[bib]', '[link]', 'https://www.spiedigitallibrary.org/conference-proceedings-of-spie/8137/1/A-cost-effective-and-open-mobile-sensor-platform-for-networked/10.1117/12.895520.full'],
    ['[C77] H. La and W. Sheng, Cooperative sensing in mobile sensor networks based on distributed consensus. Proceedings of SPIE: Signal and Data Processing of Small Targets 2011.', '[bib]', '[link]', 'https://www.spiedigitallibrary.org/conference-proceedings-of-spie/8137/81370Y/Cooperative-sensing-in-mobile-sensor-networks-based-on-distributed-consensus/10.1117/12.895519.full?SSO=1&tab=ArticleLink'],
    ['[C76] H. La, H. Chen and W. Sheng, Decentralized Flocking Control with a Minority of Informed Agents, 6th IEEE Conference on Industrial Electronics and Applications (ICIEA 2011), Beijing, China, 2011.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5975893'],
    ['[C75] R. Lim, H. La, W. Sheng. Z. Shan, Developing a Crack Inspection Robot for Bridge Maintenance, IEEE International Conference on Robotics and Automation, Shanghai, China, 2011.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5980131'],
    ['[C74] H. Chen, W. Sheng, Transformative Industrial Robot Programming in Surface Manufacturing, IEEE International Conference on Robotics and Automation, Shanghai, China, 2011.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5979570'],
    ['[C73] A. Thobbi, Ye. Gu and W. Sheng. Human-robot collaborative manipulation through imitation and reinforcement learning. IEEE International Conference on Information and Automation, 2011.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5948979'],
    ['[C72] C. Zhu, Q. Cheng and W. Sheng, Human Activity Recognition Via Motion and Vision Data Fusion, the 44th Asilomar Conference on Signals, Systems and Computers, CA, USA. 2010.', '[bib]', '[link]', 'https://www.asilomarsscconf.org/webpage/asil10/prog10/BookAbstracts.pdf'],
    ['[C71] A. Thobbi, R. Kadam, W. Sheng, Achieving Remote Presence using a Humanoid Robot Controlled by Non-Invasive BCI Device, International Conference on Embedded Systems (ICES2010), India, July, 2010.', '[bib]', '[link]', 'https://www.researchgate.net/publication/292759456_Achieving_remote_presence_using_a_humanoid_robot_controlled_by_a_non-invasive_BCI_device'], 
    ['[C70] A. Thobbi, W. Sheng, Imitation Learning of Arm Gestures in Presence of Missing Data for Humanoid Robots10th IEEE-RAS International Conference on Humanoid Robots, Nashville, TN, Dec, 2010.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5686324'],
    ['[C69] R. Kadam, S. Zhang, Q.Wang, W. Sheng, Multidimensional Scaling Based Location Calibration for Wireless Multimedia Sensor Networks. IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS10), Taipei, Taiwan, Oct. 2010.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5649678'],
    ['[C68] A. Thobbi and W. Sheng, Imitation Learning of Hand Gestures and its Evaluation for Humanoid Robots, IEEE International Conference on Information and Automation, Harbin, China. June, 2010.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5512333'],
    ['[C67] C. Zhu and W. Sheng, Realtime Human Daily Activity Recognition Through Fusion of Motion and Location Data. IEEE International Conference on Information and Automation, Harbin, China. June, 2010.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5512451'],
    ['[C66] S. Zhang, G. Li, G. Yan and W. Sheng, Development and Evaluation of a Compact Motion Sensor Node for Wearable Computing. 2010 IEEE/ASME International Conference on Advanced Intelligent Mechatronics. Montreal, Canada. July, 2010.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5695916'],
    ['[C65] S. Zhang, G. Yan and W. Sheng, An Efficient Planning Method for Mobile Robot Based 3D Digitization. 8th World Congress on Intelligent Control and Automation, Jinan, China. July, 2010.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5554855'],
    ['[C64] C. Zhu and W. Sheng, Recognizing Human Daily Activity Using a Single Inertial Sensor. World Congress on Intelligent Control and Automation, Jinan, China. July, 2010.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5555072'],
    ['[C63] H. La and W. Sheng, Flocking Control of Multiple Agents in Noisy Environments, IEEE International Conference on Robotics and Automation, Anchorage, Alaska, USA. May, 2010.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5509668'],
    ['[C62] S. Zhang, G. Yan and W. Sheng, Viewpoint Planning for Automated 3D Digitization using a Low-cost Mobile Platform, IEEE/RSJ International Conference on Intelligent Robots and Systems. St. Louis, MO, 2009.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5354245'],
    ['[C61] H. La and W. Sheng, Adaptive Flocking Control for Dynamic Target Tracking in Mobile Sensor Networks. IEEE/RSJ International Conference on Intelligent Robots and Systems. St. Louis, MO, 2009.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5354450'],
    ['[C60] C. Zhu and W. Sheng, Online Hand Gesture Recognition Using Neural Network Based Segmentation. IEEE/RSJ International Conference on Intelligent Robots and Systems. St. Louis, MO, 2009.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5354657'],
    ['[C59] V. Mehta, W. Sheng, Tianzhou Chen and Quan Shi, Development and Calibration of a Low Cost Wireless Camera Sensor Network. IEEE/RSJ International Conference on Intelligent Robots and Systems. St. Louis, MO, 2009.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5354224'],
    ['[C58] C. Zhu and W. Sheng, Hand Gesture Recognition using Neural Network based Segmentation for Natural Human Robot Interaction, Human Robot Interaction Conference, San Diego, March, 2009.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/document/5354657'], 
    ['[C57] H. La and W. Sheng, Flocking Control of a Mobile Sensor Network to Track and Observe a Moving Target, IEEE International Conference on Robotics and Automation, May, 2009.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/abstract/document/5152747'],
    ['[C56] C. Zhu and W. Sheng, Human Daily Activity Recognition in Robot-assisted Living Using Multi-sensor Fusion, IEEE International Conference on Robotics and Automation, May, 2009.', '[bib]', '[link]', 'https://ieeexplore.ieee.org/abstract/document/5152756'],
    ['[C55] H. La and W. Sheng, Moving Target Tracking and Observing in a Distributed Mobile Sensor Network, in Proceedings of the 2009 American Control Conference, St. Louis, MO. June, 2009.', '[bib]', '[link]', 'https://ara.cse.unr.edu/wp-content/uploads/2014/12/ACC_La2009.pdf'],
    ['[C54] C. Wu, W. Sheng, Y. Zhang, S. Kanchi, Mobility assisted localization for robotic sensor networks, IEEE International Conference on Information and Automation, Zhuhai, China, June, 2009.', '[bib]', '[link]']
]

export default function Conference() {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
    setOpen(false);
    };

    const handleTooltipOpen = () => {
    setOpen(true);
    };
    return(
        <Box component="main" sx={{ p: 3 }}>
            <Box sx={{mb: 1}}>
                <Typography variant='h4' align='center'>Conference</Typography>
                <Typography variant='h6' align='center'>Referred Conference Publications</Typography>
            </Box>
            <Box>
                {conference.map((item, index) => (
                    // <ListItem sx={{mb: 1}}>
                    //     <ListItemText key={index}
                    //             primary={item[0]+item[1]}
                    //         />
                    // </ListItem>
                    <><Typography>{item[0]}
                        <Link href={'#'+String(Math.abs(index-174))}>{item[1]}</Link>&nbsp;
                        <Link target="_blank" href={item[3]}>{item[2]}</Link>
                    </Typography><br/></>
                ))}
            </Box>
            <Typography align='center' variant='h5'>Bibliography</Typography>
            { bibFile.map((bib, index) => (
            <Stack direction="row" justifyContent="center">
                <Card id={index+54} elevation={10} sx={{ width: 600, maxWidth: 600, mb: 2 }}>
                <CardContent>
                    <Typography>C{index+54}</Typography>
                    <Typography sx={{whiteSpace: 'pre-wrap'}} key={index+1} variant="body2" color="text.secondary">
                    {bib}
                    </Typography>
                </CardContent>
                <CardActions>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                        PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Add" 
                      arrow>
                        <Button onClick={() => {navigator.clipboard.writeText(bib); handleTooltipOpen(this)}} size="small">copy</Button>
                    </Tooltip>
                </ClickAwayListener>
                </CardActions>
                </Card>
            </Stack>
            ))}
      </Box>
    )
}