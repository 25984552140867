import ResponsiveAppBar from './Navbar';
import { BrowserRouter as Router } from 'react-router-dom';
import Rout from './Routes';
import { StrictMode } from "react";
import Footer from './Footer';
import './animations.scss'


function App() {
  return (
    <StrictMode>
    <Router>
      <div className="App">
        <ResponsiveAppBar/>
        <Rout/>
        <Footer/>
      </div>
    </Router>
    </StrictMode>
  );
}

export default App;
