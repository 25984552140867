import { useSearchParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
import CardMedia from '@mui/material/CardMedia'
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
// import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";

function importAll(r) {
    let images = {};
     r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
   }
  const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg|JPG)$/));
  
  const profileList = [
    {
    name: 'Dr. Weihua Sheng',
    phone: '(405)744-7590',
    profession: 'Professor',
    // resint: 'Social Robotics, Smart Health, Embedded Computing, Intelligent Transportation Systems',
    email: 'weihua.sheng AT okstate.edu',
    image: images['sheng1.jpg']
    },
  {
    name: 'Zhidong Su',
    body: 'Zhidong Su is an international doctoral student from Henan, China.He is an alum of Guizhou University in China where he studied from 2016 until 2019.',
    resint: 'Mobile robotics, natural language processing, computer vision,and machine learning',
    phone: '(405)780-2992',
    email: 'zhidong.su AT okstate.edu',
    image: images['zhidong-headshot.jpg'],
  },
  {
    name: 'Jiaxing Lu',
    body: 'Jiaxing Lu is currently a first year doctoral student from China. In addition to his research, Jiaxing enjoys basketball and swimming.',
    resint: 'Artificial Intelligence, Machine Learning, and Pattern Recognition',
    phone: '(201)539-0389',
    email: 'jiaxing.lu AT okstate.edu',
    image: images['JiaxingLu.jpg'],
  },
  {
    name: 'Fei Liang',
    body: 'Fei Liang is an international doctoral student from Hebei, China. He worked at Baidu(Beijing) company as an software engineer for 4 years before he came here. Outside of his research, Fei enjoys swimming and reading.',
    resint: 'Wearable devices, smart home, machine learning and digital twin',
    phone: '(405)714-4760',
    email: 'fei.liang AT okstate.edu',
    image: images['fei.jpeg'],
    personal: 'https://scholar.google.com/citations?user=FRbTPrgAAAAJ&hl=en&oi=ao',
  },
  {
    name: 'Zhaohua Zhang',
    body: 'Zhaohua Zhang is currently a first year Ph.D student in ASCC lab, his research interests include Autonomous driving, Reinforcement Learning and Embedded Systems.',
    resint: 'Autonomous Driving, Reinforcement Learning and Embedded System',
    phone: '(315)484-6798',
    email: 'zhaohua.zhang AT okstate.edu',
    image: images['zhaohua.jpeg'],
  },
  {
    name: 'Zhanjie Chen',
    body: 'Zhanjie Chen is currently a first year Ph.D student in ASCC lab, his research interests include Visual SLAM, 3D object detection and Robot navigation.',
    resint: 'Visual SLAM, 3D object detection and Robot navigation',
    phone: '(706)255-9156',
    email: 'zhanjie.chen AT okstate.edu',
    image: images['zhanjiechen.jpg'],
  },
  {
    name: 'Joel Quarnstrom',
    body: 'Joel Quarnstrom is a mechanical engineering PhD student studying robotics and control systems. He graduated from OSU with a Bachelor of Mechanical Engineering and a second major in Physics in May 2022. He has researched novel robots and medical rehabilitation devices and has experience with 3D printing, CAD, and robot dynamics simulations among other things.',
    resint: 'Robotics and control system, robot dynamics simulation, 3D printing',
    // phone: '(315)484-6798',
    email: 'joel.quarnstrom AT okstate.edu',
    image: images['joel.jpg'],
  },
  // {
  //   name: 'Mohammed Mukarram',
  //   body: 'Experienced Software Engineer with a demonstrated history of working in the information technology and services industry. Skilled in Python, Java, JavaScript(React, Nodejs).',
  //   resint: 'Machine Learning, Big Data, IoT',
  //   phone: '(405)926-0247',
  //   email: 'mohammed.mukarram AT okstate.edu',
  //   image: images['IMGS7448 (1).JPG']
  // },
  {
    name: 'Brandon Ong',
    body: 'Brandon Ong is a senior undergraduate student persuing his BSCpE & BSEE. He is from Oklahoma City and is a graduate from Westmore High School. He is an avid fan of video games, soccer, basketball, and good food.',
    resint: 'Embedded Systems Development, VLSI, and Computer Vision',
    phone: '(405)905-9535',
    image: images['BrandonOng.jpg']
  },
  {
    name: 'Nick Loeffelholz',
    body: 'Nick Loeffelholz was born and raised in Oklahoma with a passion for problem solving. Stunted in math as a child, his passion was not fine tuned to the engineering disciplines until an encounter with a teacher and lifelong friend while serving as a transfer student in Red River, NM enabled him to excel in S.T.E.M. related disciplines. Returning to Oklahoma well-equipped, he attended the Francis Tuttle Pre-Engineering Academy where he took special interest in the fields of electronics and robotic machinery. Currently, Nick is an undergraduate student at Oklahoma State University studying Computer and Electrical Engineering. He is projected to graduate in the Spring of 2022.',
    resint: 'Smart Home, Robotic Assistance technology, Alternative Power, and Electroacoustics',
    phone: '(405)435-0557',
    email: 'nick.loeffelholz AT okstate.edu',
    image: images['NickLoeffelholz.jpg']
  },
  {
    name: 'Daniel Albrecht',
    body: `Daniel Albrecht is a junior Electrical Engineering major from Sparks, OK and an alumni of Prague High School. His interests include Mobile Robotics, Machine Learning, and Computer Vision. In addition to his contributions to the ASCC, Daniel enjoys developing his skills with the trumpet, as well as spacial puzzles like Rubik's cubes. He is also a founding member of the juggling club.`,
    resint: 'Machine Learning, Computer Vision, and Mobile Robotics',
    phone: '(918)290-1854',
    email: 'daniel.albrecht AT okstate.edu',
    image: images['danielalbrecht2.jpg']
  },
    {
      name: 'Hung La',
      profession: 'PhD (2011), Assistant Professor, University of Nevada, Reno, NV, USA.',
      image: images['hung.jpg']
    },
    {
      name: 'Chun Zhu, PhD (2011)',
      resint: 'Applied Scientist, Microsoft Corporation, CA.',
      image: images['chun.jpg']
    },
    {
      name: 'Ye Gu',
      email: 'ye.gu AT okstate.edu',
      image: images['guye.jpg']
    },
    {
      name: 'Jianhao Du',
      email: 'jianhao.du AT okstate.edu',
      image: images['jianhao.jpg']
    },
    {
      name: 'Ha Do',
      resint: 'Mobile robotics, computer vision, and embedded systems development',
      phone: '(405) 744-4799',
      email: 'ha.do AT okstate.edu',
      image: images['HaDo.jpg']
    },
    {
      name: 'Minh Pham',
      resint: 'Digital health, smart homes, and wearable computing',
      phone: '(405) 744-4799',
      email: 'minh.pham AT okstate.edu',
      image: images['MinhPham.jpg']
    },
    {
      name: 'Duy Tran',
      resint: 'Intelligent Transportation Systems',
      phone: '(405) 744-4799',
      email: 'duydt AT okstate.edu',
      image: images['duy.jpg']
    },
    {
      name: 'Ricardo Hernandez',
      body: 'Hailing from Oklahoma City, Ricardo Hernandez is a Masters student here at OSU persuing his MS in Electrical Engineering. He recieved his BS in Computer Engineering, also from Oklahoma State University. His interest lies in the realm of wearable embedded systems that will make elderly care more safe and efficient. Currently he is delving into the topic of camera vision to later on incorporate it into a wearable device. In his free time he likes to engage in the arts and several sports. He loves to draw and is currently learning how to paint digitally. If given the time, he also skateboards and trains in boxing.',
      resint: 'Robotics, Machine Learning, Embedded System Development',
      phone: '(405)430-6792',
      email: 'ricardh AT okstate.edu',
      image: images['RicardoHernandez2.jpg']
    },
    {
      name: 'Yehenew Mengistu',
      resint: 'Machine learning, computer vision, and embedded system development',
      phone: '(405) 744-4799',
      email: 'yehenew.mengistu AT okstate.edu',
      image: images['Yehenew.jpg']
    },
    {
      name: 'Kiran Muniraju',
      resint: 'Artificial Intelligence',
      phone: '(405) 744-4799',
      email: 'kiran.muniraju AT okstate.edu',
      image: images['kiran.jpg']
    },
    {
      name: 'Trung Nguyen',
      resint: 'Machine learning and computer vision',
      phone: '(405) 744-4799',
      email: 'trungdn AT okstate.edu',
      image: images['trungnguyen.jpg']
    },
    {
      name: 'Barath Lakshmanan',
      profession: 'Master (2016), Intel',
      image: images['Barath.jpg']
    },
    {
      name: 'Dharmendra Kallur',
      profession: 'Master (2014), RBC Medical Innovations',
      image: images['Dharma.jpg']
    },
    {
      name: 'Denis Osipychev',
      phone: '(405) 744-4799',
      email: 'denis.osipychev AT okstate.edu',
      image: images['Denis.jpg']
    },
    {
      name: 'Praveen C Batapati V',
      profession: 'Master (2013), Engineer, ABB Inc. OK',
      image: images['Praveen.jpg']
    },
    {
      name: 'Eyosiyas Tadesse',
      profession: 'Master (2013), Research Scientist',
      image: images['Eyosiyas.jpg']
    },
    {
      name: 'Faizan Shaik',
      profession: 'Master (2013), Toyota, Ann Arbor, MI. USA',
      image: images['faizan.jpg']
    },
    {
      name: 'Usman Zafar',
      profession: 'Master (2013), Linkedin',
      image: images['Usman.jpg']
    },
    {
      name: 'Craig Mouser',
      profession: 'Master (2012), Amazon',
      image: images['CraigMouser.jpg']
    },
    {
      name: 'Nizar Khemri',
      profession: 'Master (2012), Tripoli University, Libya',
      image: images['Nizar_Khemri.jpg']
    },
    {
      name: 'Haritha Srinivasan',
      profession: 'Master (2012), Wilson Mohr',
      image: images['Haritha.jpg']
    },
    {
      name: 'Sauvik Das Gupta',
      profession: 'Master (2012)',
      image: images['Sauvik.jpg']
    },
    {
      name: 'Anand Thobbi',
      profession: 'Master (2011), International Electronic Machines Corporation',
      image: images['anand.jpg']
    },
    {
      name: 'Gang Li',
      profession: 'Master (2011), Turn',
      image: images['ligang.jpg']
    },
    {
      name: 'Ronny Lim',
      profession: 'Master (2011)',
      resint: 'Integrated Microwave Technologies',
      image: images['ronny.jpg']
    },
    {
      name: 'Rohit Kadam',
      profession: 'Master (2010), CG Power Solutions',
      image: images['rohit.jpg']
    },
    {
      name: 'Ravi Kiran Garimella',
      profession: 'Master (2007), Fedex Services',
      image: images['ravi.jpg']
    },
    {
      name: 'Vimal Mehta',
      profession: 'Master (2008),Garmin',
      image: images['vimal.jpg']
    },
    {
      name: 'Chongben Tao',
      profession: 'Visiting Student (2013)',
      image: images['Chongben.jpg']
    },
    {
      name: 'Sijian Zhang',
      profession: 'Visiting Student (2009)',
      image: images['sijian.jpg']
    },
    {
      name: 'Xianfeng Yuan',
      resint: 'Mobile robotics, Machine learning and Intelligent fault diagnosis & prognosis',
      phone: '(405) 744-4799',
      email: 'xianfeng.yuan AT okstate.edu',
      image: images['Xianfeng.jpg']
    },
    {
      name: 'Jing Liu',
      resint: 'Resource management for sensor networks, Smart homes',
      phone: '(405) 762-6675',
      email: 'jing.liu10 AT okstate.edu',
      image: images['liujing.jpg']
    },
    {
      name: 'Zhijun Zhang',
      resint: 'Machine Learning and Pattern Recognition, Artificial Intelligence, Computational Intelligence, Recommendation System and Network Engineering',
      phone: '(405) 744-4799',
      email: 'zzjsdcn AT 163.com',
      image: images['ZhijunZhang.jpg']
    },
    {
      name: 'Baoshan You',
      resint: 'Mobile robotics ,Computer Vision and Embedded Systems Development',
      phone: '(405) 744-4799',
      email: 'Baoshan.you AT okstate.edu',
      image: images['baoshanyou.jpg']
    },
    {
      name: 'Baojuan Liang',
      phone: '(405) 744-4799',
      email: 'Baojuan.Liang AT okstate.edu',
      resint: 'Traffic Information and Control, Innovative energy supply technologies, wireless sensor network',
      image: images['BaojuanLiang.jpg']
    },
    {
      name: 'Guanci Yang',
      phone: '(405) 744-4799',
      resint: 'Computational Intelligence, Wearable Computing and Embedded Systems Development',
      email: 'guanci.yang AT okstate.edu',
      image: images['YangGuanci.jpg']
    },
    {	
      name: 'Dr. Dan Yang',
      profession: 'Lecturer',
      resint: 'Speech signal processing, Physiological signal detection',
      phone: '(405) 332-3336',
      email: 'yangdan AT neu.edu.cn',
      image: images['Yang.jpg']
    },
    {    	
      name: 'Dr. Ruili Zeng',
      profession : 'Associate Professor',
      resint: 'Intelligent traffic monitoring technology, Intelligent instrumentation and measurement',
      phone: '(405) 744-4799',
      email: 'ruili.zeng AT okstate.edu',
      image: images['zeng.png']
    },
    {
      name: 'B.E. Lanh Nguyen',
      professor: 'Visiting Scholar',
      resint: 'Automatic Control Systems',
      phone: '(405) 744-4799',
      email: 'lanhnguyen AT tnut.edu.vn',
      image: images['lanh.jpg']
    },
    {    	
      name: 'B.E. Tuan Tran',
      profession: 'Visiting Scholar',
      resint: 'Automatic Control Systems',
      phone: '(405) 744-4799',
      email: 'trantuan88tn AT gmail.com',
      image: images['Tuan.jpg']
    },
    {
      name: 'Prof. Zhaolin Gu',
      profession: 'Associate Professor',
      resint: 'Intelligent instrumentation and measurement',
      phone: '(405) 744-4799',
      email: 'zhaolin.gu AT okstate.edu',
      image: images['zhaolin.jpg']
    },
    {
          
      name: 'Prof. Li Liu',
      profession: 'Associate Professor',
      resint: 'Intelligent instrumentation and measurement',
      phone: '(405) 744-4799',
      email: 'li.liu AT okstate.edu',
      image: images['liuli.jpg']
    },
    {
          
      name: 'Theresa Pollinger',
      profession: 'Visiting Student',
      phone: '(405) 744-4799',
      email: 'theresa.pollinger AT gmx.net',
      image: images['Theresa.jpg']
    },
    {
      name: 'Sun Yuge',
      resint: 'Signal processing, Pattern recognition and BCI systems',
      phone: '(405) 332-3251',
      email: 'yuge.sun AT okstate.edu',
      image: images['Sunyuge.jpg']
    }
]

export default function Profile(){
    // const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams('');
    const name = searchParams.get('name')
    var profile = []
    for(var i=0; i<profileList.length;i++){
        if(name === 'Dr. Weihua Sheng'){
            window.location.href = 'https://wsheng.me'; 
            return null;
        }
        if(name === 'Zhidong Su'){
          window.location.href = 'https://zhidongsu.github.io'; 
          return null;
       }
        if(profileList[i]['name'] === name){
            profile = profileList[i]
        }
    }
    return(
        <Box sx={{minHeight: 'calc(100vh - 310px)'}}>
        <Stack direction="row" justifyContent="center">
        <Box component="main" sx={{ display: 'flex', width: {md: '50%'}, p: 1, justifyContent: 'center', alignContent: 'center' }}>
        {/* <Typography gutterBottom component="h2" variant="h5" color='#F37224'>{title}</Typography> */}
        <Paper variant="outlined" sx={{backgroundColor: '#fff', width: '100%'}}>
                <CardMedia
                sx={{
                  width: 200,
                  height: 200,
                  margin: 2,
                //   borderRadius: '50%',
                  flexShrink: 0,
                  backgroundColor: 'default',
                  backgroundPosition: 'top',
                  backgroundRepeat: 'no-repeat',
                  // backgroundSize: '200px 200px'
                }}
                image={profile.image}
                alt={profile.name}
                title={profile.name}
                />
          <Box sx={{
             m: 2, color: 'black'
          }}>
            <Typography sx={{color: '#F37224'}} component="h3" variant="h6">
              {profile.name}
            </Typography>
            {profile.profession && <Typography variant="body2" sx={{color: 'black'}}>
              {profile.profession}
            </Typography>}
            {profile.body && <Typography variant="body2" sx={{whiteSpace: 'pre-wrap'}}>
              {profile.body}
            </Typography>}
            {profile.resint && <Typography variant="body2" sx={{color: 'black'}}>
              Research Interests: {profile.resint}
            </Typography>}
            {profile.phone && <Typography variant="body2" sx={{color: 'black'}}>
              Phone: {profile.phone}
            </Typography>}
            {profile.email && <Typography variant="body2" sx={{color: 'black'}}>
              Email: <Link>{profile.email}</Link>
            </Typography>}
            {profile.personal && <Typography variant="body2" sx={{color: 'black'}}>
              Publications: <Link href="https://scholar.google.com/citations?user=FRbTPrgAAAAJ&hl=en&oi=ao" sx={{cursor: 'pointer'}}>{profile.personal}</Link>
            </Typography>}
          </Box>
        </Paper>
        </Box>
        </Stack>
        </Box>
    )
}