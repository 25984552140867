import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Announcements from './Announcements';
import Grid from '@mui/material/Grid';
import './animations.scss'
// import logo from './images/adobe3.jpeg';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export default function Home() {
    const gradientColors = "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1"
    const colors = "#F39224, #F37224"
    const colorsNew = "#4484ce 4485ce"
    return(
      <Box sx={{color: {md: 'white'}, minHeight: 'calc(100vh - 310px)',
          // backgroundSize: {md: "80% 100%"},
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          // background: `linear-gradient(136deg,${colorsNew})`,
          // background: {md: `linear-gradient(136deg,${colors})`, xs: `linear-gradient(110deg,${colors})`},
          // backgroundImage: {md: `url(${logo})`},
          // backgroundPosition: 'center center',
          color: 'white',
          background: {md: '#9bcaff;'},
          // background: '-webkit-linear-gradient(to right, #eea849, #f46b45)',
          // background: 'linear-gradient(to right, #eea849, #f46b45)',

          }}>
             {/* <div id="stars"></div> */}

          <Grid container spacing={1} columns = {12}>
            <Grid item md={9} lg={9}>
              <Stack direction="row" justifyContent="end">
              <Card elevation={20} sx={{ ml:{md: 10} ,mt: 10,  width: { sm: '100%', xs: '100%'}, maxWidth: { xs: '100%', sm: '100%'}, mb: 2, opacity: 0.8}}>
              {/* <CardContent sx={{background: `linear-gradient(136deg,${colors})`}}> */}
              <CardContent>
              <Typography variant='h4' sx={{ mb: 2}} align='center'>
                    Welcome to ASCC Lab
              </Typography>
              <Box component="main" sx={{ p: 1}}>
                <Typography sx={{ mb: {md: 8}, fontSize: 18}} variant = 'body1'>
                  The overall research goal of the Laboratory for Advanced Sensing, Computation and Control (ASCC Lab) is to develop innovative algorithms, methodologies, theories as well as prototypes to enable intelligent and robust sensing, decision making and control in various applications.
                  We conduct fundamental research in the areas of mobile robotics, wearable computing, robot skill learning and intelligent transportation systems. State-of-the-art facilities include advanced motion capture systems, mobile robots, humanoid robots, wireless sensor networks, vision sensors, 2D/3D laser sensors, haptic devices, brain-computer interfacing sensors, embedded development tools, PCs, etc.
                  We would like to welcome any student who is interested in the above exciting areas and is willing to push the boundaries of these scientific areas, and the boundaries of his or her own potential.
                </Typography>
                <Typography align='right'>
                  Weihua Sheng, Ph.D,
                </Typography>
                <Typography align='right'>
                  Professor and Director of ASCC Lab
                </Typography>
              </Box>
              </CardContent>
              </Card>
              </Stack>
            </Grid>
            <Grid item md={3}>
              <Announcements></Announcements>
            </Grid>
          </Grid>
      </Box>
    )
}