import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
// import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import { Input, TextArea } from 'semantic-ui-react';

const SERVICE_ID = "service_6pl4256";
const TEMPLATE_ID = "template_m9z044m";
// const USER_ID = "ascclabokstate@gmail.com";
const PUBLIC_ID = "zvEwZ9_GgfMrLdMZm";


export default function Contact() {
    const [name, setName ] = React.useState("");
    const [email, setEmail ] = React.useState("");
    const [message, setMessage ] = React.useState("");
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name)
        if(!name){
            Swal.fire({
                icon: 'error',
                title: 'Please Enter your Name',
            })
            return 
        }
        if(!email){
            Swal.fire({
                icon: 'error',
                title: 'Please Enter your Email',
            })
            return 
        }
        if(!message){
            Swal.fire({
                icon: 'error',
                title: 'Please Enter your Message',
            })
            return 
        }
        const form = document.getElementById('form')
        console.log(form)
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form, PUBLIC_ID)
        .then((result) => {
            console.log(result.text);
            Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully'
            })
        }, (error) => {
            console.log(error.text);
            Swal.fire({
            icon: 'error',
            title: 'Ooops, something went wrong',
            text: error.text,
            })
        });
        e.target.reset()
    }
    return(
        <Box component="main" sx={{ p: 3 }}>
            <Typography variant='h5' align='center'>Contact Us</Typography>
            <Box 
                justifyContent="center"
                alignItems="center"
                sx = {{flexDirection: "column", display: {md: "flex"}}}
                >
                    <Box
                        id='form'
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1, width: {md: "700px", sm: "400px"}}}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            // id="name"
                            id='form-input-control-name'
                            control={Input}
                            label="Name"
                            variant="outlined"
                            // name="name"
                            name='name'
                            autoComplete="name"
                            autoFocus
                            onChange={e => setName(e.target.value)}
                            // value={name}
                        />
                        <br />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            // id="email"
                            id="form-input-control-email"
                            label="Email"
                            variant="outlined" 
                            onChange={e => setEmail(e.target.value)}
                            // name="email"
                            name='email'
                            autoComplete="email"
                            // value={email}
                            autoFocus
                            control={Input}
                        />
                        <br />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            // id="message"
                            id='form-textarea-control-opinion'
                            label="Message"
                            variant="outlined"
                            // name="message"
                            name='message'
                            autoComplete="message"
                            onChange={e => setMessage(e.target.value)}
                            // value={message}
                            autoFocus
                            multiline={true}
                            rows={10}
                            control={TextArea}
                        ></TextField> 
                        <br />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >Submit</Button><br />
                        <Box 
                            sx={{
                                display: {md: 'flex'}, 
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography
                                sx ={{align: 'left'}}
                                variant="subtitle1"
                            >
                                Dr. Weihua Sheng<br/>
                                Professor<br/><br/>

                                School of Electrical and Computer Engineering<br/>
                                Oklahoma State University <br/>
                                Stillwater, OK, 74078<br/><br/>
                            </Typography>
                            <Typography
                                variant='subtitle1'
                            >
                                Office: 211 GAB<br/>
                                Phone: (405)744-7590<br/>
                                Fax: (405)744-9198<br/>
                                E-mail: <Link href='mailto:weihua.sheng@okstate.edu'>weihua 'dot' sheng 'at' okstate 'dot' edu</Link><br/>
                                Personal Web: <Link href='https://wsheng.me'>https://wsheng.me</Link><br/>
                            </Typography>
                        </Box>
                    </Box>
            </Box>
    </Box>
    )
}
